import { sanitizeText } from "../../helper/sanitizeUserInput";
const prepareDisplayText = (text) => {
  // URLが「http://」または「https://」で始まり、スペースまたは改行文字（\n）で区切られる場合にURLとして認識する
  const splitText = text.split(/(https?:\/\/[^\s\n]+)/g);
  const sanitizedTextAndDisplayedURL = splitText.map((part, index) => {
    let sanitizedText = "";
    if (part.match(/https?:\/\/[^\s]+/)) {
      //「http://」や 「https://」で始まるURLはそのまま表示
      sanitizedText = part;
    } else {
      //「http://」や 「https://」で始まるURL以外をサニタイズ
      sanitizedText = sanitizeText(part);
    }
    return sanitizedText;
  });
  return sanitizedTextAndDisplayedURL;
};

// URLを含まないテキストをサニタイズして表示するコンポーネント
export const SanitizedText = ({ text }) => {
  if (!text) return null;
  const sanitizedText = sanitizeText(text);
  return <div>{sanitizedText}</div>;
};

// URLを含むテキストをサニタイズして表示するコンポーネント
export const SanitizedTextWithUrl = ({ text }) => {
  if (!text) return null;
  const sanitizedTextAndDisplayedURL = prepareDisplayText(text);
  return (
    <div>
      {sanitizedTextAndDisplayedURL.map((part, index) => {
        if (!part) return null;
        if (part.match(/https?:\/\/[^\s\n]+/)) {
          // URLの部分はリンクとして表示
          return (
            <a key={index} href={part} target="_blank" rel="noopener noreferrer">
              {part}
            </a>
          );
        } else {
          // リンクでない部分はそのまま表示
          return <span key={index}>{part}</span>;
        }
      })}
    </div>
  );
};
