import React from "react";
import { Link } from "react-router-dom";
import styles from "../../assets/css/messageModal.module.css";
import { motion } from "framer-motion";
import { popUp } from "../../helper/animation";

export const MessageModal = ({ yesFunc, message, yesButtonText, onCancel }) => {
  return (
    <motion.div
      className={styles.overlay}
      variants={popUp}
      initial="hidden"
      animate="visible"
      exit="exit"
      onClick={() => onCancel()}
    >
      <div className={styles.modalContet}>
        {message}
        <div className={styles.buttonBox}>
          <button id="grayButton" onClick={() => onCancel()}>
            キャンセル
          </button>
          <button id="defaultButton" onClick={() => yesFunc()}>
            {yesButtonText}
          </button>
        </div>
      </div>
    </motion.div>
  );
};
