export const ScheduleType = {
  ADDITION: "addition",
  EDITING: "editing",
  CONFIRMATION: "confirmation",
};

export const SORT_DIRECTION = {
  ASC: "ASC",
  DESC: "DESC",
};

export const SpotType = {
  ADDITION: "addition",
  EDITING: "editing",
  CONFIRMATION: "confirmation",
};

export const SpotCategoryList = ["おすすめ", "いい景色", "グルメ", "おみやげ", "トイレ", "危険・注意", "その他"];

export const MiddleSnapHeight = 200;

export const ImageCompressionOptions = {
  maxSizeMB: 0.15,
  maxWidthOrHeight: 1100,
  initialQuality: 0.8,
};

export const MainImageCompressionOptions = {
  maxSizeMB: 0.3,
  maxWidthOrHeight: 1400,
  initialQuality: 0.75,
};
