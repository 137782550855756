import { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Header } from "./components/Header";
import { AuthModal } from "./components/auth/AuthModal";
import { Footer } from "./components/Footer";
import { TopPage } from "./pages/TopPage";
import { CreatePlan } from "./pages/CreatePlan";
import { FindPlan } from "./pages/FindPlan";
import { PlanDetail } from "./pages/PlanDetail";
// import { PlanRoom } from "./pages/PlanRoom";
// import { RideRecord } from "./pages/RideRecord";
import { CommunityPolicy } from "./pages/CommunityPolicy";
import { UserPolicy } from "./pages/UserPolicy";
// import { CreateRideRecord } from "./pages/CreateRideRecord";
// import { Mypage } from "./pages/Mypage";
// import { MypageRegister } from "./pages/MypageRegister";
// import { ConfirmParticipation } from "./pages/ConfirmParticipation";
import { NotFound } from "./pages/ExceptionMessage";
import "./assets/css/App.css";
import "./assets/css/global/button.css";
import "./assets/css/global/animation.css";
import { Amplify } from "aws-amplify";
import awsconfig from "./aws-exports";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Hub } from "aws-amplify";
import { globalUserContext } from "./globalContext";
import { MyPlan } from "./pages/MyPlan";
import { EveryTimeChangePath } from "./helper/EveryTimeChangePath";
import { HelmetProvider } from "react-helmet-async";

Amplify.configure(awsconfig);
function App() {
  const [userInfo, setUserInfo] = useState(null);
  const [authState, setAuthState] = useState(null);
  const [authMode, setAuthMode] = useState(""); //"signIn" or "signUp"
  const { user, authStatus } = useAuthenticator((context) => [context.user, context.authStatus]);
  //横スクロールを明示的に無効化
  document.body.style.overflowX = "hidden";

  useEffect(() => {
    const autologinAfterSignUp = async () => {
      Hub.listen("auth", ({ payload }) => {
        const { event } = payload;
        if (event === "autoSignIn") {
          // ユーザー情報再取得のためreload
          window.location.reload();
        } else if (event === "autoSignIn_failure") {
          //reload
          window.location.reload();
        }
      });
    };

    //初回サインアップ時にはuser.attributesがないので、その場合はautologinAfterSignUpを実行する。
    if (user && !user.attributes) {
      autologinAfterSignUp();
      return;
    }
    //サインイン時にはuser.attributesがあるので、その場合はsetUserInfoを実行する。
    setUserInfo(user);
    setAuthState(authStatus);
    return () => {
      setUserInfo(null);
      setAuthState(null);
    };
  }, [user, authStatus, setAuthState, setUserInfo]);

  return (
    <globalUserContext.Provider
      value={{
        userInfo: userInfo,
        authState: authState,
        authMode: authMode,
        setUserInfo: setUserInfo,
        setAuthState: setAuthState,
        setAuthMode: setAuthMode,
      }}
    >
      <HelmetProvider>
        <div className="App">
          <Router>
            <EveryTimeChangePath />
            <Header />
            <AuthModal />
            <Routes>
              <Route path="/" element={<TopPage />} />
              <Route path="create-plan" element={<CreatePlan />} />
              <Route path="create-plan/:id" element={<CreatePlan />} />
              <Route path="find-plan" element={<FindPlan />} />
              <Route path="find-plan/:id" element={<PlanDetail />} />
              {/* <Route path="plan-room/:id" element={<PlanRoom />} /> */}
              <Route path="communtity-policy" element={<CommunityPolicy />} />
              <Route path="user-policy" element={<UserPolicy />} />
              {/* <Route path="confirm-participation" element={<ConfirmParticipation />} /> */}
              <Route path="/mypage/plans" element={<MyPlan />} />
              {/* <Route path="/mypage-register" element={<MypageRegister />} />  */}
              <Route path="*" element={<NotFound />} />
            </Routes>
            <Footer />
          </Router>
        </div>
      </HelmetProvider>
    </globalUserContext.Provider>
  );
}

export default App;
