import { ListCard } from "../../map/ListCard.js";
import { ScheduleInfoCard } from "../../schedule/ScheduleInfoCard.js";
import { SpotInfoCard } from "../../spot/SpotInfoCard.js";
import styles from "../../../assets/css/sheetContents.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const SideSheet = ({
  showListMode,
  setShowListMode,
  selectedScheduleIndex,
  attachPlaceMode,
  showSpotList,
  toggleListMode,
  setAttachPlaceMode,
  setShowEditSpotModal,
  setShowAddSpotModal,
  setShowExistingScheduleModal,
  setAttachedSpot,
  attachedSpot,
  clickedLocation,
  setClickedLocation,
  deleteSchedule,
  addPlaceToSchedule,
  setShowAddScheduleModal,
  setShowEditScheduleModal,
  setSelectedScheduleIndex,
  mutateSpotDone,
  setMutateSpotDone,
  spotList,
  setSpotList,
  selectedSpot,
  setIsScheduleCard,
  isScheduleCard,
  showAddScheduleModal,
  planInfo,
  setPlanInfo,
  S3Keys,
  setS3Keys,
  setSelectedSpot,
  setTempMarker,
  setTempSaveStatus,
  setSearchText,
  handleOnSortContent,
}) => {
  return (
    <div className={styles.sheetContent}>
      {showListMode ? (
        <div className={styles.listBox}>
          <div className={styles.listHeaderBar}>
            <div className={styles.changeListTab}>
              <button
                type="button"
                className={styles.tab}
                disabled={!showSpotList}
                onClick={toggleListMode}
                style={{
                  backgroundColor: !showSpotList ? "#009c74" : null,
                  color: !showSpotList ? "white" : null,
                }}
              >
                スケジュール一覧
              </button>
              <button
                type="button"
                className={styles.tab}
                disabled={showSpotList}
                onClick={toggleListMode}
                style={{
                  backgroundColor: showSpotList ? "#009c74" : null,
                  color: showSpotList ? "white" : null,
                }}
              >
                スポット一覧
              </button>
            </div>
            {/* スケジュール追加ボタン */}
            {!showSpotList && (
              <button
                type="button"
                className={styles.fixedPlusButtonBottom}
                onClick={async () => {
                  setShowAddScheduleModal(true);
                }}
              >
                <FontAwesomeIcon icon={["fa", "plus"]} className={styles.faPlus} />
              </button>
            )}
          </div>
          <ListCard
            setSelectedScheduleIndex={setSelectedScheduleIndex}
            mutateSpotDone={mutateSpotDone}
            setMutateSpotDone={setMutateSpotDone}
            spotList={spotList}
            setSpotList={setSpotList}
            showListMode={showListMode}
            setShowListMode={setShowListMode}
            showSpotList={showSpotList}
            setIsScheduleCard={setIsScheduleCard}
            planInfo={planInfo}
            setShowAddScheduleModal={setShowAddScheduleModal}
            setPlanInfo={setPlanInfo}
            S3Keys={S3Keys}
            setS3Keys={setS3Keys}
            setSelectedSpot={setSelectedSpot}
            setTempSaveStatus={setTempSaveStatus}
            setSearchText={setSearchText}
            handleOnSortContent={handleOnSortContent}
            isMobile={false}
          />
        </div>
      ) : (
        <div className={styles.infoBox}>
          {isScheduleCard ? (
            <ScheduleInfoCard
              spotList={spotList}
              setShowListMode={setShowListMode}
              setShowEditScheduleModal={setShowEditScheduleModal}
              selectedSchedule={planInfo.scheduleList[selectedScheduleIndex]}
              setSelectedScheduleIndex={setSelectedScheduleIndex}
              setIsScheduleCard={setIsScheduleCard}
              attachPlaceMode={attachPlaceMode}
              setAttachPlaceMode={setAttachPlaceMode}
              deleteSchedule={() => deleteSchedule(selectedScheduleIndex)}
              setTempMarker={setTempMarker}
              setSearchText={setSearchText}
              setSelectedSpot={setSelectedSpot}
              setAttachedSpot={setAttachedSpot}
              attachedSpot={attachedSpot}
            />
          ) : (
            <SpotInfoCard
              setShowListMode={setShowListMode}
              showAddScheduleModal={showAddScheduleModal}
              setShowAddScheduleModal={setShowAddScheduleModal}
              setSearchText={setSearchText}
              selectedSpot={selectedSpot}
              setSelectedSpot={setSelectedSpot}
              clickedLocation={clickedLocation}
              setClickedLocation={setClickedLocation}
              setTempMarker={setTempMarker}
              setShowEditSpotModal={setShowEditSpotModal}
              setShowAddSpotModal={setShowAddSpotModal}
              setShowExistingScheduleModal={setShowExistingScheduleModal}
              attachPlaceMode={attachPlaceMode}
              setIsScheduleCard={setIsScheduleCard}
              addPlaceToSchedule={addPlaceToSchedule}
              setAttachedSpot={setAttachedSpot}
            />
          )}
        </div>
      )}
    </div>
  );
};
