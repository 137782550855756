import { Storage } from "aws-amplify";
import UUID from "uuidjs";
/**
 * Upload image data into S3.
 * @param {file} file is uploaded image data from computer.
 * @param {string} filePath is path to store image in S3.
 * @returns {object | never} item | error
 * 'item' has a property that "key" is file name.
 **/
export const tryUploadFile = async (file, filePath = "") => {
  if (!file) {
    throw new Error("file is falthy");
  }
  const uuid = UUID.generate();
  const fileName = `${filePath}${uuid}_${file.name}`;

  try {
    const item = await Storage.put(fileName, file, {
      contentType: `${file.type}`,
    });

    return item;
  } catch (e) {
    console.error("Failed uploding image");
    throw e;
  }
};

/**
 * Delete file from S3.
 * @param {string} key is key stored in S3. Remove any level folder from filename.
 * @param {string} level is following value. "public"(default), "protected" or "private".
 */
export const tryDeleteFile = async (key, level = "public") => {
  if (!key) {
    return;
  }

  try {
     await Storage.remove(key, { level });
  } catch (e) {
    console.error("Failed deleting image", e);
  }
};

/**
 *
 * @param {string} filename is key stored in S3. Remove any level folder from filename.
 * @returns {string | error} signedURL is URL for accessing image by HTTPS.
 */
export const tryGetS3URL = async (filename) => {
  if (!filename) {
    throw new Error("filename is falthy");
  }
  try {
    const result = await Storage.get(filename, { download: true });
    //signedURL expires in 15min
    // data.Body is a Blob
    const url = URL.createObjectURL(result.Body);
    return url;
  } catch (e) {
    console.error("Failed getting image");
    throw e;
  }
};

/**
 * rollback s3 upload when plan registration failed.
 * @param {Array} S3Keys is array of s3 key of plan image (topImage and scheduleimage)already stored in S3. Remove any level folder from filename.
 * @param {Array} imageKeyList is array of s3 key of plan image (topImage and scheduleimage) which is going to be stored in S3 ,but entire registration canceled.
 * @returns null
 */
export const tryRollbackS3Upload = async (S3Keys, imageKeyList) => {
  //もし、S3Keysがない場合、imageKeyListのすべての画像を削除する。
  if (!S3Keys && imageKeyList.length) {
    // すべての画像を削除する
    imageKeyList.forEach((key) => {
      if (!key) return;
      tryDeleteFile(key);
    });
    return;
  }
  //もし、新たに登録されたimageKeyListの内、S3Keysに含まれていない、画像を削除する。
  const deleteKeys = imageKeyList.filter((imageKey) => !S3Keys.includes(imageKey));
  if (deleteKeys.length > 0) {
    await Promise.all(deleteKeys.map((key) => tryDeleteFile(key)))
      .then((res) => {
        console.log("success rollback s3 upload");
        return res;
      })
      .catch((e) => {
        console.error("Failed rollback s3 upload", e);
        throw e;
      });
  } else {
    return;
  }
};
