import { Auth } from "aws-amplify";
/** 
 * @function authStateがconfigingの時にログイン状態を明示的に確認し、authStateを決定する。
 * @param {string} authState
 * @param {function} setAuthState
 * @returns {void}
*/
export const handleConfiguring = (setAuthState, authState) => {
  if (authState === "configuring") {
    Auth.currentUserPoolUser().then(
      (user) => {
        setAuthState("authenticated");
        return;
      },
      (err) => {
		  console.error("err", err);
		  
        setAuthState("unauthenticated");
      }
    );
  } else return;
};
