import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { memo } from "react";
import styles from "../../assets/css/ExistingScheduleModal.module.css";

export const ExistingScheduleModal = memo(
  ({
    planInfo,
    setSelectedScheduleIndex,
    showExistingScheduleModal,
    setShowExistingScheduleModal,
    setShowEditScheduleModal,
    setIsScheduleCard,
  }) => {
    const onClickClose = () => {
      setShowExistingScheduleModal(false);
    };

    return (
      <div>
        {showExistingScheduleModal ? (
          <div id="container" className={styles.content}>
            <div className={styles.inner}>
              <div className={styles.backButton} onClick={onClickClose}>
                <FontAwesomeIcon icon={["fa", "angle-left"]} className="faAngle" />
                <span>戻る</span>
              </div>
              <h1 className={styles.pageTitle}>スケジュールリスト</h1>
              <div className={styles.scheduleBox}>
                {planInfo.scheduleList.map((schedule, index) => (
                  <div className={styles.scheduleTitleBox} key={index}>
                    <button
                      type="button"
                      className={styles.scheduleTitle}
                      onClick={() => {
                        setShowEditScheduleModal(true);
                        setIsScheduleCard(true);
                        setSelectedScheduleIndex(index);
                        setShowExistingScheduleModal(false);
                      }}
                    >
                      {schedule.title}
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
);
