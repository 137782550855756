import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { memo, useEffect, useState } from "react";
import styles from "../../assets/css/inputSpotDetail.module.css";
import { SpotCategoryList, SpotType } from "../../constants/global";
import { ShowErrorMessage } from "../commons/messages/Messages";
import noImage from "../../assets/img/noimage.png";
import imageCompression from "browser-image-compression";
import { ImageCompressionOptions } from "../../constants/global";
import { usePlanImage } from "../../hooks/usePlanImage";
import { tryUploadFile, tryDeleteFile } from "../../helper/s3Access";

export const InputSpotDetail = memo(
  ({ spotType, onClickButton, onClickClose, setSearchText, spotInput, setSpotInput, setShowAddSpotModal }) => {
    const [title, setTitle] = useState("");
    const [buttonText, setButtonText] = useState("");
    const [errorMessageObj, setErrorMessageObj] = useState(null);
    const [imageList, setImageList] = useState([]);
    const [oldImageKeys, setOldImageKeys] = useState([]);
    const [imageCount, setImageCount] = useState(0);
    const { fetchImageURL } = usePlanImage();
    const spotTitle = document.getElementById("spotTitle");

    const scrollToTop = () => {
      if (spotTitle) {
        spotTitle.scrollIntoView({ behavior: "smooth" });
      }
    };

    //スポット画像のデータを保持
    useEffect(() => {
      if (!spotInput) return;
      const getSpotImage = async () => {
        if (spotInput.imageKeys && spotInput.imageKeys.length > 0) {
          const imageKeys = spotInput.imageKeys;
          setOldImageKeys(imageKeys);
          const imageURLs = await fetchImageURL(imageKeys);
          imageURLs.forEach((url) => {
            if (url) imageList.push({ file: null, url: url });
          });
          setImageList([...imageList]);
        }
      };

      getSpotImage();
    }, [spotInput.imageKeys]);

    //spot画像のカウントをセット
    useEffect(() => {
      let count = 0;
      for (let i = 0; i < imageList.length; i++) {
        if (imageList[i].file || imageList[i].url) {
          count++;
        } else continue;
      }
      setImageCount(count);
    }, [imageList]);

    useEffect(() => {
      const checkSpotType = () => {
        if (spotType === SpotType.ADDITION) {
          setTitle("スポットの作成");
          setButtonText("保存");
        } else if (spotType === SpotType.EDITING) {
          setTitle("スポットの変更");
          setButtonText("保存");
        } else if (spotType === SpotType.CONFIRMATION) {
          setTitle("スケジュールの確認");
          setButtonText("閉じる");
        }
      };
      checkSpotType();
    }, [SpotType]);

    const handleTextChangeSpot = (e) => {
      const { name, value } = e.target;
      setSpotInput({ ...spotInput, [name]: value });
    };

    const isValid = () => {
      let errorObj;
      if (!spotInput.name || spotInput.name.trim() === "") {
        errorObj = { ...errorObj, name: "スポット名を入力してください" };
      }
      if (errorObj) {
        setErrorMessageObj(errorObj);
        return false;
      } else {
        return true;
      }
    };
    // urlの数を3つにする
    useEffect(() => {
      if (!spotInput) return;
      const urlCountFormat = () => {
        const objectiveCount = 3;
        if (!spotInput.hasOwnProperty("url")) {
          spotInput.url = new Array(objectiveCount).fill("");
        }
        if (!Array.isArray(spotInput.url)) {
          spotInput.url = new Array(objectiveCount).fill("");
        }
        const realCount = spotInput.url.length;
        if (realCount !== objectiveCount) {
          for (let i = 0; i < objectiveCount - realCount; i++) {
            spotInput.url.push("");
          }
        }
        setSpotInput(spotInput);
      };

      urlCountFormat();
    }, [spotInput]);

    const handleOnClickSubmit = async () => {
      //画像の登録
      const imageKeys = [];
      for (let i = 0; i < imageList.length; i++) {
        //  ×で削除済み
        if (imageList[i].url === "" && imageList[i].url === "") {
          await tryDeleteFile(oldImageKeys[i]);
        }
        // 変更なし
        else if (!imageList[i].file) {
          imageKeys.push(oldImageKeys[i]);
        }
        // 追加
        else {
          const filePath = "spotImage/";
          const item = await tryUploadFile(imageList[i].file, filePath);
          await tryDeleteFile(oldImageKeys[i]);

          imageKeys.push(item.key);
        }
      }
      //スポットの追加
      onClickButton(imageKeys);
    };

    const handleFileSelect = async (event) => {
      const imageFile = event.target.files[0];
      try {
        const compressedFile = await imageCompression(imageFile, ImageCompressionOptions);
        const imageUrl = URL.createObjectURL(compressedFile); //表示
        setImageList([...imageList, { file: compressedFile, url: imageUrl }]);
      } catch (error) {
        console.error(error);
      }
    };

    function imgClose(index) {
      // 保存前の画像のURLを無効化
      URL.revokeObjectURL(imageList[index].url);
      imageList.splice(index, 1, { file: "", url: "" });
      setImageList([...imageList]);
    }

    return (
      <div id="container" className={styles.content}>
        <div className={styles.inner}>
          <div className={styles.inputHeader}>
            <button onClick={onClickClose} className={styles.backButton}>
              <FontAwesomeIcon icon={["fa", "angle-left"]} className="faAngle" />
              <span>戻る</span>
            </button>
            <h1 className={styles.pageTitle}>{title}</h1>
            <div className={styles.addButton}>
              <button
                type="button"
                id="defaultButton"
                onClick={() => {
                  if (isValid()) {
                    handleOnClickSubmit();
                    setSearchText("");
                    setShowAddSpotModal(false);
                  } else {
                    scrollToTop();
                  }
                }}
              >
                {buttonText}
              </button>
            </div>
          </div>
          <div className={styles.inputDetailBox}>
            <h3 className={styles.subtitle}>スポット</h3>
            <div className={styles.inputDetailContents}>
              <h4 className={styles.label}>
                画像 <span>{`${imageCount}/3`}</span>
              </h4>
              {imageList.length > 0 ? (
                <div className={styles.spotImageBoxContainer}>
                  {imageList.map((image, index) => {
                    if (!image.url) {
                      return null;
                    } else {
                      return (
                        <div className={styles.spotImageBox} key={index}>
                          <div id="closeButton" className={styles.closeButtonBox}>
                            <button type="button" className={styles.closeBtn} onClick={() => imgClose(index)}>
                              <FontAwesomeIcon icon={["fa", "fa-times"]} />
                            </button>
                          </div>
                          <img src={image?.url ? image.url : noImage} alt="spotImage" className={styles.image}></img>
                        </div>
                      );
                    }
                  })}
                  <div className={styles.addImageButtonBox}>
                    {imageCount < 3 && (
                      <label id="addBlueButton" className={styles.uploadImageButton}>
                        追加 <FontAwesomeIcon icon={["fa", "plus"]} className={styles.faPlus} />
                        <input
                          type="file"
                          accept=".png, .jpeg, .jpg"
                          onClick={(e) => {
                            e.target.value = "";
                          }}
                          onChange={handleFileSelect}
                          id="inputImage"
                        ></input>
                      </label>
                    )}
                  </div>
                </div>
              ) : (
                <div className={styles.spotImageBoxContainer}>
                  <div className={styles.addImageButtonBox}>
                    <label id="addBlueButton" className={styles.uploadImageButton}>
                      追加 <FontAwesomeIcon icon={["fa", "plus"]} className={styles.faPlus} />
                      <input
                        type="file"
                        accept=".png, .jpeg, .jpg"
                        onClick={(e) => {
                          e.target.value = "";
                        }}
                        onChange={handleFileSelect}
                        id="inputImage"
                      ></input>
                    </label>
                  </div>
                </div>
              )}

              <h4 className={styles.label}>名称</h4>
              <div className={styles.inputBox}>
                <input
                  id="spotTitle"
                  type="text"
                  name="name"
                  maxLength="100"
                  value={spotInput?.name}
                  onChange={handleTextChangeSpot}
                />
              </div>
              <div className={styles.errorMessageBox}>
                {errorMessageObj?.name ? (
                  <div className={styles.errorMessage}>
                    <FontAwesomeIcon icon={["fas", "fa-asterisk"]} className={styles.asterisk} />
                    <ShowErrorMessage message={errorMessageObj.name} />
                  </div>
                ) : (
                  ""
                )}
              </div>

              <h4 className={styles.label}>カテゴリー</h4>
              <select name="category" value={spotInput.category} onChange={handleTextChangeSpot}>
                <option>{spotInput?.category ? spotInput.category : "カテゴリー選択"}</option>
                {SpotCategoryList.map((category, index) => {
                  return (
                    <option name="category" key={index} value={category}>
                      {category}
                    </option>
                  );
                })}
              </select>

              <h4 className={styles.label}>説明</h4>
              <div className={styles.inputBox}>
                <textarea
                  id="description"
                  type="text"
                  name="memo"
                  maxLength="1000"
                  placeholder="説明を入力"
                  value={spotInput && spotInput.memo ? spotInput.memo : ""}
                  onChange={handleTextChangeSpot}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
