import { useState } from "react";
export const useScheduleMarker = () => {
  const [scheduleMarkers, setScheduleMarkers] = useState([]); // [{lat: 0, lng: 0}
  /**
   * add new marker
   * @param {array} scheduleList is list of schedule which contain latlng object.
   * @param {number} scheduleIndex is index of schedule which is selected.
   * @returns none
   */

  const addNewScheduleMarker = (scheduleList) => {
    if (scheduleList[scheduleList.length - 1].latLng) {
      // add new marker
      const lat = scheduleList[scheduleList.length - 1].latLng.lat;
      const lng = scheduleList[scheduleList.length - 1].latLng.lng;
      setScheduleMarkers([...scheduleMarkers, { lat, lng }]);
    } else {
      setScheduleMarkers([...scheduleMarkers, { lat: 0, lng: 0 }]);
    }
  };

  const deleteScheduleMarker = (scheduleIndex) => {
    // 削除したスケジュールのマーカーを削除
    const newScheduleMarkers = scheduleMarkers.filter((scheduleMarkers, index) => index !== scheduleIndex);
    setScheduleMarkers(newScheduleMarkers);
  };

  return {
    scheduleMarkers,
    setScheduleMarkers,
    addNewScheduleMarker,
    deleteScheduleMarker,
  };
};
