import { API, graphqlOperation } from "aws-amplify";
import { createSpot, deleteSpot, updateSpot } from "../../graphql/mutations";
import { getSpot, spotByCreatorId } from "../../graphql/queries";

/**
 * create Spot data into DynamoDB.
 * @param {object} spotInput is 'spotInput' object.
 * @returns {object | never} item | error
 */
export const tryCreateSpot = async (spotInput) => {
  try {
    const item = await API.graphql(
      graphqlOperation(createSpot, {
        input: spotInput,
      })
    );
    return item.data.createSpot;
  } catch (e) {
    console.error("Failed creating a spot Info");
    throw e;
  }
};

/**
 * Fetch a getSpot by a user id(sub).
 * @param {string} creatorId user id(sub).
 * @returns {object | error} A fetched user info.
 */
export const trySpotByCreatorId = async (creatorId) => {
  try {
    const result = await API.graphql(graphqlOperation(spotByCreatorId, { creatorId }));
    return result.data.spotByCreatorId.items;
  } catch (e) {
    console.error("Failed create a spot info");
    throw e;
  }
};

/**
 * Fetch a deleteSpot.
 * @param {string} id spot id.
 * @returns {id} deleted spot id
 */
export const tryDeleteSpots = async (id) => {
  try {
    const result = await API.graphql(graphqlOperation(deleteSpot, { input: { id: id } }));
    return result.data.deleteSpot;
  } catch (e) {
    console.error("Failed delete a spot info");
    throw e;
  }
};

/**
 * Fetch a updateSpot.
 * @param {string} spotInput.
 * @returns {id} update spot id
 */
export const tryUpdateSpot = async (spotInput) => {
  try {
    const item = await API.graphql(
      graphqlOperation(updateSpot, {
        input: spotInput,
      })
    );
    return item.data.updateSpot;
  } catch (e) {
    console.error("Failed update a spot Info", spotInput);
    throw e;
  }
};

/**
 * Fetch a getSpot by a spot id
 * @param {string} id spot id.
 * @returns {id} get spot id
 */
export const tryGetSpot = async (id) => {
  try {
    const result = await API.graphql(graphqlOperation(getSpot, { id }));
    return result.data.getSpot;
  } catch (e) {
    console.error("Failed get a spot info");
    throw e;
  }
};
