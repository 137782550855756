import React, { useRef, useState, useEffect } from "react";
import styles from "../assets/css/findPlan.module.css";
import tagBarStyles from "../assets/css/searchPlan.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { FilterBoxPC, FilterBoxMob } from "../components/listplans/FilterBox";
import { DISCLOSURE_RANGE, POTABI_ACCOUNT, HAJIMESTRANGE_ACCOUNT } from "../constants/planConstants";
import { ListPlansByDisclosureRange, ListRidePlansByCreator } from "../components/listplans/ListPlans";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { RIDE_PURPOSE_LIST } from "../constants/planConstants";

export const FindPlan = () => {
  const [selectedTag, setSelectedTag] = useState(null);
  const tagRef = useRef(null);
  //目的タグフィルターUI切替
  useEffect(() => {
    if (!selectedTag) return;
    const { tagName, index } = selectedTag.purpose;
    let selectedElem = document.getElementById(`tag-` + index);
    //一旦すべて元のスタイルに戻す
    for (let i = 0; i < RIDE_PURPOSE_LIST.length; i++) {
      let tagElem = document.getElementById(`tag-` + index);
      selectedElem.classList.add(`${tagBarStyles.unSelectedTag}`);
      tagElem.classList.remove(`${tagBarStyles.selectedTag}`);
    }

    //前回と同じタグが選択された場合
    if (selectedTag.prev && tagName === selectedTag.prev.purpose.tagName) {
      setSelectedTag(null);
      return;
    }
    //選択されたタグにスタイル付与
    selectedElem.classList.add(`${tagBarStyles.selectedTag}`);
    selectedElem.classList.remove(`${tagBarStyles.unSelectedTag}`);
  }, [selectedTag]);

  const EventTagBar = ({ setSelectedTag }) => {
    return (
      <div className={tagBarStyles.tagsBar} ref={tagRef}>
        {RIDE_PURPOSE_LIST.map((item, i) => {
          return (
            <div className={tagBarStyles.tagBox} key={i}>
              <div
                id={`tag-` + i}
                className={`${tagBarStyles.tag} ${tagBarStyles.unSelectedTag}`}
                onClick={() => {
                  setSelectedTag((prevState) => {
                    return { prev: prevState, purpose: { tagName: item, index: i } };
                  });
                }}
              >
                {item}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div id="container">
      <Helmet>
        <title>プランをさがす | pobicle / ポビクル</title>
        <meta
          charSet="utf-8"
          name="プランをさがす"
          description="「プランをさがす」pobicleユーザーが作った小さな旅のプランの中から休日の予定を見つけることができます。 - pobicle(ポビクル)"
        />
        <meta property="og:url" content="https://www.pobicle.com/find-plan" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="pobicle（ポビクル）は、小さな旅を楽しむためのWebサイトです。休日に思い立ってふらりと隣の街に出かけて、お散歩の延長みたいに楽しむ気楽な旅。素敵な場所や人と出会う旅に、ポビクルが寄り添います。"
        />
        <meta property="og:site_name" content="pobicle" />
        <meta property="og:image" content="%PUBLIC_URL%/logowithslogan.png" />
        <meta property="og:image:width" content="600" />
        <meta property="og:image:height" content="300" />
        <link rel="canonical" href="https://www.pobicle.com/find-plan" />
      </Helmet>
      {/* <FilterBoxMob isfilterOpened={isfilterOpened} setIsfilterOpened={setIsfilterOpened} /> */}
      <div className={styles.mainTitle}>
        <h1 id="pageTitle" className={styles.pageTitle}>
          プランをさがす
        </h1>
        {/* ダミー要素 */}
        <div className={styles.notShowFilterButton}></div>
        {/* 絞り込み機能未実装のため非表示 */}
        {/* <button
          type="button"
          className={isfilterOpened ? styles.notShowFilterButton : styles.filterButton}
          onClick={() => {
            setIsfilterOpened(isfilterOpened ? false : true);
          }}
          id="filterBtn"
        >
          <FontAwesomeIcon icon={["fa", "filter"]} />
          絞り込む
        </button> */}
      </div>
      <h2 className={styles.message} id="findPlanSubtitle">
        小さな旅に出かけよう
      </h2>
      <EventTagBar setSelectedTag={setSelectedTag} />
      <section className={styles.planListBox}>
        <div className={styles.listTitleBox}>
          <h3 className={styles.listTitle}>愛知県東栄町 ぽたび×pobicle</h3>
          <p className={styles.listCaption}>
            東三河の街と山、電動自転車に乗って繋いでく。出張企画やガイドツアーも盛りだくさん！
          </p>
        </div>
        <div className={styles.planBox}>
          {/* <FilterBoxPC /> */}
          <ListRidePlansByCreator
            creator={POTABI_ACCOUNT}
            disclosureRange={DISCLOSURE_RANGE.PUBLIC}
            isTemp={false}
            selectedTag={selectedTag}
            slider={true}
          />
        </div>
      </section>
      <section className={styles.planListBox}>
        <div className={styles.listTitleBox}>
          <h3 className={styles.listTitle}>とある編集者のちょっとへんな旅の記録</h3>
          <p className={styles.listCaption}>
            奈良在住の編集者が、ふらりと出かけた先でみたものは？ふつうの暮らしに訪れる小さな旅の記録をお届けします。
          </p>
        </div>
        <div className={styles.planBox}>
          {/* <FilterBoxPC /> */}
          <ListRidePlansByCreator
            creator={HAJIMESTRANGE_ACCOUNT}
            limit={25}
            disclosureRange={DISCLOSURE_RANGE.PUBLIC}
            isTemp={false}
            selectedTag={selectedTag}
            slider={true}
          />
        </div>
      </section>
      <section className={styles.planListBox}>
        <div className={styles.listTitleBox}>
          <h3 className={styles.listTitle}>公開中のプラン</h3>
          <p className={styles.listCaption}>
            各地のpobicleユーザーがつくった個性豊かなプラン達。あなたにぴったりのプランをさがそう。
          </p>
        </div>
        <div className={styles.planBox}>
          {/* <FilterBoxPC /> */}
          <ListPlansByDisclosureRange isTemp={false} selectedTag={selectedTag} />
        </div>
      </section>

      <div>
        <Link to={"/create-plan"}>
          <button type="button" className={styles.fixedPlusButton} >
            <FontAwesomeIcon icon={["fa", "plus"]} className={styles.faPlus} />
          </button>
        </Link>
      </div>
    </div>
  );
};
