import { useState } from "react";
import { initSpotInputInfo } from "../constants/initState";

export const useSpotInput = () => {
  const [spotInput, setSpotInput] = useState(initSpotInputInfo);

  const handleSpotURLChange = (e, index, prevList) => {
    const { name, value } = e.target;
    const list = prevList;
    list[index] = value;
    setSpotInput({ ...spotInput, [name]: list });
  };

  const resetSpotInput = () => {
    setSpotInput(initSpotInputInfo); //スポットstateを初期化
  };

  return {
    spotInput,
    setSpotInput,
    handleSpotURLChange,
    resetSpotInput,
  };
};
