import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Autocomplete, useLoadScript } from "@react-google-maps/api";
import React, { useState } from "react";
import styles from "../../assets/css/map/googleAutoComp.module.css";
import config from "../../constants/config.json";
//reuseable google auto complete component
export const GoogleAutoComplete = ({ afterGetAutoComp, placeholder = null }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries: config.googleMap.libraries,
  });
  const [autocomplete, setAutocomplete] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [searchResult, setSearchResult] = useState("");
  const onAutoCompSelected = (setSearchText, setSearchResult) => {
    if (autocomplete !== null) {
      //1.getplace()で住所文字列を取得する
      const place = autocomplete.getPlace();
      //2コンポーネントの使用する場所に応じた処理を行う
      afterGetAutoComp(place, setSearchText, setSearchResult);
    } else {
      return;
    }
  };

  const handleDeleteAutoComp = () => {
    setSearchResult("");
    setSearchText("");
  };

  return (
    <div className={styles.searchBox}>
      {isLoaded && (
        <Autocomplete
          className={styles.autoComplete}
          onLoad={(props) => {
            setAutocomplete(props);
          }}
          onPlaceChanged={() => onAutoCompSelected(setSearchText, setSearchResult)}
        >
          <input
            type="text"
            name="searchText"
            maxLength="100"
            placeholder={placeholder ? placeholder : "検索"}
            autoComplete="off"
            value={searchText ? searchText : searchResult}
            onChange={(e) => {
              setSearchResult("");
              setSearchText(e.target.value);
            }}
          ></input>
        </Autocomplete>
      )}
      <button
        type="button"
        id="closeBtn"
        className={styles.closeBtn}
        onClick={() => {
          handleDeleteAutoComp();
        }}
      >
        <FontAwesomeIcon icon={["fa", "fa-times"]} />
      </button>
    </div>
  );
};
