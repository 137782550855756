import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "../../assets/css/confirmResultModal.module.css";
import bikeImage from "../../assets/img/bike-illust1.png";
import { initPlanInfo } from "../../constants/initState";

export const ConfirmResultModal = ({ confirmResult, planInfo, setShowPreview, editMode, topImage }) => {
  useEffect(() => {
    if (confirmResult.result === false || confirmResult.result === true) document.body.style.overflow = "hidden"; //モーダルの背景のスクロールを無効化
  }, [confirmResult.result]);

  const onClick = () => {
    document.body.style.overflow = "scroll";
    setShowPreview(false);
  };
  if (confirmResult.result === true) {
    return (
      <div className={styles.overlay}>
        <div className={styles.modalContent}>
          {confirmResult.mode ? (
            <p>
              プランの下書きを保存しました。<br></br>マイプランからいつでもプランを編集できます。
            </p>
          ) : (
            <p>
              プランの作成が完了しました。<br></br>マイプランからいつでもプランを確認できます。
            </p>
          )}
          <div className={styles.buttonBox}>
            <Link to={"/mypage/plans"}>
              <button className={styles.mypageButton} onClick={() => onClick()}>
                マイプランへ
              </button>
            </Link>
            <Link to={"/create-plan"} state={{ state: { planInfo: initPlanInfo, files: [], imgUrls: [] } }}>
              <button className={styles.toCreatePlan} onClick={() => onClick()}>
                新しいプランを作成する
              </button>
            </Link>
          </div>
          <img src={bikeImage} alt="自転車"></img>
        </div>
      </div>
    );
  } else if (confirmResult.result === false) {
    return (
      <div className={styles.overlay}>
        <div className={styles.modalContent}>
          <p>プランの作成に失敗しました。</p>
          <p>もう一度プランを作成してください。</p>
          <Link
            to={planInfo.id ? `/create-plan/${planInfo.id}` : "/create-plan"}
            state={{ state: { planInfo: planInfo, mode: editMode, topImage: topImage } }}
          >
            <button className={styles.toCreatePlan} onClick={() => onClick()}>
              プランをつくる
            </button>
          </Link>
        </div>
      </div>
    );
    // return null;
  } else {
    return null;
  }
};
