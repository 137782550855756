import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import styles from "../../assets/css/infoCard.module.css";
import { CrossCloseButtonRight } from "../commons/buttons/CrossCloseButton.js";
import { tryDeleteSpots } from "../../helper/dbAccess/spotAccess";
import { MiddleSnapHeight } from "../../constants/global.js";
import { ImageByKey } from "../image/ImageByKey.js";

export const SpotInfoCard = ({
  setShowListMode,
  setShowAddScheduleModal,
  setSearchText,
  selectedSpot,
  setSelectedSpot,
  clickedLocation,
  setClickedLocation,
  setTempMarker,
  setShowEditSpotModal,
  setShowAddSpotModal,
  setShowExistingScheduleModal,
  attachPlaceMode,
  setIsScheduleCard,
  addPlaceToSchedule,
}) => {
  const [spotInfo, setSpotInfo] = useState(null);
  //スポット情報のセット
  useEffect(() => {
    if (!selectedSpot && !clickedLocation) return;
    if (clickedLocation === "error") {
      setSpotInfo({ name: "位置情報の取得に失敗しました" });
      setTempMarker(null);
    } else if (clickedLocation) {
      //クリックもしくは検索された場合
      setSpotInfo(clickedLocation);
    } else if (selectedSpot) {
      //選択されたスポット情報がある場合
      setSpotInfo(selectedSpot);
      setTempMarker({
        lat: selectedSpot.latLng.lat,
        lng: selectedSpot.latLng.lng,
        isEdit: false,
      });
    }
  }, [selectedSpot, clickedLocation]);

  const backToList = () => {
    setShowListMode(true);
    setSelectedSpot(null);
    setClickedLocation(null);
    setTempMarker(null);
    setSearchText("");
  };

  const backToScheduleCard = () => {
    setClickedLocation(null);
    setSelectedSpot(null);
    setTempMarker(null);
    setIsScheduleCard(true);
    setSearchText("");
  };

  const deleteSpot = async () => {
    if (window.confirm("このスポットを削除します。よろしいですか?")) {
      await tryDeleteSpots(spotInfo.id);
      setTempMarker(null);
      setSelectedSpot(null);
      setShowListMode(true);
    } else return;
  };
  return (
    <div
      className={styles.card}
    >
      <div className={styles.backButtonBox}>
        <CrossCloseButtonRight onClick={attachPlaceMode ? backToScheduleCard : backToList} />
      </div>
      <div className={styles.infoContainer}>
        {spotInfo && (
          <div className={styles.info}>
            <div className={styles.titleBox}>
              <h4 className={styles.title}>{spotInfo.name ? spotInfo.name : spotInfo.address}</h4>
            </div>
          </div>
        )}
        {spotInfo && (
          <div className={styles.googleMapBtnBox}>
            <a
              href={`https://www.google.com/maps/search/?api=1&query=${spotInfo.address}&query_place_id=${spotInfo.googlePlaceId}`}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.googleMapBtn}
            >
              GoogleMapで見る
            </a>
          </div>
        )}
        {/* 通常表示*/}
        {!attachPlaceMode ? (
          <div className={styles.actionButtonBox}>
            {selectedSpot && (
              <button
                type="button"
                className={styles.actionButton}
                onClick={() => {
                  setShowEditSpotModal(true);
                }}
              >
                <FontAwesomeIcon
                  icon={["fa", "fa-pen"]}
                  id="actionButtonIcon"
                  className={styles.actionButtonIconCircle}
                />
                <span>編集</span>
              </button>
            )}
            {clickedLocation && (
              <button
                className={styles.actionButton}
                type="button"
                onClick={() => {
                  setShowAddSpotModal(true);
                }}
              >
                <FontAwesomeIcon
                  icon={["fa", "fa-map-location"]}
                  id="actionButtonIcon"
                  className={styles.actionButtonIconCircle}
                />
                <span>スポット新規作成</span>
              </button>
            )}
            <button
              type="button"
              className={styles.actionButton}
              onClick={() => {
                setShowAddScheduleModal(true);
              }}
            >
              <FontAwesomeIcon
                icon={["fa", "fa-calendar-plus"]}
                id="actionButtonIcon"
                className={styles.actionButtonIconCircle}
              />
              <span>スケジュール新規作成</span>
            </button>
            <button
              type="button"
              className={styles.actionButton}
              onClick={() => {
                setShowExistingScheduleModal(true);
              }}
            >
              <FontAwesomeIcon
                icon={["fa", "fa-calendar-check"]}
                id="actionButtonIcon"
                className={styles.actionButtonIconCircle}
              />
              <span>既存スケジュールに付与</span>
            </button>
            {selectedSpot && (
              <button
                type="button"
                className={styles.actionButton}
                onClick={() => {
                  deleteSpot();
                }}
              >
                <FontAwesomeIcon
                  icon={["fas", "fa-trash-alt"]}
                  id="actionButtonIcon"
                  className={styles.deleteButtonIconCircle}
                />
                <span>削除</span>
              </button>
            )}
          </div>
        ) : (
          //  位置情報の追加/変更モード
          <div className={styles.actionButtonBox}>
            <button
              type="button"
              className={styles.actionButton}
              onClick={() => {
                addPlaceToSchedule();
                backToScheduleCard();
              }}
            >
              <FontAwesomeIcon icon={["fa", "plus"]} id="actionButtonIcon" className={styles.actionButtonIconCircle} />
              <span>スケジュールに付与</span>
            </button>
          </div>
        )}
        {selectedSpot?.imageKeys.length > 0 && (
          <div className={styles.imageRowBox}>
            {selectedSpot.imageKeys.map((key) => {
              return (
                <div className={styles.imageBox}>
                  <ImageByKey imageKey={key} alt={"spotImage"} />
                </div>
              );
            })}
          </div>
        )}

        {selectedSpot?.memo && (
          <div className={styles.infoBox}>
            <div className={styles.info}>{selectedSpot.memo}</div>
          </div>
        )}
        {selectedSpot?.address && (
          <div className={styles.infoBox}>
            <h4 className={styles.infoLabel}>住所</h4>
            <div className={styles.info}>{selectedSpot.address}</div>
          </div>
        )}
      </div>
    </div>
  );
};
