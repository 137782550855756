import { Oval } from "react-loader-spinner";
import styles from "../../assets/css/global/loadingIcon.module.css";

export const LoadingIcon = () => {
  return (
    <div className={styles.loadingIconBox}>
      <div className={styles.loadindIconContent}>
        <Oval color="#009C74" secondaryColor="#ffffff00" height={80} width={80} speedMultiplier={1} />
        <div className={styles.loading}> Loading...</div>
      </div>
    </div>
  );
};
