import React, { useState, useContext } from "react";
import { Auth } from "aws-amplify";
import styles from "../../assets/css/auth/authStatus.module.css";
import "@aws-amplify/ui-react/styles.css";
import { globalUserContext } from "../../globalContext";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EditNickName } from "../auth/EditNickName";

export function AuthStatus({ closeMenu }) {
  const { userInfo, authState, setAuthState, setAuthMode } = useContext(globalUserContext);
  const [isOpenNameEdit, setIsOpenNameEdit] = useState(false);
  const location = useLocation();
  if (userInfo && authState === "authenticated") {
    //ログイン済み
    return !isOpenNameEdit ? (
      <div className={styles.loginUser}>
        <div className={styles.usernameBox}>
          <div className={styles.greeting}>こんにちは、</div>
          <div className={styles.username}>
            {userInfo.attributes.preferred_username
              ? `${userInfo.attributes.preferred_username} さん`
              : "ニックネームを設定しましょう"}
          </div>
        </div>
        <div className={styles.handleUserStatusButton}>
          <button
            className={styles.logoutButton}
            onClick={async () => {
              try {
                if (location.pathname === "/create-plan" || location.pathname === "/confirm-plan") {
                  if (!window.confirm("入力内容が保存されない可能性があります。本当にログアウトをしますか？")) {
                    return;
                  }
                }
                await Auth.signOut();
                // make sure auth is logged out
                setAuthState("unauthenticated");
                setAuthMode(false);
              } catch (err) {
                console.error(err);
              }
            }}
          >
            ログアウト
          </button>
          <button className={styles.logoutButton} onClick={() => setIsOpenNameEdit(true)}>
            ニックネーム変更
            <FontAwesomeIcon icon={["fas", "fa-pen"]} className={styles.faPen} />
          </button>
        </div>
      </div>
    ) : (
      <EditNickName setIsOpenNameEdit={setIsOpenNameEdit} />
    );
  } else {
    return (
      <div className={styles.authButtonBox}>
        <button
          className={styles.loginButton}
          onClick={() => {
            setAuthMode("signIn");
            closeMenu();
          }}
        >
          ログイン
        </button>
        <button
          className={styles.greenButton}
          onClick={() => {
            setAuthMode("signUp");
            closeMenu();
          }}
        >
          新規登録
        </button>
      </div>
    );
  }
}
