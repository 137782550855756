import { tryRollbackS3Upload } from "./s3Access";
import { tryCreateRidePlan, tryUpdateRidePlan } from "./dbAccess/planAccess";
import { tryDeleteFile, tryUploadFile } from "./s3Access";

/**
 * @param { object } topImage is 'topImage' object.
 * @param { array } S3Keys is 'S3Keys' array.
 * @param { function } setS3Keys is 'setS3Keys' function.
 * @param { object } planInfo is 'planInfo' object.
 * @param { object } userInfo is 'userInfo' object.
 * @param { string|null } editMode is 'editMode' boolean.
 * @param { function } setRouteKeysWillBeDeleted is 'setRouteKeysWillBeDeleted' function.
 * @param { array } routeKeysWillBeDeleted is 'routeKeysWillBeDeleted' array.
 * @param { boolean } isTempSave is 'isTempSave' boolean.
 * @returns { id} planInfo.id | { error }
 * @description
 * 1. プラン情報を作成
 * 2. プラン情報を保存
 * 3. 既存のルートデータをS3から削除
 * 4. 置き換えられた画像を削除
 * 5. S3Keysを更新
 **/

export const savePlan = async (
  topImage,
  S3Keys,
  setS3Keys = null,
  planInfo,
  userInfo,
  editMode,
  setRouteKeysWillBeDeleted = null,
  routeKeysWillBeDeleted,
  isTempSave
) => {
  // 1. プラン情報を作成
  let planInfoWithImage = "";
  let imageKeyList = [];
  // 0 メイン画像の処理
  // 0.1 編集モード＆既にS3Keysがある場合
  try {
    // 変更あり
    if (topImage.file || topImage.file === "") {
      //新規画像を登録
      if (topImage.file) {
        const item = await tryUploadFile(topImage.file);
        imageKeyList[0] = item.key;
      }
      // ×で削除した場合
      else {
        imageKeyList[0] = "";
      }
    }
    //変更なし
    else if (topImage.file === null && S3Keys && S3Keys[0]) {
      // s3keyをそのまま入れる
      imageKeyList[0] = S3Keys[0];
    } else {
      imageKeyList[0] = "";
    }
    // 1 スケジュール画像の処理
    // 1.2 画像をS3に登録
    for (let i = 0; i < planInfo.scheduleList.length; i++) {
      //変更なし
      if (planInfo.scheduleList[i].image.file === null) {
        //既に登録済みの画像をそのまま入れる
        if (S3Keys && S3Keys[i + 1]) {
          imageKeyList.push(S3Keys[i + 1]);
          continue;
        } else {
          imageKeyList.push("");
          continue;
        }
      }
      // 変更あり
      else if (planInfo.scheduleList[i].image.file || planInfo.scheduleList[i].image.file === "") {
        //新規画像を登録
        if (planInfo.scheduleList[i].image.file) {
          const item = await tryUploadFile(planInfo.scheduleList[i].image.file);
          imageKeyList.push(item.key);
          continue;
        }
        // ×で削除した場合
        else {
          imageKeyList.push("");
        }
      }
      //変更なし
      else if (planInfo.scheduleList[i].image.file === null && S3Keys[i + 1]) {
        // s3keyをそのまま入れる
        imageKeyList.push(S3Keys[i + 1]);
        continue;
      } else {
        imageKeyList.push("");
        continue;
      }
    }
  } catch (e) {
    throw e;
  }
  // 登録用のscheduleListを準備
  const copiedScheduleList = JSON.parse(JSON.stringify(planInfo.scheduleList)); //deep copy planInfo.scheduleList
  const scheduleListWithoutImage = copiedScheduleList.map((schedule) => {
    if (schedule.image) delete schedule.image;
    return schedule;
  });
  let image = "";
  let scheduleImages = [];
  imageKeyList.forEach((key, i) => {
    if (i === 0) {
      image = key;
    } else {
      scheduleImages.push(key);
    }
  });
  //2 プラン情報を作成
  planInfoWithImage = {
    ...planInfo,
    scheduleList: scheduleListWithoutImage,
    image: image,
    scheduleImages: scheduleImages,
  };
  //3. create or update ride plan
  let result = "";
  try {
    const preparedPlanInfo = planInfoWithImage ? planInfoWithImage : planInfo;
    if (preparedPlanInfo.id) {
      //IDありの場合、更新
      result = await tryUpdateRidePlan({ ...preparedPlanInfo, isTemp: isTempSave }, userInfo);
    } else {
      //新規保存の場合、新規作成
      result = await tryCreateRidePlan({ ...preparedPlanInfo, isTemp: isTempSave }, userInfo);
      window.history.replaceState(null, null, "/create-plan/" + result.id);
    }

    //3 delete route data
    if (routeKeysWillBeDeleted.length > 0) {
      for (let i = 0; i < routeKeysWillBeDeleted.length; i++) {
        await tryDeleteFile(routeKeysWillBeDeleted[i]);
        setRouteKeysWillBeDeleted && setRouteKeysWillBeDeleted([]);
      }
    }

    //4 置き換えられた画像を削除
    if (S3Keys) {
      S3Keys.forEach(async (key) => {
        if (!imageKeyList.includes(key)) {
          await tryDeleteFile(key);
        }
      });
      //5 S3Keysを更新
      setS3Keys && setS3Keys(imageKeyList);
    }
    return result.id;
  } catch (e) {
    // If fail registering, delete uploaded image and re-upload original keys
    console.error(e);
    tryRollbackS3Upload(S3Keys, imageKeyList);
    throw e;
  }
};
