/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createSpot = /* GraphQL */ `
  mutation CreateSpot(
    $input: CreateSpotInput!
    $condition: ModelSpotConditionInput
  ) {
    createSpot(input: $input, condition: $condition) {
      id
      creatorId
      name
      address
      googlePlaceId
      latLng {
        lat
        lng
      }
      category
      memo
      disclosureRange
      imageKeys
      url
      createdAt
      updatedAt
    }
  }
`;
export const updateSpot = /* GraphQL */ `
  mutation UpdateSpot(
    $input: UpdateSpotInput!
    $condition: ModelSpotConditionInput
  ) {
    updateSpot(input: $input, condition: $condition) {
      id
      creatorId
      name
      address
      googlePlaceId
      latLng {
        lat
        lng
      }
      category
      memo
      disclosureRange
      imageKeys
      url
      createdAt
      updatedAt
    }
  }
`;
export const deleteSpot = /* GraphQL */ `
  mutation DeleteSpot(
    $input: DeleteSpotInput!
    $condition: ModelSpotConditionInput
  ) {
    deleteSpot(input: $input, condition: $condition) {
      id
      creatorId
      name
      address
      googlePlaceId
      latLng {
        lat
        lng
      }
      category
      memo
      disclosureRange
      imageKeys
      url
      createdAt
      updatedAt
    }
  }
`;
export const createRidePlan = /* GraphQL */ `
  mutation CreateRidePlan(
    $input: CreateRidePlanInput!
    $condition: ModelRidePlanConditionInput
  ) {
    createRidePlan(input: $input, condition: $condition) {
      id
      creator
      image
      title
      description
      timeRequired
      purpose
      prefecture
      city
      disclosureRange
      isTemp
      rideLevel
      bicycleType
      scheduleList {
        title
        startTime
        endTime
        place
        latLng {
          lat
          lng
        }
        address
        url
        phone
        memo
        spotId
      }
      scheduleImages
      routeDataKey
      date
      deadline
      maxParticipants
      minParticipants
      belongings
      price
      paymentMethod
      notes
      applyMethods
      createdAt
      updatedAt
      isCycling
      isTour
      members {
        items {
          id
          ridePlanID
          userID
          createdAt
          updatedAt
        }
        nextToken
      }
      originalPlanId
      originalPlan {
        id
        creator
        image
        title
        description
        timeRequired
        purpose
        prefecture
        city
        disclosureRange
        isTemp
        rideLevel
        bicycleType
        scheduleList {
          title
          startTime
          endTime
          place
          address
          url
          phone
          memo
          spotId
        }
        scheduleImages
        routeDataKey
        date
        deadline
        maxParticipants
        minParticipants
        belongings
        price
        paymentMethod
        notes
        applyMethods
        createdAt
        updatedAt
        isCycling
        isTour
        members {
          nextToken
        }
        originalPlanId
        originalPlan {
          id
          creator
          image
          title
          description
          timeRequired
          purpose
          prefecture
          city
          disclosureRange
          isTemp
          rideLevel
          bicycleType
          scheduleImages
          routeDataKey
          date
          deadline
          maxParticipants
          minParticipants
          belongings
          price
          paymentMethod
          notes
          applyMethods
          createdAt
          updatedAt
          isCycling
          isTour
          originalPlanId
        }
        duplicatedPlans {
          nextToken
        }
      }
      duplicatedPlans {
        items {
          id
          creator
          image
          title
          description
          timeRequired
          purpose
          prefecture
          city
          disclosureRange
          isTemp
          rideLevel
          bicycleType
          scheduleImages
          routeDataKey
          date
          deadline
          maxParticipants
          minParticipants
          belongings
          price
          paymentMethod
          notes
          applyMethods
          createdAt
          updatedAt
          isCycling
          isTour
          originalPlanId
        }
        nextToken
      }
    }
  }
`;
export const updateRidePlan = /* GraphQL */ `
  mutation UpdateRidePlan(
    $input: UpdateRidePlanInput!
    $condition: ModelRidePlanConditionInput
  ) {
    updateRidePlan(input: $input, condition: $condition) {
      id
      creator
      image
      title
      description
      timeRequired
      purpose
      prefecture
      city
      disclosureRange
      isTemp
      rideLevel
      bicycleType
      scheduleList {
        title
        startTime
        endTime
        place
        latLng {
          lat
          lng
        }
        address
        url
        phone
        memo
        spotId
      }
      scheduleImages
      routeDataKey
      date
      deadline
      maxParticipants
      minParticipants
      belongings
      price
      paymentMethod
      notes
      applyMethods
      createdAt
      updatedAt
      isCycling
      isTour
      members {
        items {
          id
          ridePlanID
          userID
          createdAt
          updatedAt
        }
        nextToken
      }
      originalPlanId
      originalPlan {
        id
        creator
        image
        title
        description
        timeRequired
        purpose
        prefecture
        city
        disclosureRange
        isTemp
        rideLevel
        bicycleType
        scheduleList {
          title
          startTime
          endTime
          place
          address
          url
          phone
          memo
          spotId
        }
        scheduleImages
        routeDataKey
        date
        deadline
        maxParticipants
        minParticipants
        belongings
        price
        paymentMethod
        notes
        applyMethods
        createdAt
        updatedAt
        isCycling
        isTour
        members {
          nextToken
        }
        originalPlanId
        originalPlan {
          id
          creator
          image
          title
          description
          timeRequired
          purpose
          prefecture
          city
          disclosureRange
          isTemp
          rideLevel
          bicycleType
          scheduleImages
          routeDataKey
          date
          deadline
          maxParticipants
          minParticipants
          belongings
          price
          paymentMethod
          notes
          applyMethods
          createdAt
          updatedAt
          isCycling
          isTour
          originalPlanId
        }
        duplicatedPlans {
          nextToken
        }
      }
      duplicatedPlans {
        items {
          id
          creator
          image
          title
          description
          timeRequired
          purpose
          prefecture
          city
          disclosureRange
          isTemp
          rideLevel
          bicycleType
          scheduleImages
          routeDataKey
          date
          deadline
          maxParticipants
          minParticipants
          belongings
          price
          paymentMethod
          notes
          applyMethods
          createdAt
          updatedAt
          isCycling
          isTour
          originalPlanId
        }
        nextToken
      }
    }
  }
`;
export const deleteRidePlan = /* GraphQL */ `
  mutation DeleteRidePlan(
    $input: DeleteRidePlanInput!
    $condition: ModelRidePlanConditionInput
  ) {
    deleteRidePlan(input: $input, condition: $condition) {
      id
      creator
      image
      title
      description
      timeRequired
      purpose
      prefecture
      city
      disclosureRange
      isTemp
      rideLevel
      bicycleType
      scheduleList {
        title
        startTime
        endTime
        place
        latLng {
          lat
          lng
        }
        address
        url
        phone
        memo
        spotId
      }
      scheduleImages
      routeDataKey
      date
      deadline
      maxParticipants
      minParticipants
      belongings
      price
      paymentMethod
      notes
      applyMethods
      createdAt
      updatedAt
      isCycling
      isTour
      members {
        items {
          id
          ridePlanID
          userID
          createdAt
          updatedAt
        }
        nextToken
      }
      originalPlanId
      originalPlan {
        id
        creator
        image
        title
        description
        timeRequired
        purpose
        prefecture
        city
        disclosureRange
        isTemp
        rideLevel
        bicycleType
        scheduleList {
          title
          startTime
          endTime
          place
          address
          url
          phone
          memo
          spotId
        }
        scheduleImages
        routeDataKey
        date
        deadline
        maxParticipants
        minParticipants
        belongings
        price
        paymentMethod
        notes
        applyMethods
        createdAt
        updatedAt
        isCycling
        isTour
        members {
          nextToken
        }
        originalPlanId
        originalPlan {
          id
          creator
          image
          title
          description
          timeRequired
          purpose
          prefecture
          city
          disclosureRange
          isTemp
          rideLevel
          bicycleType
          scheduleImages
          routeDataKey
          date
          deadline
          maxParticipants
          minParticipants
          belongings
          price
          paymentMethod
          notes
          applyMethods
          createdAt
          updatedAt
          isCycling
          isTour
          originalPlanId
        }
        duplicatedPlans {
          nextToken
        }
      }
      duplicatedPlans {
        items {
          id
          creator
          image
          title
          description
          timeRequired
          purpose
          prefecture
          city
          disclosureRange
          isTemp
          rideLevel
          bicycleType
          scheduleImages
          routeDataKey
          date
          deadline
          maxParticipants
          minParticipants
          belongings
          price
          paymentMethod
          notes
          applyMethods
          createdAt
          updatedAt
          isCycling
          isTour
          originalPlanId
        }
        nextToken
      }
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      nickName
      icon
      PlansJoined {
        items {
          id
          ridePlanID
          userID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      nickName
      icon
      PlansJoined {
        items {
          id
          ridePlanID
          userID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      nickName
      icon
      PlansJoined {
        items {
          id
          ridePlanID
          userID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPlanMembers = /* GraphQL */ `
  mutation CreatePlanMembers(
    $input: CreatePlanMembersInput!
    $condition: ModelPlanMembersConditionInput
  ) {
    createPlanMembers(input: $input, condition: $condition) {
      id
      ridePlanID
      userID
      ridePlan {
        id
        creator
        image
        title
        description
        timeRequired
        purpose
        prefecture
        city
        disclosureRange
        isTemp
        rideLevel
        bicycleType
        scheduleList {
          title
          startTime
          endTime
          place
          address
          url
          phone
          memo
          spotId
        }
        scheduleImages
        routeDataKey
        date
        deadline
        maxParticipants
        minParticipants
        belongings
        price
        paymentMethod
        notes
        applyMethods
        createdAt
        updatedAt
        isCycling
        isTour
        members {
          nextToken
        }
        originalPlanId
        originalPlan {
          id
          creator
          image
          title
          description
          timeRequired
          purpose
          prefecture
          city
          disclosureRange
          isTemp
          rideLevel
          bicycleType
          scheduleImages
          routeDataKey
          date
          deadline
          maxParticipants
          minParticipants
          belongings
          price
          paymentMethod
          notes
          applyMethods
          createdAt
          updatedAt
          isCycling
          isTour
          originalPlanId
        }
        duplicatedPlans {
          nextToken
        }
      }
      user {
        id
        nickName
        icon
        PlansJoined {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePlanMembers = /* GraphQL */ `
  mutation UpdatePlanMembers(
    $input: UpdatePlanMembersInput!
    $condition: ModelPlanMembersConditionInput
  ) {
    updatePlanMembers(input: $input, condition: $condition) {
      id
      ridePlanID
      userID
      ridePlan {
        id
        creator
        image
        title
        description
        timeRequired
        purpose
        prefecture
        city
        disclosureRange
        isTemp
        rideLevel
        bicycleType
        scheduleList {
          title
          startTime
          endTime
          place
          address
          url
          phone
          memo
          spotId
        }
        scheduleImages
        routeDataKey
        date
        deadline
        maxParticipants
        minParticipants
        belongings
        price
        paymentMethod
        notes
        applyMethods
        createdAt
        updatedAt
        isCycling
        isTour
        members {
          nextToken
        }
        originalPlanId
        originalPlan {
          id
          creator
          image
          title
          description
          timeRequired
          purpose
          prefecture
          city
          disclosureRange
          isTemp
          rideLevel
          bicycleType
          scheduleImages
          routeDataKey
          date
          deadline
          maxParticipants
          minParticipants
          belongings
          price
          paymentMethod
          notes
          applyMethods
          createdAt
          updatedAt
          isCycling
          isTour
          originalPlanId
        }
        duplicatedPlans {
          nextToken
        }
      }
      user {
        id
        nickName
        icon
        PlansJoined {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePlanMembers = /* GraphQL */ `
  mutation DeletePlanMembers(
    $input: DeletePlanMembersInput!
    $condition: ModelPlanMembersConditionInput
  ) {
    deletePlanMembers(input: $input, condition: $condition) {
      id
      ridePlanID
      userID
      ridePlan {
        id
        creator
        image
        title
        description
        timeRequired
        purpose
        prefecture
        city
        disclosureRange
        isTemp
        rideLevel
        bicycleType
        scheduleList {
          title
          startTime
          endTime
          place
          address
          url
          phone
          memo
          spotId
        }
        scheduleImages
        routeDataKey
        date
        deadline
        maxParticipants
        minParticipants
        belongings
        price
        paymentMethod
        notes
        applyMethods
        createdAt
        updatedAt
        isCycling
        isTour
        members {
          nextToken
        }
        originalPlanId
        originalPlan {
          id
          creator
          image
          title
          description
          timeRequired
          purpose
          prefecture
          city
          disclosureRange
          isTemp
          rideLevel
          bicycleType
          scheduleImages
          routeDataKey
          date
          deadline
          maxParticipants
          minParticipants
          belongings
          price
          paymentMethod
          notes
          applyMethods
          createdAt
          updatedAt
          isCycling
          isTour
          originalPlanId
        }
        duplicatedPlans {
          nextToken
        }
      }
      user {
        id
        nickName
        icon
        PlansJoined {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
