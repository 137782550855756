import { useState } from "react";
import { initScheduleInfo } from "../constants/initState";

export const useScheduleInput = () => {
  const [scheduleInput, setScheduleInput] = useState(initScheduleInfo);

  const handleScheduleURLChange = (e, index, prevList) => {
    const { name, value } = e.target;
    const list = prevList;
    list[index] = value;
    setScheduleInput({ ...scheduleInput, [name]: list });
  };

  const resetScheduleInput = () => {
    setScheduleInput(initScheduleInfo); //スケジュールstateを初期化
  };

  return {
    scheduleInput,
    setScheduleInput,
    handleScheduleURLChange,
    resetScheduleInput,
  };
};
