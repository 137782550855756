import React, { memo, useEffect, useCallback } from "react";
import { InputScheduleDetail } from "./InputScheduleDetail";
import { ScheduleType } from "../../constants/global";
import { useScheduleInput } from "../../hooks/useScheduleInput.js";

export const AddScheduleModal = memo(
  ({
    planInfo,
    setPlanInfo,
    showScheduleModal,
    setShowAddScheduleModal,
    addNewScheduleMarker,
    setTempMarker,
    selectedSpot,
    setSelectedSpot,
    setShowSpotList,
    clickedLocation,
    setShowListMode,
    attachedSpot,
    setAttachedSpot,
    setTempSaveStatus,
  }) => {
    const { scheduleInput, setScheduleInput, handleScheduleURLChange, resetScheduleInput } = useScheduleInput();

    useEffect(() => {
      if (!showScheduleModal) return;
      // スケジュールに付与するスポット情報をセット
      const inputSpot = selectedSpot ? selectedSpot : attachedSpot;
      setAttachedSpot(inputSpot);

      if (inputSpot || clickedLocation) {
        const spotId = inputSpot && inputSpot.id ? inputSpot.id : null;
        const { latLng, address } = inputSpot ? inputSpot : clickedLocation;

        setScheduleInput({
          ...scheduleInput,
          //位置情報のセット
          latLng: latLng,
          address: address,
          spotId: spotId,
          place: null, //旧バージョンのデータを削除
        });
      } else {
        setScheduleInput({
          ...scheduleInput,
          //位置情報のセット
          latLng: { lat: 0, lng: 0 },
          address: null,
          spotId: null,
          place: null, //旧バージョンのデータを削除
        });
      }
    }, [showScheduleModal, selectedSpot, clickedLocation]);

    const addSchedule = useCallback(
      (schedule) => {
        const scheduleList = planInfo.scheduleList;
        scheduleList.push(schedule);
        addNewScheduleMarker(scheduleList);
        setPlanInfo({ ...planInfo, scheduleList: scheduleList });
      },
      [planInfo]
    );

    useEffect(() => {
      if (showScheduleModal) {
        document.body.style.overflowY = "hidden";
      }
    }, [showScheduleModal]);

    const onClickAddButton = (newImageObj) => {
      let newImage = null;
      //追加がある場合
      if (newImageObj.file && newImageObj.url) {
        newImage = newImageObj;
      }
      //画像の追加がない場合
      else if (newImageObj.file === null && newImageObj.url === null) {
        newImage = { file: null, url: null, id: newImageObj.id };
      }
      //画像が削除された場合
      else if (newImageObj.file === "" && newImageObj.url === "") {
        newImage = { file: "", url: "", id: newImageObj.id };
      } else {
        newImage = { file: null, url: null };
      }
      const urls = scheduleInput.url.filter((u) => !(u.trim() === ""));
      addSchedule({
        ...scheduleInput,
        url: urls,
        image: newImage,
      });

      //下書き保存ボタン表示
      setTempSaveStatus(false);

      //値の初期化
      setSelectedSpot(null);
      setShowSpotList(false);
      setShowListMode(true);
      resetScheduleInput();
      setTempMarker(null);
      setShowAddScheduleModal(false);
      setAttachedSpot(null);
      document.body.style.overflowY = "scroll";
    };
    const onClickClose = () => {
      resetScheduleInput();
      setAttachedSpot(null);
      setShowAddScheduleModal(false);
    };

    return (
      <div>
        {showScheduleModal ? (
          <InputScheduleDetail
            scheduleInput={scheduleInput}
            selectedSpot={selectedSpot}
            setScheduleInput={setScheduleInput}
            handleScheduleURLChange={handleScheduleURLChange}
            onClickButton={onClickAddButton}
            scheduleType={ScheduleType.ADDITION}
            onClickClose={onClickClose}
            attachedSpot={attachedSpot}
          />
        ) : null}
      </div>
    );
  }
);
