import React from "react";
// import { Link, Outlet } from "react-router-dom";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "../assets/css/userPolicy.module.css";

export const UserPolicy = () => {
  return (
    <div id="container" className>
      <h1 id="pageTitle" className={styles.pageTitle}>
        利用規約
      </h1>
      <div className={styles.userPolicyBox}>
        <section>
          <h2>第1章 総則</h2>
          <p>
            pobicle利用規約（以下「本規約」といいます。）は、株式会社アスタワン（以下「当社」といいます。）が提供する「pobicle」（自転車ユーザー向け情報提供サービス）（以下「本サービス」総称します。）について、当社と本サービスを利用されるお客様（以下「ユーザー」といいます。）との間で、本サービスの利用条件等を定めたものです。
          </p>
          <div className={styles.articleBox}>
            <h3>第1条 本規約への同意、未成年による利用</h3>
            <p>
              <p>
                1.ユーザーは、本規約の全ての条項に同意することを条件として本サービスを利用するものとします。なお、本サービスを利用した場合、ユーザーは、本規約の内容を理解し、これに同意の上、本サービスを利用したとみなし、当社とユーザーとの間で本サービスの利用に関する契約（以下「本契約」といいます。）が成立したものとします。
              </p>
            </p>
            <p>
              <p>
                2.ユーザーは、未成年者である場合、その親権者その他の法定代理人（以下「親権者等」といいます。）の同意を得ることを条件として、本サービスを利用するものとします。本規約への同意時に未成年者であったユーザーが、成年に達した後も本サービスの利用を継続した場合、当該ユーザーは、未成年者であった間になした本サービスの利用に係る一切の行為を追認したものとみなされます。
              </p>
            </p>
          </div>
          <div className={styles.articleBox}>
            <h3>第2条 その他の規定類</h3>
            <p>
              <p>
                1.当社が本サービス上及び本サービスのサポートサイト上に随時掲載する各種ガイドライン、ルールその他規定類は、本規約の一部を構成するものとします。
              </p>
            </p>
            <p>
              <p>
                2.当該規定類と本規約との間に矛盾抵触がある場合、当該規定類に別途の定めなき限り、本規約が優先して適用されます。
              </p>
            </p>
            <p>
              <p>3.ユーザーは、本規約に同意することにより、当該規定類に同意したものとみなされます。</p>
            </p>
          </div>
          <div className={styles.articleBox}>
            <h3>第3条 本規約の変更</h3>
            <p>
              <p>1.当社は、本規約を改定する場合には、原則としてユーザーの同意を得るものとします。</p>
            </p>
            <p>
              <p>
                2.前項にかかわらず、当社は、以下の各号のいずれかに該当する場合に、本規約をユーザーの同意を得ずに変更することがあります。
                <div className={styles.subArticleBox}>
                  <p>
                    <p>1.本規約の変更がユーザーの一般の利益に適合するとき</p>
                  </p>
                  <p>
                    <p>
                      2.本規約の変更が、本契約の目的に反するものではなく、かつ変更の必要性、変更後の内容の相当性及び合理性があるとき
                    </p>
                  </p>
                </div>
              </p>
            </p>
            <p>
              <p>
                3.前項に基づく変更後の本規約は、当社が変更後の本規約を、その変更日と共に予め本サービス上に表示し、当該変更日が到来した時点から効力が生じるものとします。
              </p>
            </p>
            <p>
              <p>
                4.変更後の本規約の効力が生じた後に、ユーザーが本サービスを利用した場合、ユーザーは、変更後の本規約の内容を理解し、これに同意の上、本サービスを利用したとみなすものとします。
              </p>
            </p>
          </div>
          <div className={styles.articleBox}>
            <h3>第4条 当社からの通知</h3>
            <p>
              <p>
                1.当社は、本サービス又は当社ホームページでの表示その他一般的商慣行に照らし妥当な方法により、ユーザーに対し随時必要な情報を通知します。
              </p>
            </p>
            <p>
              <p>
                2.前項の通知は、それが本サービス又は当社ホームページでの表示により行われる場合はその掲載時点、その他の場合は当社が当該通知の内容を利用者に送信した時点より効力を発するものとします。
              </p>
            </p>
          </div>
          <div className={styles.articleBox}>
            <h3>第5条 個人情報の取扱い</h3>
            <p>
              <p>
                1.当社は、個人情報を当社の「プライバシーポリシー」（以下「当社プライバシーポリシー」といいます。）に基づき、適切に取り扱うものとします。
              </p>
            </p>
            <p>
              <p>
                2.ユーザーは本サービスの利用にあたり、当社が本規約及び当社プライバシーポリシーに基づきユーザーの個人情報を取得、利用その他取り扱いすることに同意するものとします。
              </p>
            </p>
            <p>
              <p>
                3.本サービスに関しては当社プライバシーポリシーのほか、下記に掲げるアプリケーションプライバシーポリシーが適用されます。アプリケーションプライバシーポリシーと、本規約又は当社プライバシーポリシーとの間に矛盾・抵触が存在する場合、その限りにおいて、アプリケーションプライバシーポリシーが優先して適用されるものとします。
              </p>
            </p>
          </div>
          <div className={styles.appPolicyBox}>
            <h3>アプリケーションプライバシーポリシー</h3>
            <p>
              株式会社アスタワン（以下、「当社」）は、法令遵守のもとに、個人情報保護の重要性を認識し、下記の方針に従い、個人情報の保護に努めます。
              当社は、ソフトウェア開発業及び情報処理サービス業とし、お客様に更なる信頼性と安心感をご提供できるように努めて参ります。このため、当社は業務に従事する一人一人が個人情報保護の重要性を認識し、個人情報に関する法令を遵守し、お預かりする個人情報の適切な取り扱いを実現致します。
              本サービスを提供するにあたり、以下のように本サービスのアプリケーションプライバシーポリシー（以下「本アプリプライバシーポリシー」といいます。）を定めます。本アプリプライバシーポリシーは、本サービスにおいてアプリケーションから送信される利用者情報をどのような方針で利用・管理するかについて定めたものです。
            </p>
            <h4>【本サービスのアプリケーションから送信される利用者情報】</h4>
            <div className={styles.appListBox}>
              <ul>
                <p>
                  <p>位置情報(お客様が他のユーザーと位置情報を共有する場合及び、お客様自身がお客様の位置情報を把握する為に位置情報を取得します。)</p>
                </p>
                <p>
                  <p>携帯端末の通信情報（接続状況、ネットワークタイプ等）</p>
                </p>
                <p>
                  <p>携帯端末情報（通信キャリア情報、OS・バージョン情報、機種情報等）</p>
                </p>
              </ul>
            </div>
            <h4>【個人情報の利用目的】</h4>
            <p>
              当社は、個人情報の取得に際しては、その利用目的をあらかじめ開示し、関連法令に基づき認められる場合を除き、当該個人情報の本人の同意なく、その目的の達成に必要な範囲を超えて利用しません。
              当該目的には、以下の各目的が含まれます。
            </p>
            <div className={styles.appListBox}>
              <ul>
                <p>
                  <p>ユーザーに当社サービスの会員向けサービスを提供するため。</p>
                </p>
                <p>
                  <p>
                    ユーザーが当社サービスで入力した個人情報について、当社サービス内で同じ情報の入力を回避するため。
                  </p>
                </p>
                <p>
                  <p>
                    ユーザーからの問い合わせ対応等、当社がユーザーにとって有益と判断した情報を含む各種の連絡をするため。
                  </p>
                </p>
                <p>
                  <p>
                    あらかじめ外部に提供することを明示した上で、ユーザーの利用申込情報を、ユーザーが申し込んだサービスの提供企業に通知するため。
                  </p>
                </p>
                <p>
                  <p>
                    ユーザーアンケートや当社サービスの利用状況・利用環境に関して、個人を識別できない形式に加工した統計データを作成し、利用するため。
                  </p>
                </p>
                <p>
                  <p>当社サービス、当社のウェブサイトの改善や新サービスの開発等に役立てるため。</p>
                </p>
              </ul>
            </div>
            <h4>【データ送信先】</h4>
            <p>
              位置情報、通信キャリア情報、OS・バージョン情報、機種情報等お客様が利用されている携帯端末の情報および接続状況、ネットワークタイプ等携帯端末の通信情報を、アプリケーションや本サービス各種機能の改善のために、本サービスのサーバーへ自動的に送信します。また、上記の利用者情報については、個人を特定できない形に加工した上で、統計資料作成等のため、業務提携企業・団体等に提供されることがあります。
            </p>
            <h4>【本サービスの機能とは直接関係のないプログラムについて】</h4>
            <p>
              本サービスのアプリケーションには、第三者が作成する本サービスの機能とは直接関係のない、利用者情報を収集するようなプログラムは含まれておりません。
            </p>
            <h4>【本アプリプライバシーポリシーの変更】</h4>
            <p>
              本サービスにおけるアプリケーションのバージョンアップに伴い、送信される利用者情報、主な利用目的、データ送信先が変更となる場合があります。アプリケーションのバージョンアップ時には本アプリプライバシーポリシーを再度ご確認下さい。
              個人情報に関する開示・訂正・利用停止・削除等に関するお問い合わせは、当社までご連絡ください。
            </p>
            <p>個人情報管理責任者 代表取締役　久米　幹夫</p>
            <p>株式会社アスタワン</p>
            <p>〒435-0042 静岡県浜松市東区篠ケ瀬町１３６８−２</p>
          </div>
        </section>
        <section>
          <h2>第2章 費用</h2>
          <div className={styles.articleBox}>
            <h3>第1条 本サービスの利用費用</h3>
            <p>
              本サービスの利用にあたって必要な機器・ソフトウェアの購入・導入・維持の費用、データ利用料金等の通信料金、電気料金その他一切の費用は、ユーザーが自己の責任において支払うものとします。当社は、本サービスの利用環境に関し推奨環境を提示することはありますが、当該環境の整備及び費用を一切負担しません。
            </p>
          </div>
        </section>
        <section>
          <h2>第3章 ユーザーの義務</h2>
          <div className={styles.articleBox}>
            <h3>第10条 端末機器及びアカウントの管理</h3>
            <p>
              <p>
                1.ユーザーは、自身の端末機器及びアカウント（ユーザーが登録ユーザーとして自らの情報を登録することにより得られる、本サービスにおける当該ユーザー固有の地位を指し、当社が発行する当該ユーザー固有の識別符号を含みます。以下同じ。）を第三者に使用させ、又は第三者と共有してはならないものとします。
              </p>
            </p>
            <p>
              <p>
                2.ユーザーは、本サービスの利用にあたり、自身の端末機器及びアカウントを自らの責任において管理するものとし、自身の端末機器及びアカウントを利用してなされた行為について、一切の責任を負うものとします。
              </p>
            </p>
            <p>
              <p>
                3.当社は、ユーザーの利用する端末機器及びアカウントを利用してなされた行為について、当該端末機器及びアカウントを現実に使用して本サービスを利用する者が誰であるかを問わず、全て、当該アカウントを取得したユーザー自身による行為とみなします。
              </p>
            </p>
            <p>
              <p>
                4.当社は、ユーザーの使用する端末機器の故障、紛失、第三者からのマルウェア、スパイウェア、ウィルス、ハッキング・クラッキング等による攻撃、端末機器及びアカウントの管理不備及び使用上の過誤、又は第三者による端末機器及びアカウントの使用等により、ユーザーが損害を被った場合であっても、一切の責任を負いません。
              </p>
            </p>
            <p>
              <p>
                5.ユーザーは、自身の端末機器及びアカウントが第三者により不正に利用されるおそれがある場合、速やかに、当該不正利用を回避するため必要な措置をとるものとします。
              </p>
            </p>
            <p>
              <p>
                6.当社は、ユーザーが自身の端末機器及びアカウントを紛失若しくは失念したことにより、ユーザーに生じうる不利益（ユーザーが本サービスにおいて投稿・登録又は保存した情報その他本サービスにおいて実現される一切の状態が失われることを含みます。）について、一切の責任を負わないものとします。
              </p>
            </p>
            <p>
              <p>
                7.ユーザーは、本アプリのバージョン及び本アプリを登録している自身の端末機器のOSを最新の状態に保つよう努めるものとします。またユーザーは、自身の端末機器やOSのバージョンによっては正常に動作しない場合があることをあらかじめ承諾するものとします。なお、本アプリを利用可能なOSのバージョンについては当社ホームページで確認できます。
              </p>
            </p>
          </div>
          <div className={styles.articleBox}>
            <h3>第11条 禁止行為</h3>
            <p>
              当社は、本サービスの利用に際して、以下の内容に該当するユーザーの行為（これらに該当するおそれのある行為を含み、第三者をして行為させる場合を含みます。）を禁止します。
            </p>
            <p>
              <p>
                1.自身の会員情報（ユーザーが使用するIDその他の識別符号を含みます。）を第三者に提供し、本サービスを使用させる行為
              </p>
            </p>
            <p>
              <p>
                2.虚偽の情報を申告してユーザー登録をする行為、その他本サービスにおいて当社又は第三者に対して事実に反する情報を提供し又は流布する行為
              </p>
            </p>
            <p>
              <p>3.不正アクセス行為</p>
            </p>
            <p>
              <p>
                4.当社、他のユーザー、本サービスに関連する人物又は団体等であるように振舞い、その他本サービスとの関連性について第三者に何らかの誤解を生ぜしめる行為
              </p>
            </p>
            <p>
              <p>
                5.本サービスが使用するサーバー、システム、ネットワークその他本サービスに関連する施設設備に過大な負荷を与えうる方法で本サービスを利用する行為
              </p>
            </p>
            <p>
              <p>
                6.コンピューターウイルスの頒布その他本サービスの実施及びユーザーによる端末機器に不具合を生ぜしめる行為
              </p>
            </p>
            <p>
              <p>7.他のユーザーによる本サービスの利用に支障を与えうる行為</p>
            </p>
            <p>
              <p>8.本サービスの不具合に関する情報を、当社以外の第三者に対して故意に提供又は開示する行為</p>
            </p>
            <p>
              <p>
                9.本サービスに用いられるプログラムに対する改変、毀損、リバースエンジニアリング、逆コンパイル、逆アセンブルその他これに準ずる行為
              </p>
            </p>
            <p>
              <p>
                10.本サービス又は本サービス上で提供される情報を複製、転記、抽出、加工、翻案、譲渡、賃与、売買、送信する行為（但し、第１７条１項にて認められる二次利用は除く。）
              </p>
            </p>
            <p>
              <p>
                11.本サービス又は本サービス上で提供される情報を用いた営利行為又はその準備行為（但し、当社が認定したものを除く。）
              </p>
            </p>
            <p>
              <p>
                12.当社又は第三者の著作権、特許権、商標権その他の知的財産権（以下「知的財産権」と総称します。）を侵害する行為（但し、第１７条１項にて認められる二次利用は除く。）
              </p>
            </p>
            <p>
              <p>
                13.当社、他のユーザーその他の第三者に対する名誉棄損、ストーキング、いじめ、嫌がらせ、詐欺、脅迫、なりすまし又は威圧行為
              </p>
            </p>
            <p>
              <p>14.本サービスを通じて計画したイベントを、実施当日参加者に通知せずに中止する行為</p>
            </p>
            <p>
              <p>15.虚偽を述べ、又は悪意のあるイベントを計画して参加者を募る行為</p>
            </p>
            <p>
              <p>16.歩行中、車両運転中その他本サービスの利用が適切でない状況又は態様において本サービスを使用する行為</p>
            </p>
            <p>
              <p>17.自然の動植物を大切にしない行為、虐待その他残虐な又は社会的に容認されない内容を含む情報の発信</p>
            </p>
            <p>
              <p>18.希少動植物に関する情報を投稿する際の、その所在・発見場所が明らかに特定される内容を含む情報の発信</p>
            </p>
            <p>
              <p>
                19.わいせつな内容（性的な事項を連想させると当社が判断した表現をいい、芸術性の有無を問いません。）を含む情報の発信
              </p>
            </p>
            <p>
              <p>20.面識のない第三者との交際又はわいせつな行為等を目的とする情報の発信</p>
            </p>
            <p>
              <p>
                21.青少年有害情報（青少年が安全に安心してインターネットを利用できる環境の整備等に関する法律第2条第3項が定義するものをいいます。）を投稿する行為、児童を性交等の相手方となるように誘引する行為
              </p>
            </p>
            <p>
              <p>22.自殺又は自傷行為を誘引、勧誘又は助長する行為</p>
            </p>
            <p>
              <p>
                23.覚せい剤、麻薬等の禁制品その他の違法薬物、及び危険ドラッグその他の不適切な薬物の売買及びこれらの不適切な利用を助長する情報の発信
              </p>
            </p>
            <p>
              <p>
                24.宗教的行為、宗教団体、政治的活動又は政治団体の宣伝又は広告に関する情報の発信、及びこれらへの勧誘行為
              </p>
            </p>
            <p>
              <p>25.無限連鎖講又はネットワークビジネスに関する情報の発信</p>
            </p>
            <p>
              <p>26.ジャンクメール又はスパムメールに相当する文面を含む情報の発信</p>
            </p>
            <p>
              <p>27.未成年者に対する飲酒、喫煙又は賭博の推奨又は勧誘その他未成年者に悪影響を及ぼす情報の発信</p>
            </p>
            <p>
              <p>28.児童ポルノ、残虐な表現その他第三者に不快感を与える情報の発信</p>
            </p>
            <p>
              <p>
                29.人種、民族、性別、信条、社会的身分、居住地、身体的特徴、病歴、教育、財産等の差別につながる情報の発信
              </p>
            </p>
            <p>
              <p>30.不正な位置情報を登録する行為</p>
            </p>
            <p>
              <p>31.他人の肖像権、プライバシーその他の人格権を侵害する行為</p>
            </p>
            <p>
              <p>32.他のユーザーの個人情報又はプライバシーに関する情報を不当に収集し利用する行為</p>
            </p>
            <p>
              <p>33.詐欺その他の犯罪に結びつく行為</p>
            </p>
            <p>
              <p>34.本サービスの提供に支障を与え、又は当社及び本サービスの信用を損なう行為</p>
            </p>
            <p>
              <p>35.故意、過失を問わず、法令に違反する行為</p>
            </p>
            <p>
              <p>36.公序良俗に反する行為又は他人に不利益を与える行為</p>
            </p>
            <p>
              <p>
                37.自治体、消防、警察、あるいはこれらを構成員とする団体及びその他これに準ずる公私の団体からの注意喚起情報や協力要請に従わない行為（ただし安全上の必要から緊急止むを得ない理由があるなど社会一般のモラルに照らして許容される場合を除く）
              </p>
            </p>
            <p>
              <p>
                38.自治体、消防、警察、あるいはこれらを構成員とする団体及びその他これに準ずる公私の団体からの注意喚起情報や協力要請に従わない行為（ただし安全上の必要から緊急止むを得ない理由があるなど社会一般のモラルに照らして許容される場合を除く）
              </p>
            </p>
          </div>
          <div className={styles.articleBox}>
            <h3>第12条 利用の制限又は停止</h3>
            <p>
              当社は、ユーザーの行為が以下のいずれかに該当すると当社が判断した場合、当該ユーザーによる本サービスの利用開始を拒否し、当該ユーザーが本サービスにおいて登録した情報（以下「ユーザーコンテンツ」といいます。）の全部若しくは一部を削除し、当該ユーザーによる本サービスの利用を停止若しくは制限し、又は当該ユーザーのアカウントを削除することがあります。
            </p>
            <p>
              <p>1.前条の禁止行為を含み、本規約に違反した場合、又はそのおそれがあると当社が判断した場合</p>
            </p>
            <p>
              <p>
                2.ユーザーの行為（本サービス外における作為又は不作為を含む。）によって、本サービスの提供に支障が生じ、若しくは妨害された場合、又はそのおそれがあると当社が判断した場合
              </p>
            </p>
            <p>
              <p>
                3.当社所定の方法によらない方法、その他不正な方法により本サービスを利用し、又はそのおそれがあると当社が判断した場合
              </p>
            </p>
            <p>
              <p>4.過去に当社から本条に基づく措置を受けたユーザーである場合</p>
            </p>
            <p>
              <p>5.その他、当社が不適切と判断した場合</p>
            </p>
          </div>
          <div className={styles.articleBox}>
            <h3>第13条 退会</h3>
            <p>
              <p>
                1.ユーザーは、本サービスからの退会を希望する場合、ユーザー自身により、当社所定の方法により退会の手続をとるものとします。当社は、ユーザーの使用する端末機器又はアカウントから当該手続がとられた場合、当該端末機器又はアカウントを使用するユーザー本人の真意による退会手続が行われたとみなします。
              </p>
            </p>
            <p>
              <p>
                2.ユーザーが本サービスから退会した場合、当該ユーザーが本サービス利用に関して有する一切の権利、過去の記録情報その他本サービスの利用に関連してユーザーがこれまでに蓄積したデータ、履歴その他一切の情報を確定的に失います。ユーザーが前項に定める所定の退会手続を履践していた場合、真に退会する意思がなかったとのユーザーの抗弁は認められません。
              </p>
            </p>
            <p>
              <p>
                3.ユーザーによる本サービスからの退会後も、当社とユーザーとの間の本規約に基づく契約関係は終了せず、引き続き効力を有するものとします。
              </p>
            </p>
            <p>
              <p>
                4.当社は、ユーザーが本サービスを退会した後も、当該ユーザーが当社に提供し又は当社が本サービス利用の過程で収集した情報を、引き続き保有・利用できるものとします。
              </p>
            </p>
            <p>
              <p>
                5.ユーザーが死亡した場合、当該ユーザーは本サービスの利用を終了したものとします。本サービスの利用権は一身専属的なものとし、相続の対象とはなりません。
              </p>
            </p>
            <p>
              <p>
                6.当社は、当社の裁量により、あらかじめ通知することなく、最終のアクセスから6か月以上が経過しているアカウントを削除することができるものとします。
              </p>
            </p>
          </div>
        </section>
        <section>
          <h2>第4章 サポート及び情報の取扱い</h2>
          <div className={styles.articleBox}>
            <h3>第14条 サポートサービス</h3>
            <p>
              <p>
                1.当社は、ユーザーによる本サービスの利用を補助するためのサービス（以下「サポート」といいます。）を提供しますが、その内容は当社が提供可能な範囲で決定するものとし、特定のサポートを実施する義務を負わないものとします。
              </p>
            </p>
            <p>
              <p>
                2.サポートは、電話による問い合わせ窓口若しくは本サービスを通じて、又は当社所定のウェブ掲示板又は専用フォームで受け付けるものとし、当社からの回答が必要な場合は原則として電話又は電子メールにより行います。
              </p>
            </p>
            <p>
              <p>
                3.前項の回答対応には、その内容により、数日から数週間を要する場合があり、また、内容によっては対応できない場合があります。ユーザーの問い合わせが、本サービスの不具合又は要望への対応内容・時期に関する場合、又は本サービスの利用に関しないハードウェア・OS・ソフトウェア等の使用方法に関する場合、当社は原則として回答を行いません。
              </p>
            </p>
            <p>
              <p>
                4.ユーザーは、当社による回答の内容をサポートを受ける目的以外で利用し、又は公開してはならないものとします。
              </p>
            </p>
          </div>
          <div className={styles.articleBox}>
            <h3>第15条 知的財産権</h3>
            <p>
              <p>
                1.本サービスに関する一切の知的財産権（本サービス上で提供される情報に関して生じている知的財産権を含みます。）は、当社又はこれについて正当な権限を有する第三者に帰属します。
              </p>
            </p>
            <p>
              <p>
                2.ユーザーは、当社が本サービスにおいて提供した一切の情報について、当社の同意なく、複製し、改変し、公衆送信し、その他当社の知的財産権を侵害することとなる利用をしてはならないものとします。但し、第１７条１項にて認められる二次利用の場合のほか私的使用の目的による場合は、この限りではありません。
              </p>
            </p>
            <p>
              <p>
                3.当社がユーザーに対して本サービスの利用を許すことは、当社のユーザーに対する知的財産権その他何らかの権利に基づく許諾とは解釈されないものとします。
              </p>
            </p>
          </div>
          <div className={styles.articleBox}>
            <h3>第16条 投稿データの取扱い</h3>
            <p>
              <p>
                1.本サービスにおいては、ユーザーが他の特定又は不特定のユーザーに対して文章又は画像等を送信することができる機能として、いわゆるメッセージ機能や掲示板機能（以下「投稿機能」と総称します。）が設けられることがあります。
              </p>
            </p>
            <p>
              <p>
                2.ユーザーは、投稿機能を利用して、他の特定又は不特定のユーザーに対して送信し又は保存した文章又は画像その他の情報（以下「投稿データ」と総称します。）の内容について、一切の責任を負うものとします。当社は、投稿データの内容について、一切の責任を負いません。
              </p>
            </p>
            <p>
              <p>
                3.ユーザーは、投稿データが第三者の知的財産権、プライバシー権、肖像権その他の人格権を含む一切の権利又は利益を侵害しないこと、及び法令に違反しないことを保証するものとします。
              </p>
            </p>
            <p>
              <p>
                4.ユーザーは、自身の投稿データに起因して第三者から苦情の申立、訴訟の提起その他の紛争が生じた場合、ユーザー自身の責任と費用によりこれを解決するものとし、当社に何等の迷惑をかけず、かつ損害を与えないものとします。当社は、かかる紛争に起因してユーザーに生じる損害につき、一切の責任を負いません。
              </p>
            </p>
            <p>
              <p>
                5.ユーザーは当社に対して、投稿データについて、これらを保存・蓄積した上、本サービスの構築、本サービスにおける情報提供、本サービスの円滑な提供及び改善、並びに本サービスその他関連するサービスの広告宣伝を目的とした国内外における複製権、公衆送信権、翻訳権、翻案権、その他著作権法上の権利を期間の定めなく無償で許諾するものとし、投稿を行った時点で当該許諾を行ったものとみなすものとします。また、当社が投稿データの使用を許諾した第三者が当該投稿データを使用した場合であっても、ユーザーは当該第三者に対してロイヤリティ等の求償権を有しないものとします。
              </p>
            </p>
            <p>
              <p>6.前項の場合において、ユーザーは、投稿データに関して、著作者人格権を行使しないものとします。</p>
            </p>
            <p>
              <p>
                7.当社は、当社が必要と判断した場合、投稿データを閲覧し、その全部又は一部を削除できるものとします。但し、当社は投稿データを閲覧し、又は必要に応じて削除する義務を負うものではなく、当社が投稿データを削除しなかった場合であっても、それによって生じた結果について当社は何等の責任を負わないものとします。
              </p>
            </p>
          </div>
          <div className={styles.articleBox}>
            <h3>第17条 二次利用</h3>
            <p>
              <p>
                1.ユーザーは、自身の投稿データ及び本サービスによって当社から提供された情報（以下、総称して「投稿データ等」といいます。）を、以下の媒体（以下「本件外部媒体」といいます。）で使用することができるものとします。但し、有償か無償かにかかわらず、投稿データ等を本件外部媒体を除く第三者に提供し、又は使用許諾する場合には、当社の事前の承諾を得るものとします。
              </p>
              <div className={styles.subArticleBox}>
                <p>
                  <p>1.ユーザー自身が運営するWebサイト</p>
                </p>
                <p>
                  <p>
                    2.第三者が運営するSNSサービス（インターネットを介したコミュニケーションツールの総称をいい、Youtube等の動画投稿サイトを含む。）。なお、ユーザー自身のアカウント内での使用に限ります。
                  </p>
                </p>
              </div>
            </p>
            <p>
              <p>
                2.前項にかかわらず、本件外部媒体の内容が公序良俗に反し、法令に違反し、その他投稿データ等を掲載するのに相応しくないと当社が判断する場合には、当社は、ユーザーに対して投稿データ等の削除を求めることができるものとし、ユーザーはこれに応じるものとします。
              </p>
            </p>
            <p>
              <p>
                3.本条に基づく投稿データ等の本件外部媒体における利用をする場合には、本件外部媒体に広告が表示されている場合であっても、第11条(11)で定める営利行為とはみなさないものとします。
              </p>
            </p>
            <p>
              <p>
                4.ユーザーは、当社から提供された情報を本件外部媒体に表示する場合には、これを改変することはできず、且つ、当社が権利者である旨を表示するものとします。
              </p>
            </p>
            <p>
              <p>
                5.ユーザーが投稿データ等を本件外部媒体で使用したことによって当社が損害を被った場合には、当社に帰責事由がある場合を除き、ユーザーは当社の被った損害および解決に要した費用（合理的な弁護士費用を含みます。）を賠償するものとします。
              </p>
            </p>
          </div>
          <div className={styles.articleBox}>
            <h3>第18条 情報の取得及び利用</h3>
            <p>
              <p>
                1.当社は、ユーザーが本サービスを利用するに際して、当社自ら、又は第三者と共同して以下に掲げる情報（以下「ユーザーデータ」といいます。）を取得することがあります。
              </p>
              <div className={styles.subArticleBox}>
                <p>
                  <p>1.端末情報（使用デバイス、OS、端末言語設定など）</p>
                </p>
                <p>
                  <p>
                    2.ユーザーによる本サービスの利用状況（本サービスのバージョン、ユーザーの位置情報、その他自転車利用に関連する情報、本サービスの利用履歴など）
                  </p>
                </p>
                <p>
                  <p>3.ユーザーが利用したキャンペーンに関する情報</p>
                </p>
              </div>
            </p>
            <p>
              <p>
                2.当社は、投稿データ及びユーザーデータ（以下「本データ」と総称します）が個人情報保護法上の個人情報に該当する場合、当社プライバシーポリシーに基づき、当該個人情報を適切に取り扱うものとします。
              </p>
            </p>
            <p>
              <p>3.当社は、本データを以下に掲げる目的で利用します。</p>
              <div className={styles.subArticleBox}>
                <p>
                  <p>1.本人確認</p>
                </p>
                <p>
                  <p>2.本サービスに関するお知らせ等の配信</p>
                </p>
                <p>
                  <p>3.ユーザーの傾向等の分析並びにそれに合わせたサービス及び広告その他の情報提供</p>
                </p>
                <p>
                  <p>
                    4.本サービスの利用状況等に係る統計情報の作成、並びに当該統計情報の本サービス又は当社ウェブサイト等における公表及び第三者への提供
                  </p>
                </p>
                <p>
                  <p>5.法令及び本規約への違反の疑いがある場合の調査</p>
                </p>
              </div>
            </p>
            <p>
              <p>
                4.当社は、以下に掲げる場合、当社関連会社に対して行う場合、及び本規約に別途定める場合を除き、本データをユーザーの同意なく第三者に開示しません。
              </p>
              <div className={styles.subArticleBox}>
                <p>
                  <p>1.法令に基づく場合</p>
                </p>
                <p>
                  <p>2.人の生命、身体又は財産の保護のために必要がある場合</p>
                </p>
                <p>
                  <p>
                    3.公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難である場合
                  </p>
                </p>
                <p>
                  <p>
                    4.国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがある場合
                  </p>
                </p>
              </div>
            </p>
            <p>
              <p>
                5.当社は、ユーザーが本サービスを退会し、当社がユーザーのアカウントを削除し、又は当社が本サービスの提供を終了したときは、当社の判断により、本データを全て消去することがあります。
              </p>
            </p>
            <p>
              <p>6.本条の定めは、ユーザーが本サービスを退会した後も有効に存続するものとします。</p>
            </p>
          </div>
        </section>
        <section>
          <h2>第5章 変更及び終了</h2>
          <div className={styles.articleBox}>
            <h3>第19条 本サービスの中断又は停止</h3>
            <p>
              <p>
                1.当社は、以下いずれかに該当する場合、ユーザーに対して事前に通知することなく、本サービスの全部又は一部について、その提供を中断し若しくは停止することができます。
              </p>
              <div className={styles.subArticleBox}>
                <p>
                  <p>1.本サービスにかかわるコンピューター・システムの点検又は保守作業を定期的又は緊急に行う場合</p>
                </p>
                <p>
                  <p>2.サーバーのダウン等、当社が設置し本サービスに利用する電気通信設備の障害が生じた場合</p>
                </p>
                <p>
                  <p>
                    3.ネットワーク回線の混雑、インターネットプロバイダーにおける障害その他本サービスに利用する外部インフラの障害が生じた場合
                  </p>
                </p>
                <p>
                  <p>
                    4.不可抗力（火災、停電その他の不慮の事故、地震、津波、台風、雷、大雨、洪水その他の自然災害を含み、以下同様とします。）により本サービスの提供ができない状況になった場合
                  </p>
                </p>
                <p>
                  <p>5.その他、当社が必要と判断した場合</p>
                </p>
              </div>
            </p>
            <p>
              <p>
                2.当社は、前項に基づく本サービスの中断又は停止によりユーザーに損害が生じた場合であっても、当社の責めに帰すべき事由がある場合を除き、一切の責任を負いません。
              </p>
            </p>
          </div>
        </section>
        <section>
          <h2>第6章 雑則</h2>
          <div className={styles.articleBox}>
            <h3>第20条 免責事項</h3>
            <p>
              <p>
                1.当社は、当社の責めに帰すべき事由ある場合を除き、ユーザーが本サービスの利用に関連して被ったいかなる直接的・間接的損害についても一切責任を負いません。ユーザーは自己の責任と判断において、本サービスを利用するものとします。
              </p>
            </p>
            <p>
              <p>
                2.ユーザーは、本サービス及び当社ウェブサイトにおいて提供されるサービス及び情報は、日々更新及び変更されうる性質のものであることを了解するものとします。当社は、当該サービス及び情報の存在及び内容の永続性を保証しません。
              </p>
            </p>
            <p>
              <p>
                3.当社は、本サービスの機能が常に完全に動作することを保障しません。本サービスの機能は、端末機器の状態、使用環境（携帯電話用電波やGPS信号の受信環境を含みます。）その他の要因によっても制限を受けることがあります。
              </p>
            </p>
            <p>
              <p>
                4.当社は、本サービスによってアクセスが可能な情報（位置情報、サイクリング経路、施設その他自転車に関する情報を含みますが、これに限りません。）、ソフトウェアなどについて、その完全性、確実性、妥当性、正確性、有用性、最新性、適法性又はユーザーの利用目的及び環境との適合性を保証しません。
              </p>
            </p>
            <p>
              <p>5.当社は、本サービスがいかなる第三者の権利も侵害していないことを保証しません。</p>
            </p>
            <p>
              <p>
                6.当社は、本アプリからのリンクにより遷移可能な一切の第三者ウェブサイトについて、その内容等の適法性、整合性、安全性、正確性、公序良俗に反しないことその他一切の保証をしません。当社は、ユーザーによる当該ウェブサイトの利用、及び当該利用を通して提供された商品又はサービス等に起因する損害や損失について、一切の責任を負いません。
              </p>
            </p>
            <p>
              <p>
                7.当社は、ユーザーが本サービスを利用することにより第三者との間で生じた紛争等に関して、当社の責めに帰すべき事由ある場合を除き、一切の責任を負いません。ユーザーは当該紛争を自己の責任と費用によりこれを解決するものとし、当社に何等の迷惑をかけず、かつ損害を与えないものとします。ユーザーが第三者に対して紛争を提起する場合も同様とします。
              </p>
            </p>
            <p>
              <p>
                8.当社は、本サービスに関連する情報の削除又は消失、本サービスの利用によるデータの消失、機器の故障又は損傷その他本サービスに関連してユーザーが被った損害につき、一切の責任を負いません。当社は、第16条で定めるユーザーの投稿データが滅失した場合であっても一切の責任を負わず、ユーザーは必要に応じて自らバックアップ等を行うものとします。
              </p>
            </p>
            <p>
              <p>
                9.当社は、本サービス利用に際し、他の機器との組み合わせに起因する故障若しくは損傷、その他ユーザーが被った損害につき、当社の責めに帰すべき事由ある場合を除き、一切の責任を負いません。
              </p>
            </p>
            <p>
              <p>
                10.当社は、本サービスに対する不正アクセス、コンピューターウイルスの侵入その他第三者の行為に起因してユーザーに生じる損害について、当社の責めに帰すべき事由ある場合を除き、一切の責任を負いません。
              </p>
            </p>
            <p>
              <p>11.当社は、不可抗力によりユーザーに生じた損害について、一切の責任を負いません。</p>
            </p>
          </div>
          <div className={styles.articleBox}>
            <h3>第21条 損害賠償</h3>
            <p>
              <p>
                1.ユーザーは、本規約に定める条項に違反し、又は自己の責めに帰すべき事由により当社に損害を与えた場合、当社に対し、当社が被った損害（合理的な弁護士費用を含みます。）を賠償するものとします。
              </p>
            </p>
            <p>
              <p>
                2.当社を免責する規定にも拘わらず、消費者契約法その他の適用法令に基づき当社がユーザーに対して損害賠償責任を負う場合であっても、当社の責任は、損害の原因となった事由が生じた時点から遡って1年の間に当該ユーザーから現実に受領した利用料金の総額を上限とします。当社に故意又は重過失がある場合はこの限りではありませんが、その場合も、当社の損害賠償責任は当該ユーザーが直接被った損害に限られるものとし、結果的損害、付随的損害、逸失利益等の間接損害は、当社の予見または予見可能性の有無にかかわらず一切含まないものとします。
              </p>
            </p>
          </div>
          <div className={styles.articleBox}>
            <h3>第22条 権利義務の移転禁止</h3>
            <p>
              ユーザーは、本規約に関して有するユーザーとしての地位、又は権利若しくは義務の全部又は一部について、第三者に対して譲渡し、名義を変更し、質入れし、担保に供し、許諾しその他一切の処分をしてはならないものとします。なお、当社は、本サービスにかかる事業を第三者に譲渡した際には、当該事業譲渡に伴い本契約上の地位、権利及び義務並びにユーザーの登録事項その他の情報を当該事業譲渡の譲受人に譲渡することができるものとし、ユーザーは、かかる譲渡につき本条においてあらかじめ同意したものとします。なお、本条に定める事業譲渡には、通常の事業譲渡のみならず、合併、会社分割その他事業が移転するあらゆる場合を含みます。
            </p>
          </div>
          <div className={styles.articleBox}>
            <h3>第23条 本規約の有効性</h3>
            <p>
              <p>
                1.本規約のうち、一部の規定が法令等に基づいて無効と判断された場合であっても、その他の規定の有効性には影響を及ぼさないものとします。
              </p>
            </p>
            <p>
              <p>
                2.本規約のうち、一部の規定があるユーザーとの関係で無効と判断され、又は取り消された場合であっても、その他のユーザーとの間の有効性には影響を及ぼさないものとします。
              </p>
            </p>
          </div>
          <div className={styles.articleBox}>
            <h3>第24条 準拠法</h3>
            <p>本規約に関する準拠法は、日本法とします。</p>
          </div>
          <div className={styles.articleBox}>
            <h3>第25条 合意管轄</h3>
            <p>
              当社とユーザーとの間で訴訟の必要が生じた場合、訴額に応じて日本国静岡簡易裁判所又は静岡地方裁判所を第一審の専属的合意管轄裁判所とします。
            </p>
            <div className={styles.contactBox}>
              <p>株式会社アスタワン</p>
              <p>〒435-0042 静岡県浜松市東区篠ケ瀬町１３６８−２</p>
              <p>制定：2022年6月1日</p>
              <p>改定：</p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
