import crossCloseButtonStyles from "../../../assets/css/global/crossCloseButton.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const CrossCloseButtonRight = ({ onClick }) => {
  const label = "Close";
  return (
    <div>
      <label htmlFor={label}>
        <FontAwesomeIcon icon={["fa", "times"]} className={crossCloseButtonStyles.faTimesRight} />
      </label>
      <input id={label} type={"checkbox"} className={crossCloseButtonStyles.hideCheckbox} onClick={onClick}></input>
    </div>
  );
};
export const CrossCloseButtonLeft = ({ onClick }) => {
  const label = "Close";
  return (
    <div>
      <label htmlFor={label}>
        <FontAwesomeIcon icon={["fa", "times"]} className={crossCloseButtonStyles.faTimesLeft} />
      </label>
      <input id={label} type={"checkbox"} className={crossCloseButtonStyles.hideCheckbox} onClick={onClick}></input>
    </div>
  );
};
