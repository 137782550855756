import { DISCLOSURE_RANGE } from "./planConstants";

export const initScheduleInfo = {
  title: null,
  startTime: null,
  endTime: null,
  place: null,//住所or場所名
  address: null,
  latLng: {
    lat: 0,
    lng: 0,
  },
  url: [],
  phone: null,
  memo: null,
  spotId: null,
};

export const initPlanInfo = {
  /*BasicInfo*/
  creator: null,
  image: null,
  title: null,
  description: null,
  timeRequired: null,
  purpose: [],
  prefecture: null,
  city: null,
  disclosureRange: DISCLOSURE_RANGE.PUBLIC,
  isTemp: false,
  /*scheduleInfo*/
  scheduleList: [],
  scheduleImages: [],
  routeDataKey: null,
  /*rideInfo*/
  rideLevel: null,
  bicycleType: [],
  /*tourInfo*/
  date: null,
  deadline: null,
  maxParticipants: null,
  minParticipants: null,
  belongings: null,
  paymentMethod: null,
  price: null,
  notes: null,
  applyMethods: [],
  isCycling: false,
  isTour: false,
  /*original plan */
  originalPlanId: null,
};

export const initLatLng = {
  lat: 35.6809591,
  lng: 139.7673068,
};

export const initSpotInputInfo = {
  creatorId: null,
  name: null,
  address: null,
  googlePlaceId: null,
  latLng: {
    lat: 0,
    lng: 0,
  },
  category: "",
  memo: "",
  disclosureRange: "public",
  imageKeys: [],
  url: [],
};
