
/**
 * クリップボードにtextをコピー
 * @param {*} plainText 
 * @param {*} htmlText 
 * @returns 
 */

const copyFunc = async (plainText, htmlText = null) => {
  //for Safari Link:https://wolfgangrittner.dev/how-to-use-clipboard-api-in-firefox/
  if (typeof ClipboardItem && navigator.clipboard.write) {
    const blobPlain = Promise.resolve(new Blob([plainText], { type: "text/plain" }));
    const blobHtml = htmlText ? Promise.resolve(new Blob([htmlText], { type: "text/html" })) : null;
    const blobObj = blobHtml ? { "text/plain": blobPlain, "text/html": blobHtml } : { "text/plain": blobPlain };
    //クリップボードにコピー
    const item = new window.ClipboardItem(blobObj);
    const result = navigator.clipboard
      .write([item])
      .then(() => {
        return true;
      })
      .catch((error) => {
        throw error;
      });
    return result ? true : false;
  } else {
    const result = navigator.clipboard
      .writeText(plainText)
      .then((result) => {
        return true;
      })
      .catch((error) => {
        throw error;
      });
    return result ? true : false;
  }
};

/**
 * クリップボードへのコピー処理を呼び出し
 * @param {*} plainText 
 * @param {*} htmlText 
 * @param {*} setDoneCopied 
 */
export const tryCopyFunc = async (plainText, htmlText = null, setDoneCopied) => {
  let result = null;
  try {
    result = await copyFunc(plainText, htmlText);
    if (result) setDoneCopied(true);
  } catch (error) {
    console.error(error);
    setDoneCopied(false);
  }
  if (result) {
    setTimeout(function () {
      setDoneCopied(false);
    }, 1 * 1000);
  }
};
