import React, { memo, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "../../assets/css/inputScheduleDetail.module.css";
import { ScheduleType } from "../../constants/global";
import { ShowErrorMessage } from "../commons/messages/Messages";
import imageCompression from "browser-image-compression";
import { ImageCompressionOptions } from "../../constants/global";
import UUID from "uuidjs";
export const InputScheduleDetail = memo(
  ({
    scheduleInput,
    setScheduleInput,
    handleScheduleURLChange,
    onClickButton,
    scheduleType,
    onClickClose,
    attachedSpot,
  }) => {
    const [title, setTitle] = useState("");
    const [buttonText, setButtonText] = useState("");
    const [errorMessageObj, setErrorMessageObj] = useState(null);
    const scheduleTitle = document.getElementById("scheduleTitle");
    const [currentImageObj, setCurrentImageObj] = useState(null);
    const [displayImgUrl, setDisplayImgUrl] = useState(null);
    //スケジュールのURLの数を3つに固定
    useEffect(() => {
      const urlCountFormat = () => {
        if (!scheduleInput) return;
        const objectiveCount = 3;
        if (!scheduleInput.hasOwnProperty("url") || !Array.isArray(scheduleInput.url)) {
          scheduleInput.url = new Array(objectiveCount).fill("");
        }
        const realCount = scheduleInput.url.length;
        if (realCount !== objectiveCount) {
          for (let i = 0; i < objectiveCount - realCount; i++) {
            scheduleInput.url.push("");
          }
        }
        setScheduleInput(scheduleInput);
      };

      urlCountFormat();
    }, [scheduleInput]);

    /** スケジュールテキスト入力のハンドラ */
    const handleTextChange = (e) => {
      const { name, value } = e.target;
      setScheduleInput({ ...scheduleInput, [name]: value });
    };

    //スケジュール編集時の初期画像表示用URLのセット
    useEffect(() => {
      if (displayImgUrl) return;
      if (!scheduleInput?.image) return;
      const { url } = scheduleInput.image;
      const scheduleImage = document.getElementById("scheduleImage");
      const closeBtn = document.getElementById("closeButton");
      if (url) {
        setDisplayImgUrl(url);
        scheduleImage.style.display = "flex";
        closeBtn.style.visibility = "visible";
        return;
      } else return;
    }, [scheduleInput]);

    useEffect(() => {
      const checkScheduleType = () => {
        if (scheduleType === ScheduleType.ADDITION) {
          setTitle("スケジュールの作成");
          setButtonText("追加");
        } else if (scheduleType === ScheduleType.EDITING) {
          setTitle("スケジュールの変更");
          setButtonText("変更");
        } else if (scheduleType === ScheduleType.CONFIRMATION) {
          setTitle("スケジュールの確認");
          setButtonText("閉じる");
        }
      };
      checkScheduleType();
    }, [scheduleType]);

    const isValid = () => {
      let errorObj;
      if (!scheduleInput.title || scheduleInput.title.trim() === "") {
        errorObj = { ...errorObj, scheduleTitle: "タイトルを入力してください" };
      }
      //if you add validation write here

      if (errorObj) {
        setErrorMessageObj(errorObj);
        return false;
      } else {
        return true;
      }
    };

    const scrollToTop = () => {
      if (scheduleTitle) {
        scheduleTitle.scrollIntoView({ behavior: "smooth" });
      }
    };

    const handleFileSelect = async (event) => {
      const scheduleImage = document.getElementById("scheduleImage");
      const closeBtn = document.getElementById("closeButton");
      let imageFile = event.target.files[0];

      try {
        const compressedFile = await imageCompression(imageFile, ImageCompressionOptions);
        imageFile = compressedFile;
        const imageUrl = URL.createObjectURL(imageFile); //表示
        setDisplayImgUrl(imageUrl);
        scheduleImage.style.display = "flex";
        //×ボタン表示
        closeBtn.style.visibility = "visible";

        //選択中の画像ファイルをセット
        const uuid = UUID.generate();
        setCurrentImageObj({ file: compressedFile, url: imageUrl, id: uuid });
      } catch (error) {
        console.error(error);
      }
    };

    function imgClose() {
      // 保存前の画像のURLを無効化
      if (currentImageObj && currentImageObj.url) URL.revokeObjectURL(currentImageObj.url);
      const scheduleImage = document.getElementById("scheduleImage");
      const closeBtn = document.getElementById("closeButton");
      closeBtn.style.visibility = "hidden";
      scheduleImage.style.display = "none";
      setDisplayImgUrl(null);
      setCurrentImageObj({ file: "", url: "" });
    }

    function handleOnClickSubmit() {
      document
        .getElementById("inputScheduleBox")
        .scrollIntoView({ behavior: "auto", block: "start", inline: "nearest" });
      //画像の追加がない場合
      if (currentImageObj === null) {
        const uuid = UUID.generate();
        onClickButton({ file: null, url: null, id: uuid });
      } else if (currentImageObj.file === "" && currentImageObj.url === "") {
        //画像×で削除された場合
        const uuid = UUID.generate();
        onClickButton({ file: "", url: "", id: uuid });
      } else {
        //画像の追加あり
        onClickButton(currentImageObj);
      }
    }
    return (
      <div id="container" className={styles.content}>
        <div className={styles.inner}>
          <div className={styles.inputScheduleHeader}>
            <button type="button" onClick={onClickClose} className={styles.backButton}>
              <FontAwesomeIcon icon={["fa", "angle-left"]} className="faAngle" />
              <span>戻る</span>
            </button>
            <h1 className={styles.pageTitle}>{title}</h1>
            <div className={styles.addButton}>
              <button
                type="button"
                id="defaultButton"
                onClick={() => {
                  if (isValid()) {
                    handleOnClickSubmit();
                  } else {
                    scrollToTop();
                  }
                }}
              >
                {buttonText}
              </button>
            </div>
          </div>
          <div className={styles.addScheduleBox}>
            <h3 className={styles.subtitle}>場所</h3>
            <div className={styles.contentBox}>
              {scheduleInput.spotId && attachedSpot ? (
                <div className={styles.spot}>
                  <div className={styles.spotInfoContents}>
                    <div className={styles.categoryBox}>
                      <div className={styles.categoryName}>{attachedSpot?.category}</div>
                    </div>
                    <h4 className={styles.place}>{attachedSpot?.name}</h4>
                  </div>
                  <button
                    type="button"
                    className={styles.deleteButton}
                    onClick={() => setScheduleInput({ ...scheduleInput, spotId: null })}
                  >
                    <FontAwesomeIcon icon={["fa", "times"]} className={styles.faTimeRight} />
                  </button>
                </div>
              ) : (
                <div className={styles.place}>
                  {scheduleInput.address
                    ? scheduleInput.address
                    : scheduleInput?.place
                    ? scheduleInput.place
                    : "位置情報なし"}
                </div>
              )}
            </div>
            <div className="schedule-box">
              <h3 id="scheduleTitle" className={styles.subtitle}>
                画像
              </h3>
              <div className={styles.contentBox}>
                <div className={styles.scheduleImageBox}>
                  <div id="closeButton" className={styles.closeButtonBox}>
                    <button type="button" className={styles.closeBtn} onClick={imgClose}>
                      <FontAwesomeIcon icon={["fa", "fa-times"]} />
                    </button>
                  </div>
                  <img src={displayImgUrl} alt="scheduleImage" id="scheduleImage" className={styles.image}></img>
                  <label id="addBlueButton" className={styles.uploadImageButton}>
                    画像をアップロード <FontAwesomeIcon icon={["fa", "plus"]} className={styles.faPlus} />
                    <input
                      type="file"
                      accept=".png, .jpeg, .jpg"
                      onClick={(e) => {
                        e.target.value = "";
                      }}
                      onChange={handleFileSelect}
                      id="inputImage"
                    ></input>
                  </label>
                  {/* <p>10MB未満のjpeg, jpg, png画像のみ</p> */}
                </div>
              </div>
              <h3 className={styles.subtitle}>スケジュール</h3>
              <div className="planTitle">
                <h3 className={styles.details}>
                  タイトル&ensp;
                  <FontAwesomeIcon icon={["fas", "fa-asterisk"]} className={styles.asterisk} />
                </h3>
                <div className={styles.inputBox}>
                  <input
                    id="scheduleTitle"
                    type="text"
                    name="title"
                    maxLength="50"
                    placeholder="（例）パン屋さんでパンを買う"
                    value={scheduleInput?.title}
                    onChange={handleTextChange}
                  />
                </div>
              </div>
              <div className={styles.errorMessageBox}>
                {errorMessageObj?.scheduleTitle ? <ShowErrorMessage message={errorMessageObj.scheduleTitle} /> : ""}
              </div>
              <div className={styles.memo}>
                <h3 className={styles.details}>メモ</h3>
                <div className={styles.inputBox}>
                  <textarea
                    id="memo"
                    type="text"
                    name="memo"
                    maxLength="1000"
                    placeholder="メモを入力"
                    value={scheduleInput?.memo}
                    onChange={handleTextChange}
                  />
                </div>
              </div>
              <div className={styles.timeInput}>
                <h3 className={styles.details}>開始時間&ensp;</h3>
                <div className={styles.inputBox}>
                  <input
                    id="startTime"
                    type="time"
                    name="startTime"
                    maxLength="50"
                    value={scheduleInput?.startTime}
                    onChange={handleTextChange}
                  />
                  <button
                    id="closeButton"
                    type="button"
                    className={styles.timeDeleteBtn}
                    onClick={() => setScheduleInput({ ...scheduleInput, startTime: "" })}
                  >
                    <FontAwesomeIcon icon={["fa", "fa-times"]} />
                  </button>
                </div>
              </div>
              <div className={styles.errorMessageBox}>
                {errorMessageObj?.startTime ? <ShowErrorMessage message={errorMessageObj.startTime} /> : ""}
              </div>
              <div className={styles.timeInput}>
                <h3 className={styles.details}>終了時間&ensp;</h3>
                <div className={styles.inputBox}>
                  <input
                    id="endTime"
                    type="time"
                    name="endTime"
                    maxLength="50"
                    value={scheduleInput?.endTime}
                    onChange={handleTextChange}
                  />
                  <button
                    id="closeButton"
                    type="button"
                    className={styles.timeDeleteBtn}
                    onClick={() => setScheduleInput({ ...scheduleInput, endTime: "" })}
                  >
                    <FontAwesomeIcon icon={["fa", "fa-times"]} />
                  </button>
                </div>
              </div>
              <div className={styles.errorMessageBox}>
                {errorMessageObj?.endTime ? <ShowErrorMessage message={errorMessageObj.endTime} /> : ""}
              </div>
            </div>

            <h3 id="scheduleTitle" className={styles.subtitle}>
              その他
            </h3>
            {new Array(3).fill("").map((_, index) => {
              return (
                <div className="url" key={index}>
                  <h3 className={styles.details}>{`URL${index + 1}`}</h3>
                  <div className={styles.inputBox}>
                    <input
                      id={`url${index + 1}`}
                      type="url"
                      name="url"
                      maxLength="1000"
                      placeholder="URLを入力"
                      value={scheduleInput?.url ? scheduleInput?.url[index] : ""}
                      onChange={(e) => handleScheduleURLChange(e, index, scheduleInput.url)}
                    />
                  </div>
                </div>
              );
            })}

            <div className="tel">
              <h3 className={styles.details}>電話番号</h3>
              <div className={styles.inputBox}>
                <input
                  id="tel"
                  type="tel"
                  name="phone"
                  maxLength="15"
                  placeholder="電話番号を入力"
                  value={scheduleInput?.phone}
                  onChange={handleTextChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
