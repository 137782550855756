import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import styles from "../assets/css/planDetail.module.css";
import noImage from "../assets/img/noimage.png";
import { useNavigate, useParams } from "react-router-dom";
import { tryGetRidePlan } from "../helper/dbAccess/planAccess";
import { tryGetSpot } from "../helper/dbAccess/spotAccess";
import { tryCopyFunc } from "../helper/copyClipBoard";
import { usePlanImage } from "../hooks/usePlanImage";
import { ReadOnlyScheduleModal } from "../components/schedule/ReadOnlyScheduleModal";
import { ReadOnlyMap } from "../components/map/MapRender";
import { BackPreviousButton } from "../components/commons/buttons/BackButton";
import { DISCLOSURE_RANGE } from "../constants/planConstants";
import { globalUserContext } from "../globalContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingIcon } from "../components/commons/LoadingIcon";
import { tryGetUser } from "../helper/dbAccess/userAccess";
import { tryDeleteRidePlan } from "../helper/dbAccess/planAccess";
import { tryListPlanMembers } from "../helper/dbAccess/planMemberAccess";
import { tryDeleteFile } from "../helper/s3Access";
import { Storage } from "aws-amplify";
import { Helmet } from "react-helmet-async";
import { SanitizedText, SanitizedTextWithUrl } from "../components/commons/SanitizedText";
import { NotFound } from "./ExceptionMessage";
import { motion } from "framer-motion";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "../assets/css/global/swiper.css";
// import function to register Swiper custom elements
import { register } from "swiper/element/bundle";
// register Swiper custom elements
register();

export const PlanDetail = () => {
  const { userInfo, authState, setAuthMode } = useContext(globalUserContext);
  const { id } = useParams();
  const { fetchImageURL } = usePlanImage();
  const [fetchedPlan, setFetchedPlan] = useState(null);
  const [showSchedule, setShowSchedule] = useState();
  const [currentSchedule, setCurrentSchedule] = useState(null);
  const [scheduleIndex, setScheduleIndex] = useState(null);
  const [publishingSettings, setPublishingSettings] = useState(null);
  const [markers, setMarkers] = useState([]);
  const navigate = useNavigate();
  const [isEditable, setIsEditable] = useState(false);
  const [imageUrlList, setImageUrlList] = useState(null);
  const [imageListForTop, setImageListForTop] = useState(null);
  const [creator, setCreator] = useState("");
  const [isMember, setIsMember] = useState("checking");
  const [isLinkCopied, setIsLinkCopied] = useState(false);
  const [isInfoCopied, setIsInfoCopied] = useState(false);
  const [routeDataUrl, setRouteDataUrl] = useState(null);
  //plan情報の取得
  useEffect(() => {
    if (!id) return;
    const getRidePlan = async () => {
      try {
        const plan = await tryGetRidePlan(id);
        if (plan) {
          // planの情報を取得
          // マーカー情報の取得
          let latLngList = [];
          //spotIdがある場合は、spotIdからlatLngを取得
          for (let i = 0; i < plan.scheduleList.length; i++) {
            if (plan.scheduleList[i].spotId) {
              //spotIdからlatLngを取得
              const spot = await tryGetSpot(plan.scheduleList[i].spotId);
              latLngList.push(spot.latLng);
              plan.scheduleList[i] = { ...plan.scheduleList[i], spot: spot };
            } else {
              latLngList.push(plan.scheduleList[i].latLng ? plan.scheduleList[i].latLng : { lat: 0, lng: 0 });
            }
          }
          setFetchedPlan(plan);
          setMarkers(latLngList);
        } else {
          navigate("*"); // 404
          return;
        }
      } catch (e) {
        console.error(e);
        // 404
      }
    };

    getRidePlan();
  }, [id]);

  //プランに参加済みかどうかチェック
  useEffect(() => {
    const checkIfMembers = async () => {
      if (fetchedPlan.isTemp) return;
      if (!userInfo || !userInfo.attributes) {
        setIsMember("checking");
        return;
      }
      //planの作成者の場合は、参加者とする
      if (userInfo.attributes.sub === fetchedPlan.creator) {
        setIsMember(true);
        return;
      }
      try {
        const planMembers = await tryListPlanMembers(id, userInfo.attributes.sub);

        if (planMembers && planMembers.length > 0) {
          setIsMember(true);
        } else if (planMembers.find((member) => member.userID === fetchedPlan.creator)) {
          setIsMember(true);
        } else {
          setIsMember(false);
        }
      } catch (e) {
        console.error(e);
        // 404
      }
    };
    if (!id || !fetchedPlan) return;

    checkIfMembers();
  }, [id, fetchedPlan, userInfo]);

  //プラン作成ユーザー情報の取得
  useEffect(() => {
    const prepareCreatorInfo = async () => {
      try {
        const res = await tryGetUser(fetchedPlan.creator);
        if (res && res.nickName) {
          setCreator(res.nickName);
        } else {
          // setCreator(fetchedPlan.creator);
          setCreator(fetchedPlan.creator);
        }
      } catch (e) {
        console.error(e);
      }
    };
    if (fetchedPlan) {
      prepareCreatorInfo();
    } else return;
  }, [fetchedPlan]);

  // すべて画像keyの取得 & URL化して表示
  useEffect(() => {
    if (!fetchedPlan) return;
    //imageKeys[0]はトップ画像,それ以降はスケジュール画像
    //画像がない場合は""が入っている。

    //スケジュール画像がなく、スポット画像がある場合は、スポット画像をスケジュール画像として表示
    const setScheduleImages = () => {
      let scheduleImgKeys = [];

      for (let i = 0; i < fetchedPlan.scheduleList.length; i++) {
        if (fetchedPlan.scheduleImages[i]) {
          scheduleImgKeys.push(fetchedPlan.scheduleImages[i]);
        } else if (fetchedPlan.scheduleList[i].spot?.imageKeys) {
          scheduleImgKeys.push(fetchedPlan.scheduleList[i]?.spot?.imageKeys[0]);
        } else {
          scheduleImgKeys.push("");
        }
      }
      return scheduleImgKeys;
    };
    const scheduleImgKeys = setScheduleImages();
    const imageKeys = [fetchedPlan.image, ...scheduleImgKeys];

    (async () => {
      //すべての画像を取得
      const imageURLs = await fetchImageURL(imageKeys);
      let urlList = [];
      imageURLs.forEach((url, i) => {
        if (!url && i === 0) {
          urlList[0] = noImage;
        } else {
          urlList[i] = url;
        }
      });
      setImageUrlList(urlList);
      setImageListForTop(
        urlList.map((url) => {
          if (!url) return noImage;
          else return url;
        })
      );
    })();
  }, [fetchedPlan]);

  //routeDataファイルをfetchedPlanから取得し、url化
  useEffect(() => {
    if (!fetchedPlan || !fetchedPlan.routeDataKey) return;
    const getRouteDataUrl = async () => {
      const routeDataKey = fetchedPlan.routeDataKey;
      try {
        const routeDataUrl = await Storage.get(routeDataKey);
        if (routeDataUrl) {
          setRouteDataUrl(routeDataUrl);
        }
      } catch (e) {
        console.error(e);
      }
    };
    getRouteDataUrl();
  }, [fetchedPlan]);

  // fetchedPlan&userInfo,authStateの値によって初期表示の切替
  useEffect(() => {
    if (!fetchedPlan || !userInfo || !userInfo.attributes) return;
    //下書き中プランにアクセスするのが、creator本人かどうか判断
    if (fetchedPlan.isTemp === true) {
      if (userInfo?.attributes.sub !== fetchedPlan.creator) {
        navigate("*");
        if (userInfo === undefined || authState === "unauthenticated") setAuthMode("signIn"); //未ログインの場合はログインモーダルを表示
        return;
      }
    }
    //編集ボタンの有無を管理
    userInfo?.attributes.sub === fetchedPlan.creator ? setIsEditable(true) : setIsEditable(false);
  }, [fetchedPlan, userInfo, authState]);

  const onCopyLink = () => {
    const plainText = window.location.href;
    tryCopyFunc(plainText, null, setIsLinkCopied);
  };

  //プラン情報をクリップボードにコピー
  const onCopyInfo = async () => {
    if (!fetchedPlan) return;
    const { title, description, scheduleList } = fetchedPlan;
    let schedulePlainText = "";
    let scheduleHtmlText = "";
    //スケジュール情報をテキストに整形
    if (scheduleList.length > 0) {
      scheduleList.forEach((schedule, i) => {
        schedulePlainText =
          schedulePlainText + `${i + 1}, ${schedule.startTime} ~ ${schedule.endTime}, ${schedule.title}\n`;
        scheduleHtmlText =
          scheduleHtmlText +
          `${i + 1}, ${schedule.startTime ? schedule.startTime : ""} ${
            schedule.endTime ? `~ ${schedule.endTime}` : ""
          } ${schedule.title}<br/>`;
      });
    }
    //plainTextは、htmlタグを含まないテキストでタイトルなどを太字にしない
    const plainText = `${title}\n${description ? description : ""}\n\nスケジュール\n${
      schedulePlainText ? schedulePlainText : ""
    }`;
    //htmlTextは、htmlタグを含むテキストでタイトルなどを太字にする
    const htmlText = `<strong>${title}</strong><br/>${
      description ? description : ""
    }<br/<br/><strong>スケジュール</strong><br/>${scheduleHtmlText ? scheduleHtmlText : ""}`;
    tryCopyFunc(plainText, htmlText, setIsInfoCopied);
  };

  // //公開設定の文言を管理
  useEffect(() => {
    if (!fetchedPlan) return;
    try {
      if (fetchedPlan.disclosureRange === DISCLOSURE_RANGE.PRIVATE) {
        setPublishingSettings("限定公開（プランURLを知っている人のみ閲覧可能）");
        return;
      } else if (fetchedPlan.disclosureRange === DISCLOSURE_RANGE.PUBLIC) {
        setPublishingSettings("公開（すべての人が閲覧可能）");
      } else {
        return;
      }
    } catch (e) {
      console.error(e);
    }
  }, [fetchedPlan]);

  const toEditPage = () => {
    navigate("/create-plan/" + fetchedPlan.id);
  };

  const deletePlan = async () => {
    if (window.confirm("このプランを削除しますか？削除したプランを復元することは出来ません。")) {
      try {
        const result = await tryDeleteRidePlan(fetchedPlan.id);
        if (result) {
          //S3から画像の消去(複製元のプランではない、かつ複製したプランではないことを確認)
          if (!result.duplicatedPlans.items.length && !result.originalPlanId) {
            result.image && (await tryDeleteFile(result.image));
            if (result.scheduleImages.length > 0) {
              result.scheduleImages.forEach((imageKey) => {
                imageKey && tryDeleteFile(imageKey);
              });
            }
            // S3からルートデータの消去
            result.routeDataKey && (await tryDeleteFile(result.routeDataKey));
          }
          alert("プランを削除しました。");
          navigate("/mypage/plans");
        }
      } catch (e) {
        console.error(e);
        alert("プランの削除が失敗しました。もう一度お試しください。");
        return;
      }
    } else return;
  };

  // const openJoinModal = () => {
  //   let modalBox = document.getElementById(styles.modalBox);
  //   let modalContent = document.getElementById(styles.modalContent);
  //   modalBox.classList.remove(styles.hidden);
  //   modalContent.classList.remove(styles.hidden);
  // };

  // const closeJoinModal = () => {
  //   let modalBox = document.getElementById(styles.modalBox);
  //   let modalContent = document.getElementById(styles.modalContent);
  //   modalBox.classList.add(styles.hidden);
  //   modalContent.classList.add(styles.hidden);
  // };
  // const onClickJoin = async () => {
  //   try {
  //     if (!isMember) await tryCreatePlanMembers(fetchedPlan.id, userInfo.attributes.sub);
  //   } catch (e) {
  //     console.error(e);
  //   }
  //   navigate(`/plan-room/${fetchedPlan.id}`);
  // };
  if (!fetchedPlan) {
    return (
      <div id="container">
        <LoadingIcon />
      </div>
    );
  } else if (
    // プランが下書きで、かつ作成者本人でない場合は404
    //限定公開(PRIVATE)は今後[プラン参加者のみ]や[ID付きURL共有]などの仕様が可能になった際に条件を追加。
    fetchedPlan.isTemp &&
    fetchedPlan.creator !== userInfo?.attributes.sub
  ) {
    return <NotFound />;
  } else {
    return (
      <div>
        {fetchedPlan && (
          <Helmet>
            <meta charSet="utf-8" name="プラン詳細" description="プラン詳細" />
            {/* 非公開(private)または下書きの時に検索結果に表示されないようにする */}
            {fetchedPlan.disclosureRange === DISCLOSURE_RANGE.PRIVATE ||
              (fetchedPlan.isTemp && <meta name="robots" content="noindex, nofollow" />)}
            <title>
              {fetchedPlan?.title}｜{fetchedPlan?.prefecture ? `${fetchedPlan?.prefecture}` : ""}
              {fetchedPlan?.city ? `${fetchedPlan?.city}` : ""}
              {fetchedPlan?.prefecture || fetchedPlan?.city ? "" : "休日"}
              の小さな旅のプランをさがす・つくる・走りに行く | pobicle / ポビクル
            </title>
            <meta
              property="og:title"
              content={`${fetchedPlan?.title} 休日の小さな旅のプランをさがす・つくる・走りに行く - ポビクル`}
            />
            {imageListForTop && imageListForTop[0] ? (
              <meta name="thumbnail" content={imageListForTop[0]} />
            ) : (
              <meta property="thumbnail" content="%PUBLIC_URL%/logowithslogan.png" />
            )}
            {imageListForTop && imageListForTop[0] ? (
              <meta property="og:image" content={imageListForTop[0]} />
            ) : (
              <meta property="og:image" content="%PUBLIC_URL%/logowithslogan.png" />
            )}
            <meta property="og:image:width" content="600" />
            <meta property="og:image:height" content="300" />
          </Helmet>
        )}
        <div className={styles.topHeader}>
          <BackPreviousButton />
          <div className={styles.optionBar}>
            {!isLinkCopied ? (
              <button onClick={() => onCopyLink()} className={styles.copyButton}>
                <FontAwesomeIcon icon={["fas", "fa-link"]} className={styles.faIcon} />
                <div>リンク</div>
              </button>
            ) : (
              <button className={styles.copiedButton} disabled>
                <FontAwesomeIcon icon={["fas", "fa-link"]} className={styles.faIcon} />
                <div>コピー完了</div>
              </button>
            )}
            {isEditable && (
              <button onClick={toEditPage} className={styles.editPenButton}>
                <FontAwesomeIcon icon={["fas", "fa-pen"]} className={styles.faIcon} />
                <div>編集</div>
              </button>
            )}
            {isEditable && (
              <button onClick={deletePlan} className={styles.deleteButton}>
                <FontAwesomeIcon icon={["fas", "fa-trash-alt"]} className={styles.faIcon} /> <div>削除</div>
              </button>
            )}
            {/* {isMember === true && !fetchedPlan.isTemp && (
              <button
                type="button"
                className={styles.joinModalOpener}
                onClick={() => navigate(`/plan-room/${fetchedPlan.id}`)}
              >
                <FontAwesomeIcon
                  icon={["fas", "fa-person-walking-dashed-line-arrow-right"]}
                  className={styles.faIcon}
                />
                <div>プランルーム</div>
              </button>
            )}
            {isMember === false && !fetchedPlan.isTemp && (
              <button type="button" className={styles.joinModalOpener} onClick={openJoinModal}>
                <FontAwesomeIcon icon={["fas", "fa-lock"]} className={styles.faIcon} />
                <div>プランに参加</div>
              </button>
            )} */}
            {/* 未ログイン状態 */}
            {/* {isMember === "checking" && !fetchedPlan.isTemp && (
              <button type="button" className={styles.joinModalOpener} onClick={() => setAuthMode("signIn")}>
                <FontAwesomeIcon icon={["fas", "fa-lock"]} className={styles.faIcon} />
                <div>プランに参加</div>
              </button> */}
            {/* //デモ期間用（ログインなしでもルーム入室可能）
              // <button type="button" className={styles.joinModalOpener} onClick={openJoinModal}>
              //   <FontAwesomeIcon icon={["fas", "fa-lock"]} className={styles.faIcon} />
              //   <div>プランに参加</div>
              // </button>
            )} */}
          </div>
        </div>
        {!imageListForTop && (
          <div className="topImgBoxPC">
            <LoadingIcon />
          </div>
        )}
        {/* PC */}
        {imageListForTop && (
          <div className="topImgBoxPC">
            <swiper-container
              navigation="true"
              pagination="true"
              pagination-type="progressbar"
              rewind="true"
              space-between="0"
              slides-per-view={fetchedPlan.scheduleList && fetchedPlan.scheduleList.length >= 2 ? "2" : "1"}
              class="mySwiper"
            >
              <div className="swiper-button-next"></div>
              <div className="swiper-button-prev"></div>
              {imageListForTop.map((image, index) => {
                // メイン画像
                if (index === 0) {
                  return (
                    <swiper-slide key={index}>
                      <div className="slideBox">
                        <img src={imageListForTop[0] ? imageListForTop[0] : noImage} alt="topImage" id="topImage" />
                      </div>
                    </swiper-slide>
                  );
                } else {
                  return (
                    <swiper-slide key={index} lazy="true">
                      <div className="slideBox">
                        <img
                          src={imageListForTop[index] ? imageListForTop[index] : noImage}
                          alt="topImage"
                          id="topImage"
                          loading="lazy"
                        />
                        <div className="topImgTitle">{`${
                          index + ". " + fetchedPlan.scheduleList[index - 1]?.title
                        }`}</div>
                      </div>
                    </swiper-slide>
                  );
                }
              })}
            </swiper-container>
          </div>
        )}

        {/* モバイル */}
        {imageListForTop && (
          <div className="topImgBoxMobile">
            <swiper-container
              navigation="true"
              pagination="true"
              pagination-type="progressbar"
              rewind="true"
              space-between="0"
              slides-per-view="1"
              class="mySwiper"
            >
              {imageListForTop.map((schedule, index) => {
                if (index === 0) {
                  return (
                    <swiper-slide key={index}>
                      <div className="slideBox">
                        <img src={imageListForTop[0] ? imageListForTop[0] : noImage} alt="topImage" id="topImage" />
                      </div>
                    </swiper-slide>
                  );
                } else {
                  return (
                    <swiper-slide
                      key={index}
                      lazy="true"
                      onClick={() => {
                        setCurrentSchedule(fetchedPlan.scheduleList[index - 1]);
                        setScheduleIndex(index - 1);
                        setShowSchedule(true);
                      }}
                    >
                      <div className="slideBox">
                        <img
                          src={imageListForTop[index] ? imageListForTop[index] : noImage}
                          alt="topImage"
                          id="topImage"
                          loading="lazy"
                        />
                        <div className="topImgTitle">{`${
                          index + ". " + fetchedPlan.scheduleList[index - 1]?.title
                        }`}</div>
                      </div>
                    </swiper-slide>
                  );
                }
              })}
            </swiper-container>
          </div>
        )}

        <section className={styles.planContent}>
          <section className={styles.basicInfoSection}>
            <div className={styles.titleBox}>
              <h1>
                <SanitizedText text={fetchedPlan.title} />
              </h1>
              <div className={styles.copyInfoButtonBox}>
                {!isInfoCopied ? (
                  <button onClick={() => onCopyInfo()} className={styles.copyInfoButton}>
                    <FontAwesomeIcon icon="fa-regular fa-copy" />
                    プラン情報をクリップボードにコピー
                  </button>
                ) : (
                  <div className={styles.copiedInfoButton}>
                    <FontAwesomeIcon icon="fa-regular fa-copy" />
                    コピー完了
                  </div>
                )}
              </div>
            </div>
            {fetchedPlan.originalPlanId &&
              (fetchedPlan.originalPlan?.id ? (
                <span className={styles.originalPlan}>
                  このプランは
                  <Link to={`/find-plan/${fetchedPlan.originalPlan.id}`}>{fetchedPlan.originalPlan.title}</Link>
                  を基に作成されました。
                </span>
              ) : (
                <span className={styles.deletedOriginalPlan}>このプランの基となったプランは既に削除済み。</span>
              ))}
            <div className={styles.tagBox}>
              {fetchedPlan.rideLevel && <div className={styles.tag}>{fetchedPlan.rideLevel}向け</div>}
              {fetchedPlan.purpose &&
                fetchedPlan.purpose.map((purpose, index) => {
                  return (
                    <div className={styles.tag} key={index}>
                      {purpose}
                    </div>
                  );
                })}
              {fetchedPlan.bicycleType &&
                fetchedPlan.bicycleType.map((bicycleType, index) => {
                  return (
                    <div className={styles.tag} key={index}>
                      {bicycleType}
                    </div>
                  );
                })}
            </div>
            <div className={styles.infoBox}>
              <div className={styles.infoLabel}>地域</div>
              {!fetchedPlan.prefecture && !fetchedPlan.city ? (
                <div className={styles.infoContent}>データなし</div>
              ) : (
                <div className={styles.infoContent}>
                  {fetchedPlan.prefecture} {fetchedPlan.city}
                </div>
              )}
            </div>
            <div className={styles.infoBox}>
              <div className={styles.infoLabel}>作成者</div>
              {/* <img src={iconImg} alt="アイコン" className={styles.iconImage} /> */}
              <div className={styles.infoContent}>{creator}</div>
            </div>
            <div className={styles.infoBox}>
              <div className={styles.infoLabel}>作成日</div>
              <div className={styles.infoContent}>{new Date(fetchedPlan.createdAt).toLocaleDateString()}</div>
            </div>
            <div className={styles.infoBox}>
              <div className={styles.infoLabel}>最終更新日</div>
              <div className={styles.infoContent}>{new Date(fetchedPlan.updatedAt).toLocaleDateString()}</div>
            </div>
            <div className={styles.infoBox}>
              <div className={styles.infoLabel}>公開設定</div>
              <div className={styles.infoContent}>{publishingSettings}</div>
            </div>
            <div className={styles.planExplanation}>
              <SanitizedTextWithUrl text={fetchedPlan.description} />
            </div>
          </section>
          {fetchedPlan.scheduleList && fetchedPlan.scheduleList.length >= 1 && (
            <div className={styles.scheduleContent}>
              <div className={styles.infoSection}>スケジュール</div>
              <div className={styles.scheduleList}>
                <div className={styles.timeSchedule}>
                  {fetchedPlan.scheduleList.map((schedule, index) => (
                    <div key={index}>
                      <div className={styles.scheduleRow}>
                        <div>
                          <div className={styles.scheduleIndex}>{`${index + 1}`}.</div>
                        </div>
                        <motion.div
                          whileHover={{ scale: 1.02 }}
                          whileTap={{ scale: 0.95 }}
                          className={styles.scheduleBox}
                          onClick={() => {
                            setCurrentSchedule(schedule);
                            setScheduleIndex(index);
                            setShowSchedule(true);
                          }}
                        >
                          <div className={styles.timeBox}>
                            {schedule.startTime && ( // startTimeがある場合
                              <div className={styles.time}>
                                {schedule.startTime} ~ {schedule.endTime}
                              </div>
                            )}
                          </div>
                          <div className={styles.scheduleTitle}>{schedule.title}</div>
                          <div className={styles.scheduleMemo}>{schedule.memo}</div>
                        </motion.div>
                        {
                          //最初のスケジュールの最初用のタイムラインを表示
                          index === 0 && <div className={styles.firstTimeline}></div>
                        }
                        {index !== 0 && index !== fetchedPlan.scheduleList.length - 1 && (
                          <div className={styles.timeline}></div>
                        )}
                        {
                          //最後の場合は最後用のタイムラインを表示
                          index === fetchedPlan.scheduleList.length - 1 && <div className={styles.lastTimeline}></div>
                        }
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
          {showSchedule && (
            <ReadOnlyScheduleModal
              show={showSchedule}
              setShow={setShowSchedule}
              schedule={currentSchedule}
              scheduleIndex={scheduleIndex}
              setScheduleIndex={setScheduleIndex}
              imgUrl={imageUrlList && scheduleIndex !== null ? imageUrlList[scheduleIndex + 1] : null}
            />
          )}
          <section className={styles.mapSection}>
            <div className={styles.infoSection}>マップ</div>
            <div className={styles.mapBox}>
              <ReadOnlyMap
                routeDataUrl={routeDataUrl}
                markers={markers}
                setScheduleIndex={setScheduleIndex}
                setShowSchedule={setShowSchedule}
                scheduleList={fetchedPlan.scheduleList}
                setCurrentSchedule={setCurrentSchedule}
                areaArray={[
                  fetchedPlan.city ? fetchedPlan.city : "",
                  fetchedPlan.prefecture ? fetchedPlan.prefecture : "",
                ]}
              />
            </div>
          </section>
          {/* ツアー情報 */}
          {(fetchedPlan.date || fetchedPlan.isTour) && (
            <section>
              <h3>ツアー概要</h3>
              <div className={styles.tourInfoBox}>
                {fetchedPlan.date && (
                  <div className={styles.infoBox}>
                    <div className={styles.infoLabel}>開催日</div>
                    <div className={styles.tourInfoContent}>{new Date(fetchedPlan.date).toLocaleDateString()}</div>
                  </div>
                )}
                {fetchedPlan.time && (
                  <div className={styles.infoBox}>
                    <div className={styles.infoLabel}>所要時間</div>
                    <div className={styles.tourInfoContent}>{fetchedPlan.time}</div>
                  </div>
                )}
                {fetchedPlan.deadline && (
                  <div className={styles.infoBox}>
                    <div className={styles.infoLabel}>応募締切</div>
                    <div className={styles.tourInfoContent}>
                      {new Date(fetchedPlan.deadline).toLocaleString().slice(0, -3)}
                    </div>
                  </div>
                )}
                {fetchedPlan.maxParticipants && (
                  <div className={styles.infoBox}>
                    <div className={styles.infoLabel}>定員</div>
                    <div className={styles.tourInfoContent}>{fetchedPlan.maxParticipants}</div>人
                  </div>
                )}
                {fetchedPlan.minParticipants && (
                  <div className={styles.infoBox}>
                    <div className={styles.infoLabel}>最少催行人数</div>
                    <div className={styles.tourInfoContent}>{fetchedPlan.minParticipants}</div>人
                  </div>
                )}
                {fetchedPlan.price && (
                  <div className={styles.infoBox}>
                    <div className={styles.infoLabel}>参加費</div>
                    <div className={styles.tourInfoContent}>￥{fetchedPlan.price}</div>
                  </div>
                )}
                {fetchedPlan.paymentMethod && (
                  <div className={styles.infoBox}>
                    <div className={styles.infoLabel}>お支払い方法</div>
                    <div className={styles.tourInfoContent}>
                      {<SanitizedTextWithUrl text={fetchedPlan.paymentMethod} />}
                    </div>
                  </div>
                )}
                {fetchedPlan.notes && (
                  <div className={styles.infoBox}>
                    <div className={styles.infoLabel}>注意事項</div>
                    <div className={styles.notes}>{<SanitizedTextWithUrl text={fetchedPlan.notes} />}</div>
                  </div>
                )}
                {fetchedPlan.applyMethods && fetchedPlan.applyMethods.length > 0 && (
                  <div className={styles.applyMethodInfoBox}>
                    <div className={styles.infoLabel}>お申し込み手順</div>
                    {fetchedPlan.applyMethods.map((applyMethod, index) => (
                      <div key={index} className={styles.applyMethodBox}>
                        {fetchedPlan.applyMethods.length > 1 && <div>{index + 1}. </div>}
                        {<SanitizedTextWithUrl text={applyMethod} />}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </section>
          )}

          {/* <section>
              <div id={styles.modalBox} className={styles.hidden} onClick={closeJoinModal}></div>
              <div id={styles.modalContent} className={styles.hidden}>
    
                <div>
                  プランのルームに参加するとプラン内で共有されているエリア情報が閲覧可能になり、
                  <br />
                  あなた自身もプランへの情報追加に貢献できます。
                  <br /> <br />
                  プランルーム内では
                  <div className={styles.policyList}>
                    <li>
                      <Link to={"/communtity-policy"}>
                        <a href="">コミュニティポリシー</a>
                      </Link>
                    </li>
                    および
                    <li>
                      <Link to={"/user-policy"}>
                        <a href="">利用規約</a>
                      </Link>
                    </li>
                  </div>
                  を必ず確認の上、ルールを守ってお楽しみください。 <br /> <br />
                  <label htmlFor="checked">
                    <input
                      type="checkBox"
                      value=""
                      id="checked"
                 
                    ></input>
                    確認しました。
                  </label>
                  <button type="button" id="defaultButton" onClick={onClickJoin}>
                    参加する。
                  </button>
                  <br />
                  <br />
                </div>
            
              </div>
            </section> */}
        </section>
      </div>
    );
  }
};
