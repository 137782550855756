export const FormErrorMessage = (props) => {
  const { style, children } = props;

  return (
    <div style={{ color: "#dd3636", textAlign: "start", marginTop: "0.2rem", ...style }} id = "errorMessage">
      {children}
    </div>
  );
};

export const ItIsRequired = ({ errorValue = null }) => {
  if (!errorValue) {
    return <FormErrorMessage>必須項目です。</FormErrorMessage>;
  } else {
    return <FormErrorMessage>{errorValue && `${errorValue} を`}記入してください。</FormErrorMessage>;
  }
};

export const ShowErrorMessage = ({ message }) => {
  return <FormErrorMessage>{message}</FormErrorMessage>;
};
