import { useEffect } from "react";
import styles from "../assets/css/topPage.module.css";

import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { newScrollObserver } from "../helper/animation";

import topImagePC from "../assets/img/top/hamanako_pc.jpg";
import topImageMob from "../assets/img/top/hamanako_mob.jpg";
import { ReactComponent as LogoSimple } from "../assets/img/logo/logo_simple.svg";
import topBackGround from "../assets/img/top/top_background.png";
import people1 from "../assets/img/illust/people1.png";
import people2 from "../assets/img/illust/people2.png";
import people3 from "../assets/img/illust/people3.png";
import people4 from "../assets/img/illust/people4.png";
import communityImage from "../assets/img/illust/community.jpg";
import { ListPlansByDisclosureRange } from "../components/listplans/ListPlans";
import { Helmet } from "react-helmet";

export const TopPage = () => {
  useEffect(() => {
    newScrollObserver();
  }, []);
  return (
    <div id="container">
      <Helmet>
        <title> pobicle / ポビクル | 休日の小さな旅のプランをさがす・つくる・走りに行く</title>
        <meta
          charSet="utf-8"
          name="トップページ"
          description="pobicle（ポビクル）は、小さな旅を楽しむためのWebサイトです。休日に思い立ってふらりと隣の街に出かけて、お散歩の延長みたいに楽しむ気楽な旅。素敵な場所や人と出会う旅に、ポビクルが寄り添います。"
        />
        <meta property="og:url" content="https://www.pobicle.com/" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="pobicle（ポビクル）は、小さなを楽しむためのWebサイトです。休日に思い立ってふらりと隣の街に出かけて、お散歩の延長みたいに楽しむ気楽な旅。素敵な場所や人と出会う旅に、ポビクルが寄り添います。"
        />
        <meta property="og:site_name" content="pobicle" />
        <meta property="og:image" content="%PUBLIC_URL%/logowithslogan.png" />
        <meta property="og:image:width" content="600" />
        <meta property="og:image:height" content="300" />
        <link rel="canonical" href="https://www.pobicle.com/" />
      </Helmet>
      <main className={styles.main}>
        <div className={styles.imageBox}>
          <div className={styles.backImageBoxPC}>
            <img src={topImagePC} alt="自転車" />
          </div>
          <div className={styles.backImageBoxMob}>
            <img src={topImageMob} alt="弁天島の写真" />
          </div>
          <div className={styles.treeBox}>
            <img src={topBackGround} alt="ポビクル" className={styles.treeImg} />
          </div>
          <div id="slideInAll" className={styles.peopleBox}>
            <img id="slideInFast" src={people4} alt="自転車ユーザー" className={styles.peopleImg} />
            <img id="slideInVerySlow" src={people3} alt="自転車ユーザー" className={styles.peopleImg} />
            <img id="slideInSlow" src={people2} alt="自転車ユーザー" className={styles.peopleImg} />
            <img id="slideInMiddle" src={people1} alt="自転車ユーザー" className={styles.peopleImg} />
          </div>
        </div>

        <div className={styles.pobiExplainBox}>
          <div id="scrollFadeIn" className={styles.introBox}>
            <div className={styles.catchCopy}>
              <h1 alt="ポビクル">小さな旅の入り口に</h1>
            </div>
            <div className={styles.catchCopyMob}>
              <h1>小さな旅の入り口に</h1>
              <LogoSimple className={styles.logoMob} alt="ポビクルのロゴ" />
            </div>
            <div className={styles.leadSentence}>
              <p>
                今日も通り過ぎた、窓の外のあの街にいったい何があるのだろう。
                <br />
                そこには会うべき人や、出会いたかった場所があるのかもしれない。
                <br />
                そう思ったあなたはきっと <br />
                <b>「小さな旅の入り口」</b>
                に立っている。
                <br />
                自転車に乗ったり、お散歩したり、あなたの旅に、ポビクルが寄り添います。
              </p>
            </div>
          </div>

          <div id="scrollFadeIn" className={styles.canDoBox}>
            <h2 id="whatYouCan" className={styles.subTitle}>
              ポビクルでできること
            </h2>
            <div className={styles.canDoList}>
              <div className={styles.canDoContent}>
                <h3 className={styles.canDoTitle}>
                  <span className={styles.brCreatePlan}>旅プラン</span>をつくる
                </h3>
                <div className={styles.canDoCaption}>
                  <p>
                    街のお気に入りの景色、旅の途中で寄りたいスポットを追加しましょう。プランを簡単に公開できます。
                    「地域のいいところに出会えないまま通り過ぎてしまう」。そんな地域観光の悩み解決にも。
                  </p>
                </div>
                <Link to={"create-plan"}>
                  <button type="button" id="canDoListButton">
                    プランをつくる
                    <FontAwesomeIcon icon={["fa", "angle-right"]} className="faAngle" />
                  </button>
                </Link>
              </div>
              <div className={styles.canDoContent}>
                <h3 className={styles.canDoTitle}>
                  <span className={styles.brParticipatePlan}>旅プラン</span>をさがす
                </h3>
                <div className={styles.canDoCaption}>
                  <p>
                    地域の魅力を知り尽くした地元の人や地域を訪れた旅人がつくった個性豊かな旅のプランの中からグルメや文化、ご近所散策などのジャンルごとにあなたにぴったりの旅を見つけることができます。
                  </p>
                </div>
                <Link to={"find-plan"}>
                  <button type="button" id="canDoListButton">
                    プランをさがす
                    <FontAwesomeIcon icon={["fa", "angle-right"]} className="faAngle" />
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div id="scrollFadeIn" className={styles.contactBox}>
          <h1 className={styles.subTitle}>あなたの地域に小さな旅の入り口をつくりませんか？</h1>
          <button type="button" id="defaultButton">
            <Link
              to="https://forms.gle/EqeLxsLzvd2mq3BCA"
              target="_blank"
              rel="noopener noreferrer"
              style={{ width: "100%", color: "#ffff" }}
            >
              お問い合わせ
              <FontAwesomeIcon icon={["fa", "angle-right"]} className="faAngleRight" />
            </Link>
          </button>
        </div>
        <div id="scrollFadeIn" className={styles.planExampleBox}>
          <h2 className={styles.subTitle}>公開中のプラン</h2>
          <div className={styles.cardsBox}>
            <ListPlansByDisclosureRange limit={6} isTemp={false} />
          </div>
          <div className={styles.buttonBox}>
            <Link to={"find-plan"}>
              <button type="button" id="defaultButton">
                もっと見る
                <FontAwesomeIcon icon={["fa", "angle-right"]} className="faAngle" />
              </button>
            </Link>
          </div>
        </div>
        <div id="scrollFadeIn" className={styles.contactBox}>
          <h1 className={styles.subTitle}>お問い合わせはこちらから</h1>
          <p style={{ width: "100%", color: "black" }}>
            地域の魅力をもっと紹介したい 行政、観光協会、観光まちづくりに関する方など、お気軽にお問い合わせください。
            <br />
            まずは、お話を聞かせてください。
          </p>
          <button type="button" id="defaultButton">
            <Link
              to="https://forms.gle/EqeLxsLzvd2mq3BCA"
              target="_blank"
              rel="noopener noreferrer"
              style={{ width: "100%", color: "#ffff" }}
            >
              お問い合わせ
              <FontAwesomeIcon icon={["fa", "angle-right"]} className="faAngleRight" />
            </Link>
          </button>
        </div>
        {/* <div className={styles.pobiExplainBox}>
          <div id="scrollFadeIn" className={styles.aboutBox}>
            <h2 className={styles.subTitle}>
              <span className={styles.brAbout}>ポビクルって</span>こんなコミュニティ
            </h2>
            <img src={communityImage} alt="ポビクルってこんなコミュニティ" className={styles.communityImage} />
            <p>
              一人でも楽しいけど、みんなで走ったら、もっと楽しい。ポビクルは住み慣れた街でも、新しくやってきた街でも、旅先でも、旅と自転車を仲間と楽しむコミュニティです。
              <br />
              E-bikeだって、ロードバイクだって、レンタサイクルだっていい。小さな旅に出てみよう。
            </p>
            <div className={styles.buttonBox}>
              <Link to={"communtity-policy"}>
                <button type="button" id="defaultButton">
                  くわしく見る
                  <FontAwesomeIcon icon={["fa", "angle-right"]} className="faAngle" />
                </button>
              </Link>
            </div>
          </div>
        </div> */}
      </main>
    </div>
  );
};
