import styles from "../../../assets/css/bottomSheet.module.css";
import { MiddleSnapHeight } from "../../../constants/global";
import { ListCard } from "../../map/ListCard.js";
import { ScheduleInfoCard } from "../../schedule/ScheduleInfoCard.js";
import { SpotInfoCard } from "../../spot/SpotInfoCard.js";
import React, { useState, useRef, useEffect } from "react";
import Sheet from "react-modal-sheet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function BottomSheet({
  showListMode,
  setShowListMode,
  selectedScheduleIndex,
  attachPlaceMode,
  showSpotList,
  toggleListMode,
  setAttachPlaceMode,
  setShowEditSpotModal,
  setShowAddSpotModal,
  setShowExistingScheduleModal,
  setAttachedSpot,
  attachedSpot,
  clickedLocation,
  setClickedLocation,
  deleteSchedule,
  addPlaceToSchedule,
  setShowAddScheduleModal,
  setShowEditScheduleModal,
  setSelectedScheduleIndex,
  mutateSpotDone,
  setMutateSpotDone,
  spotList,
  setSpotList,
  selectedSpot,
  setIsScheduleCard,
  isScheduleCard,
  showAddScheduleModal,
  planInfo,
  setPlanInfo,
  S3Keys,
  setS3Keys,
  setSelectedSpot,
  setTempMarker,
  setTempSaveStatus,
  setSearchText,
}) {
  const [isOpen, setOpen] = useState(true);
  const [isTop, setIsTop] = useState(false);
  const sheetRef = useRef();
  const snapTo = (i) => sheetRef.current?.snapTo(i);
  const [onSortContent, setOnSortContent] = useState(false);

  const handleOnSortContent = (onSort) => {
    setOnSortContent(onSort);
  };
  //infoカード表示への切り替え時とスポット、スケジュール、マップ選択時にsnapを変更
  useEffect(() => {
    if (!isOpen) {
      setOpen(true);
      snapTo(1);
    }
    if (!showListMode) {
      snapTo(1);
    } else return;
  }, [showListMode, selectedSpot, selectedScheduleIndex, clickedLocation]);

  return (
    <div className={styles.bottomSheetBox}>
      {!isOpen && (
        <div className={styles.openButtonBox} onClick={() => setOpen(true)}>
          <button className={styles.snapButton} type="button" onClick={() => (isTop ? snapTo(1) : snapTo(0))}>
            ー
          </button>
        </div>
      )}

      <Sheet
        id={styles.bottomSheet}
        ref={sheetRef}
        isOpen={isOpen}
        disableDrag={onSortContent}
        onClose={() => {}}
        snapPoints={[0.9, MiddleSnapHeight, 75]}
        initialSnap={1}
        onSnap={(snapIndex) => {
          if (snapIndex === 0) {
            setIsTop(true);
          } else {
            setIsTop(false);
          }
        }}
      >
        <Sheet.Container>
          <Sheet.Content>
            <div className={styles.snapButtonBox}>
              <button className={styles.snapButton} type="button" onClick={() => (isTop ? snapTo(1) : snapTo(0))}>
                ー
              </button>
            </div>
            <div
              className={styles.sheetContent}
              style={{ height: isTop ? "100%" : `${MiddleSnapHeight}px` }} 
            >
              {showListMode ? (
                <div className={styles.listBox}>
                  <div className={styles.listHeaderBar}>
                    <div className={styles.changeListTab}>
                      <button
                        type="button"
                        className={styles.tab}
                        disabled={!showSpotList}
                        onClick={toggleListMode}
                        style={{
                          backgroundColor: !showSpotList ? "#009c74" : null,
                          color: !showSpotList ? "white" : null,
                        }}
                      >
                        スケジュール一覧
                      </button>
                      <button
                        type="button"
                        className={styles.tab}
                        disabled={showSpotList}
                        onClick={toggleListMode}
                        style={{
                          backgroundColor: showSpotList ? "#009c74" : null,
                          color: showSpotList ? "white" : null,
                        }}
                      >
                        スポット一覧
                      </button>
                    </div>
                    {/* スケジュール追加ボタン */}
                    {!showSpotList && (
                      <button
                        type="button"
                        className={
                          isTop || isTop === null ? styles.fixedPlusButtonBottom : styles.fixedPlusButtonMiddle
                        }
                        onClick={async () => {
                          setShowAddScheduleModal(true);
                        }}
                      >
                        <FontAwesomeIcon icon={["fa", "plus"]} className={styles.faPlus} />
                      </button>
                    )}
                  </div>
                  <ListCard
                    setSelectedScheduleIndex={setSelectedScheduleIndex}
                    mutateSpotDone={mutateSpotDone}
                    setMutateSpotDone={setMutateSpotDone}
                    spotList={spotList}
                    setSpotList={setSpotList}
                    showListMode={showListMode}
                    setShowListMode={setShowListMode}
                    showSpotList={showSpotList}
                    setIsScheduleCard={setIsScheduleCard}
                    planInfo={planInfo}
                    setShowAddScheduleModal={setShowAddScheduleModal}
                    setPlanInfo={setPlanInfo}
                    S3Keys={S3Keys}
                    setS3Keys={setS3Keys}
                    setSelectedSpot={setSelectedSpot}
                    setTempSaveStatus={setTempSaveStatus}
                    setSearchText={setSearchText}
                    handleOnSortContent={handleOnSortContent}
                    isMobile={true}
                  />
                </div>
              ) : (
                <div className={styles.infoBox}>
                  {isScheduleCard ? (
                    <ScheduleInfoCard
                      spotList={spotList}
                      setShowListMode={setShowListMode}
                      setShowEditScheduleModal={setShowEditScheduleModal}
                      selectedSchedule={planInfo.scheduleList[selectedScheduleIndex]}
                      setSelectedScheduleIndex={setSelectedScheduleIndex}
                      setIsScheduleCard={setIsScheduleCard}
                      attachPlaceMode={attachPlaceMode}
                      setAttachPlaceMode={setAttachPlaceMode}
                      deleteSchedule={() => deleteSchedule(selectedScheduleIndex)}
                      setTempMarker={setTempMarker}
                      setSearchText={setSearchText}
                      setSelectedSpot={setSelectedSpot}
                      setAttachedSpot={setAttachedSpot}
                      attachedSpot={attachedSpot}
                    />
                  ) : (
                    <SpotInfoCard
                      setShowListMode={setShowListMode}
                      showAddScheduleModal={showAddScheduleModal}
                      setShowAddScheduleModal={setShowAddScheduleModal}
                      setSearchText={setSearchText}
                      selectedSpot={selectedSpot}
                      setSelectedSpot={setSelectedSpot}
                      clickedLocation={clickedLocation}
                      setClickedLocation={setClickedLocation}
                      setTempMarker={setTempMarker}
                      setShowEditSpotModal={setShowEditSpotModal}
                      setShowAddSpotModal={setShowAddSpotModal}
                      setShowExistingScheduleModal={setShowExistingScheduleModal}
                      attachPlaceMode={attachPlaceMode}
                      setIsScheduleCard={setIsScheduleCard}
                      addPlaceToSchedule={addPlaceToSchedule}
                      setAttachedSpot={setAttachedSpot}
                    />
                  )}
                </div>
              )}
            </div>
          </Sheet.Content>
        </Sheet.Container>
      </Sheet>
    </div>
  );
}
