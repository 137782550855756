import { memo, useEffect, useState } from "react";
import styles from "../../assets/css/readOnlyScheduleModal.module.css";
import { CrossCloseButtonRight } from "../commons/buttons/CrossCloseButton";
import { SanitizedTextWithUrl } from "../commons/SanitizedText";
import { motion, AnimatePresence } from "framer-motion";
import { slideUp } from "../../helper/animation";
import { usePlanImage } from "../../hooks/usePlanImage";

export const ReadOnlyScheduleModal = memo(
  ({ show, setShow, schedule, scheduleIndex, setScheduleIndex, scheduleImageFile, imgUrl }) => {
    const [scheduleImageURL, setScheduleImageURL] = useState(null);
    const [attachedSpotImageURLs, setAttachedSpotImageURLs] = useState([]);
    const [attachedSpot, setAttachedSpot] = useState(null);
    const [googleMapLink, setGoogleMapLink] = useState(null);
    const { fetchImageURL } = usePlanImage();
    console.log(attachedSpot);
    useEffect(() => {
      if (show) {
        document.body.style.overflowY = "hidden"; //モーダルの背景のスクロールを無効化
      }
    }, [show]);

    useEffect(() => {
      let imageURL = "";
      if (!scheduleImageFile && !imgUrl) {
        setScheduleImageURL(null);
        return;
      }
      if (imgUrl) {
        setScheduleImageURL(imgUrl);
      } else if (scheduleImageFile) {
        try {
          imageURL = URL.createObjectURL(scheduleImageFile);
          setScheduleImageURL(imageURL);
        } catch (e) {}
      }
    }, [imgUrl, scheduleImageFile]);

    const onClickClose = () => {
      setShow(false);
      setScheduleImageURL(null);
      setScheduleIndex(null);
      setAttachedSpotImageURLs([]);
      setAttachedSpot(null);
      document.body.style.overflowY = "scroll";
    };

    //スケジュールに付与されたスポット情報をセット
    useEffect(() => {
      if (!schedule) return;
      if (!schedule.spotId || !schedule.spot) return;
      // spot画像をすべてフェッチ
      const getSpotImageURL = async (imageKeys) => {
        const imageURLs = await fetchImageURL(imageKeys);
        return imageURLs;
      };
      const spot = schedule.spot;
      setAttachedSpot(spot);
      if (spot) {
        getSpotImageURL(spot.imageKeys).then((imageURLs) => {
          setAttachedSpotImageURLs(imageURLs);
          return;
        });
      }
    }, [schedule]);

    //googleMapリンクの生成
    useEffect(() => {
      if (!schedule) return;
      if (attachedSpot)
        setGoogleMapLink(
          `https://www.google.com/maps/search/?api=1&query=${attachedSpot.address}&query_place_id=${
            attachedSpot.googlePlaceId ? attachedSpot.googlePlaceId : ""
          }`
        );
      else if (schedule.address) {
        setGoogleMapLink(`https://www.google.com/maps/search/?api=1&query=${schedule.address}`);
      } else if (schedule.place) {
        setGoogleMapLink(`https://www.google.com/maps/search/?api=1&query=${schedule.place}`);
      }
    }, [schedule, attachedSpot]);

    return (
      <AnimatePresence
        // Disable any initial animations on children that
        // are present when the component is first rendered
        initial={false}
        // Fires when all exiting nodes have completed animating out
        onExitComplete={() => null}
      >
        <div className={styles.modal}>
          <motion.div
            onClick={onClickClose}
            className={styles.overlay}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          ></motion.div>
          <motion.div className={styles.content} variants={slideUp} initial="hidden" animate="visible" exit="exit">
            <div className={styles.inner}>
              <CrossCloseButtonRight onClick={onClickClose} />
              <div className={styles.scheduleHeader}>
                <h4 className={styles.pageTitle}>スケジュール{scheduleIndex + 1}</h4>
                <h2>{schedule.title}</h2>
              </div>
              {scheduleImageURL && (
                <img id="image" alt="スケジュール画像" src={scheduleImageURL} className={styles.scheduleImage}></img>
              )}
              <div className={styles.scheduleContents}>
                {(attachedSpot?.memo || schedule.memo) && (
                  <div className={styles.infoBox}>
                    <div className={styles.tdMemoBox}>
                      <SanitizedTextWithUrl text={schedule.memo ? schedule.memo : attachedSpot?.memo} />
                    </div>
                  </div>
                )}
                {(schedule.startTime || schedule.endTime) && (
                  <div className={styles.infoBox}>
                    <div className={styles.tdTitle}>時間</div>
                    <div>
                      {schedule.startTime}~{schedule.endTime}
                    </div>
                  </div>
                )}
                {(attachedSpot?.name || schedule.place || schedule.address) && (
                  <div className={styles.infoBox}>
                    <div className={styles.tdTitle}>場所</div>
                    <div>
                      {attachedSpot?.name ? attachedSpot?.name : schedule.address ? schedule.address : schedule?.place}
                    </div>
                  </div>
                )}
                {schedule.place || schedule.address ? (
                  <li>
                    <a href={googleMapLink} target="_blank" rel="noopener noreferrer">
                      GoogleMapで見る
                    </a>
                  </li>
                ) : null}

                {attachedSpot?.category && (
                  <div className={styles.infoBox}>
                    <div className={styles.tdTitle}>カテゴリー</div>
                    <div>{attachedSpot?.category ? attachedSpot?.category : ""}</div>
                  </div>
                )}
                {(attachedSpot?.url || schedule.url) && (
                  <div className={styles.infoBox}>
                    {attachedSpot?.url
                      ? attachedSpot?.url
                      : schedule.url
                          .filter((u) => u !== "")
                          .map((u, idx) => {
                            return (
                              <div key={idx}>
                                <div className={styles.tdTitle}>{`URL${idx + 1}`}</div>
                                <div>
                                  <SanitizedTextWithUrl text={u} />
                                </div>
                              </div>
                            );
                          })}
                  </div>
                )}
                {schedule.phone && (
                  <div className={styles.infoBox}>
                    <div className={styles.tdTitle}>電話</div>
                    <div>{schedule.phone}</div>
                  </div>
                )}
                {attachedSpotImageURLs.length > 0 && (
                  <div className={styles.tdImageBox}>
                    {attachedSpotImageURLs.map((url, idx) => {
                      return <img src={url} alt="spotImage" />;
                    })}
                  </div>
                )}
              </div>
            </div>
          </motion.div>
        </div>
      </AnimatePresence>
    );
  }
);
