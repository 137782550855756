import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import styles from "../../assets/css/infoCard.module.css";
import { tryGetSpot } from "../../helper/dbAccess/spotAccess.js";
import { CrossCloseButtonRight } from "../commons/buttons/CrossCloseButton.js";
import { SpotList } from "../spot/SpotList.js";
import { ImageByKey } from "../image/ImageByKey.js";

export const ScheduleInfoCard = ({
  spotList,
  setShowListMode,
  setShowEditScheduleModal,
  selectedSchedule,
  setSelectedScheduleIndex,
  setIsScheduleCard,
  attachPlaceMode,
  setAttachPlaceMode,
  deleteSchedule,
  setTempMarker,
  setSearchText,
  setSelectedSpot,
  setAttachedSpot,
  attachedSpot,
}) => {
  const [placeData, setPlaceData] = useState(null);

  // スケジュールの場所情報を取得
  useEffect(() => {
    if (!selectedSchedule) return;
    const getAttachedSpot = async (spotId) => {
      try {
        const response = await tryGetSpot(spotId);
        return response;
      } catch (e) {
        console.error("error", e);
      }
    };

    // scheduleのspotを優先的に表示する
    if (selectedSchedule.spotId) {
      getAttachedSpot(selectedSchedule.spotId).then((spot) => {
        // スケジュールにスポットが紐づいていて、かつスポット情報が取得できた場合
        if (spot) {
          setAttachedSpot(spot);
          const placeTitle = spot.name ? spot.name : null;
          const placeId = spot.googlePlaceId ? spot.googlePlaceId : null;
          setPlaceData({ placeTitle: placeTitle, placeId: placeId });
        }
      });
    } else {
      // scheduleの位置情報をセット
      const placeTitle = selectedSchedule?.address ? selectedSchedule.address : null;
      const placeId = null;
      setPlaceData({ placeTitle: placeTitle, placeId: placeId });
      setAttachedSpot(null);
    }
    // 一時マーカーのセット
    selectedSchedule.latLng
      ? setTempMarker({
          lat: selectedSchedule.latLng.lat,
          lng: selectedSchedule.latLng.lng,
          isEdit: false,
        })
      : setTempMarker(null);
  }, [selectedSchedule]);
  const backToList = () => {
    setShowListMode(true);
    setTempMarker(null);
    setPlaceData(null);
    setAttachedSpot(null);
    setSelectedScheduleIndex(null);
  };

  const onClickSpot = (spot) => {
    setSelectedSpot(spot); // スケジュールにスポットを追加する場合
    setIsScheduleCard(false); //スポットカードを表示にする
    setSearchText(spot.address);
  };

  const backToAttachPlace = () => {
    setIsScheduleCard(true);
  };
  return attachPlaceMode ? (
    <div className={styles.card}>
      <div className={styles.listHeader}>選択可能なスポット一覧</div>
      <SpotList spotList={spotList} onClickSpot={onClickSpot} />
    </div>
  ) : (
    <div className={styles.card}>
      <CrossCloseButtonRight onClick={attachPlaceMode ? backToAttachPlace : backToList} />
      <div className={styles.infoContainer}>
        <div className={styles.titleBox}>
          <h4 className={styles.title}>{selectedSchedule?.title}</h4>
        </div>
        <div className={styles.titleBox}>
          <div className={styles.info}>{placeData?.placeTitle}</div>
        </div>
        {placeData && (
          <div className={styles.googleMapBtnBox}>
            <a
              href={`https://www.google.com/maps/search/?api=1&query=${placeData.placeTitle}&query_place_id=${placeData.placeId}`}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.googleMapBtn}
            >
              GoogleMapで見る
            </a>
          </div>
        )}
        <div className={styles.actionButtonBox}>
          <button
            className={styles.actionButton}
            type="button"
            onClick={() => {
              setShowEditScheduleModal(true);
            }}
          >
            <FontAwesomeIcon icon={["fa", "fa-pen"]} className={styles.actionButtonIconCircle} id="actionButtonIcon" />
            <span>編集</span>
          </button>
          <button
            className={styles.actionButton}
            type="button"
            onClick={() => {
              setAttachPlaceMode(true);
            }}
          >
            <FontAwesomeIcon
              icon={["fa", "fa-map-location"]}
              className={styles.actionButtonIconCircle}
              id="actionButtonIcon"
            />
            <span>位置情報の追加/変更</span>
          </button>
          <button
            className={styles.actionButton}
            type="button"
            onClick={() => {
              deleteSchedule();
            }}
          >
            <FontAwesomeIcon
              icon={["fa", "trash-alt"]}
              className={styles.deleteButtonIconCircle}
              id="actionButtonIcon"
            />
            <span>削除</span>
          </button>
        </div>
        <div className={styles.imageRowBox}>
          {selectedSchedule?.image?.url && (
            <div className={styles.imageBox}>
              <img src={selectedSchedule.image.url} alt="scheduleImage" />
            </div>
          )}
          {attachedSpot?.imageKeys &&
            attachedSpot?.imageKeys.length > 0 &&
            attachedSpot.imageKeys.map((key) => {
              return (
                <div className={styles.imageBox}>
                  <div className={styles.spotImgText}>スポット画像</div>
                  <ImageByKey imageKey={key} alt={"spotImage"} />
                </div>
              );
            })}
        </div>

        {selectedSchedule?.memo && (
          <div className={styles.infoBox}>
            <div className={styles.info}>{selectedSchedule.memo}</div>
          </div>
        )}
        {selectedSchedule?.phone && (
          <div className={styles.infoBox}>
            <h4 className={styles.infoLabel}>電話番号</h4>
            <div className={styles.info}>{selectedSchedule.phone}</div>
          </div>
        )}

        {selectedSchedule?.startTime && (
          <div className={styles.infoBox}>
            <h4 className={styles.infoLabel}>開始時間</h4>
            <div className={styles.info}>{selectedSchedule.startTime}</div>
          </div>
        )}
        {selectedSchedule?.endTime && (
          <div className={styles.infoBox}>
            <h4 className={styles.infoLabel}>終了時間</h4>
            <div className={styles.info}>{selectedSchedule.endTime}</div>
          </div>
        )}
        {selectedSchedule?.url.length > 0 && (
          <div className={styles.infoBox}>
            {selectedSchedule.url.map((url, index) => {
              if (url === "") return null;
              return (
                <div>
                  {index === 0 && <h4 className={styles.infoLabel}>URL</h4>}
                  <ul>
                    <li>
                      <a href={`${url}`} target="_blank" rel="noopener noreferrer" key={index} className={styles.info}>
                        {url}
                      </a>
                    </li>
                  </ul>
                </div>
              );
            })}
          </div>
        )}
        {attachedSpot && (
          <div className={styles.infoBox}>
            <h4 className={styles.infoLabel}>付与されたスポット</h4>
            <button type="button" className={styles.spotInfo} onClick={() => onClickSpot(attachedSpot)}>
              <div className={styles.categoryBox}>
                <div className={styles.categoryName}>{attachedSpot?.category}</div>
              </div>
              <div>{attachedSpot?.name}</div>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
