import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import styles from "../../assets/css/listCard.module.css";

export const SpotList = ({ spotList, onClickSpot }) => {
  const [openDetailIndex, setOpenDetailIndex] = useState(null);

  return (
    <div className={styles.listContainer}>
      <div className={styles.listContents}>
        {spotList?.length >= 0 &&
          spotList.map((spot, index) => {
            return (
              <div id={`${index}-${spot.title}`} key={index} className={styles.content}>
                <div className={styles.scheduleTitleBox}>
                  <div className={styles.scheduleTitle} onClick={() => onClickSpot(spot)}>
                    {spot.name}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};
