// import the library
import { library } from "@fortawesome/fontawesome-svg-core";

// import your icons
import {
  faCircle,
  faHeart,
  faTimes,
  faPlus,
  faAngleDown,
  faAngleUp,
  faAngleRight,
  faAngleLeft,
  faCaretRight,
  faBars,
  faFilter,
  faArrowLeftLong,
  faAsterisk,
  faPen,
  faTrashAlt,
  faLink,
  faStar,
  faPersonWalkingDashedLineArrowRight,
  faLock,
  faArrowRight,
  faArrowUpRightFromSquare,
  faExpand,
  faMinimize,
  faMap,
  faMapPin,
  faCalendarCheck,
  faCalendarPlus,
  faMapLocation,
  faEllipsis,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faCopy } from "@fortawesome/free-regular-svg-icons";

library.add(
  faCircle,
  faHeart,
  faTimes,
  faPlus,
  faAngleDown,
  faAngleUp,
  faAngleRight,
  faAngleLeft,
  faCaretRight,
  faBars,
  faInstagram,
  faFilter,
  faArrowLeftLong,
  faAsterisk,
  faPen,
  faTrashAlt,
  faLink,
  faStar,
  faPersonWalkingDashedLineArrowRight,
  faLock,
  faArrowRight,
  faArrowUpRightFromSquare,
  faExpand,
  faMinimize,
  faCopy,
  faMap,
  faMapLocation,
  faMapPin,
  faCalendarCheck,
  faCalendarPlus,
  faEllipsis,
  faCheck
);
