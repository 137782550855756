import { useState, useEffect, useContext } from "react";
import { ListRidePlansByCreator, ListRidePlansFromPlanMembers } from "../components/listplans/ListPlans";
import styles from "../assets/css/myPlan.module.css";
import { globalUserContext } from "../globalContext";
import { handleConfiguring } from "../helper/auth";
import { Helmet } from "react-helmet-async";
import { NotLogin } from "./ExceptionMessage";
// import { DISCLOSURE_RANGE } from "../constants/planConstants";

export const MyPlan = () => {
  const { authState, setAuthState, userInfo, setAuthMode } = useContext(globalUserContext);
  const [listType, setListType] = useState("youCreated");

  useEffect(() => {
    //ログイン状態かどうか確認
    if (!userInfo && authState === "unauthenticated") {
      setAuthMode("signIn");

      return;
    } else if (authState === "configuring") {
      handleConfiguring(setAuthState, authState);
    } else return;
  }, [authState, userInfo]);

  const changeColor = (id) => {
    const lists = document.getElementsByClassName(styles.navItemActive);
    lists[0].classList.add(styles.navItem);
    lists[0].classList.remove(styles.navItemActive);
    document.getElementById(id).classList.add(styles.navItemActive);
  };

  const onClickList = (e) => {
    const id = e.target.id;
    setListType(id);
    changeColor(id);
  };
  const ListRidePlans = () => {
    if (listType === "youCreated")
      return (
        <ListRidePlansByCreator
          creator={userInfo?.attributes.sub}
          // disclosureRange={DISCLOSURE_RANGE.PUBLIC}
          isTemp={false}
        />
      );
    else if (listType === "asMember") return <ListRidePlansFromPlanMembers userID={userInfo?.attributes.sub} />;
    else if (listType === "temp") return <ListRidePlansByCreator creator={userInfo?.attributes.sub} isTemp={true} />;
    else return null;
  };
  if (!authState || authState === "unauthenticated") {
    return (
      <div id="container">
        <NotLogin />
      </div>
    );
  } else {
    return (
      <div id="container">
        <Helmet>
          <title>マイプラン | pobicle / ポビクル</title>
          <meta
            charSet="utf-8"
            name="マイプラン"
            description="「マイプラン」自分が作った旅プランや作成中のプランの管理ができます。 - pobicle(ポビクル)"
          />
          <meta property="og:url" content="https://www.pobicle.com/mypage/plans" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="「マイプラン」自分が作った旅プランや作成中のプランの管理ができます。" />
          <meta property="og:site_name" content="pobicle" />
          <meta property="og:image" content="%PUBLIC_URL%/logowithslogan.png" />
          <meta property="og:image:width" content="600" />
          <meta property="og:image:height" content="300" />
          <link rel="canonical" href="https://www.pobicle.com/mypage/plans" />
        </Helmet>
        <h1 id="pageTitle" className={styles.pageTitle}>
          マイプラン
        </h1>

        <ul id="navList" className={styles.navList}>
          <li id="asMember" className={styles.navItem} onClick={onClickList}>
            参加中のプラン
          </li>
          <li id="youCreated" className={styles.navItemActive} onClick={onClickList}>
            公開中のプラン
          </li>
          <li id="temp" className={styles.navItem} onClick={onClickList}>
            下書き中のプラン
          </li>
        </ul>

        <ListRidePlans />
      </div>
    );
  }
};
