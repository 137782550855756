import React, { useContext, useEffect } from "react";
import { SpotType } from "../../constants/global";
import { globalUserContext } from "../../globalContext";
import { tryCreateSpot } from "../../helper/dbAccess/spotAccess";
import { InputSpotDetail } from "./InputSpotDetail";

export const AddSpotModal = ({
  setMutateSpotDone,
  clickedLocation,
  showAddSpotModal,
  setShowAddSpotModal,
  setTempMarker,
  setShowListMode,
  showSpotModal,
  setShowSpotList,
  setSearchText,
  spotInput,
  setSpotInput,
  resetSpotInput,
  setClickedLocation,
}) => {
  const { userInfo } = useContext(globalUserContext);
  useEffect(() => {
    if (showSpotModal) {
      document.body.style.overflowY = "hidden";
    }
  }, [showSpotModal]);

  useEffect(() => {
    if (!clickedLocation) return;
    setSpotInput({
      ...spotInput,
      name: clickedLocation.address,
      address: clickedLocation.address,
      latLng: clickedLocation.latLng,
      googlePlaceId: clickedLocation.googlePlaceId,
      creatorId: userInfo.attributes.sub,
      imageKeys: [],
    });
  }, [clickedLocation]);

  const createSpot = async (imageKeys) => {
    try {
      const newSpotInfo = {
        ...spotInput,
        category: spotInput.category ? spotInput.category : "その他",
        imageKeys: imageKeys,
      };
      await tryCreateSpot(newSpotInfo);
    } catch (e) {
      console.error("error", e);
    }
  };

  const onClickAddButton = async (imageKeys) => {
    //スポットの追加
    await createSpot(imageKeys);
    //スポットの追加が完了したら、スポット一覧を表示する
    setShowListMode(true);
    setShowSpotList(true);
    //スポットの変更完了フラグ
    setMutateSpotDone(true);
    //値の初期化
    setClickedLocation(null);
    resetSpotInput();
    setTempMarker(null);
    setShowAddSpotModal(false);
    document.body.style.overflowY = "scroll";
  };

  const onClickClose = () => {
    document.body.style.overflowY = "scroll";
    setShowAddSpotModal(false);
    resetSpotInput();
  };
  return (
    <div>
      {showAddSpotModal ? (
        <div>
          <InputSpotDetail
            clickedLocation={clickedLocation}
            spotType={SpotType.ADDITION}
            onClickButton={onClickAddButton}
            onClickClose={onClickClose}
            setSearchText={setSearchText}
            spotInput={spotInput}
            setSpotInput={setSpotInput}
            setShowAddSpotModal={setShowAddSpotModal}
          />
        </div>
      ) : null}
    </div>
  );
};
