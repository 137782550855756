/*プランをつくる*/
export const RIDE_LEVEL = { beginner: "初心者", intermediate: "中級者", advanced: "上級者" };
export const RIDE_PURPOSE_LIST = ["グルメ", "文化", "自然", "観光", "ご近所散策","ツアー"];
export const RIDE_PURPOSE_LIST_EN = ["Gourmet", "Culture", "Nature", "Tourism", "Local Exploration","Tour Guide"];
export const BICYCLE_TYPE_LIST = [
  "クロスバイク",
  "ロードバイク",
  "マウンテンバイク",
  "BMX",
  "ミニベロ",
  "折り畳み自転車",
  "シティサイクル",
  "e-bike",
];
export const BICYCLE_TYPE_LIST_EN = [
  "CrossBike",
  "LoadBike",
  "MTB",
  "BMX",
  "MiniBike",
  "FoldingBike",
  "CityCycle",
  "e-bike",
];
export const DISCLOSURE_RANGE = {
  PUBLIC: "public",
  PRIVATE: "private",
  URL_ONLY: "url_only",
  FRIEND_ONLY: "friend_only",
};


/*プランをさがす*/
//dev
// export const POBICLE_ACCOUNT = "4272ae89-d4fb-401e-8c70-4e72a95305d4";
//prod
export const POBICLE_ACCOUNT = "6863704f-49d1-472c-bbf9-02f446af9509";
export const POTABI_ACCOUNT = "75ad2f13-ed8a-4f52-9aad-780ba04af315";
export const HAJIMESTRANGE_ACCOUNT = "a3fb1974-202b-4e95-a60a-3c298167bf46";
