import { createContext } from "react";

// logged in user info is universal for every component, so we can put it in global context
export const globalUserContext = createContext({
  userInfo: null,
  setUserInfo: () => {},
  authState: null,
  setAuthState: () => {},
  authMode: null,
  setAuthMode: () => {},
});
