import { useState, useCallback } from "react";
import { tryGetS3URL } from "../helper/s3Access";

export const usePlanImage = () => {
  const [imageURLs, setImageURLs] = useState([]);

  /**
   *@param {Array} -imageKeys is S3key array from DB
   */

  const fetchImageURL = useCallback(
    async (imageKeys) => {
      let imageURL = "";
      for (let i = 0; i < imageKeys.length; i++) {
        if (!imageKeys[i]) {
          imageURLs.push("");
          continue;
        }
        try {
          imageURL = await tryGetS3URL(imageKeys[i]);
        } catch (e) {
          console.error(e);
          imageURL = "";
        } finally {
          imageURLs.push(imageURL);
        }
      }
      return imageURLs;
    },
    [imageURLs]
  );

  return { fetchImageURL };
};
