import { useEffect, useState } from "react";
import { usePlanImage } from "../../hooks/usePlanImage.js";

export const ImageByKey = ({ imageKey, alt }) => {
  const [imageUrl, setImageUrl] = useState(null);
  const { fetchImageURL } = usePlanImage();
  useEffect(() => {
    const getImageURL = async () => {
      const imageUrl = await fetchImageURL([imageKey]);
      setImageUrl(imageUrl);
    };
    getImageURL();
  }, [imageKey]);

  return <img src={imageUrl} alt={alt} />;
};
