import React, { useEffect, useState, useContext } from "react";
import styles from "../../assets/css/confirmPlan.module.css";
import { tryCopyFunc } from "../../helper/copyClipBoard";
import { tryCreateRidePlan, tryUpdateRidePlan } from "../../helper/dbAccess/planAccess";
import { tryGetSpot } from "../../helper/dbAccess/spotAccess";
import { tryGetUser } from "../../helper/dbAccess/userAccess";
import { tryGetS3URL, tryDeleteFile, tryUploadFile, tryRollbackS3Upload } from "../../helper/s3Access";
import { usePlanImage } from "../../hooks/usePlanImage";
import { ConfirmResultModal } from "../modal/ConfirmResultModal";
import { ReadOnlyScheduleModal } from "../schedule/ReadOnlyScheduleModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { globalUserContext } from "../../globalContext";
import { ReadOnlyMap } from "../map/MapRender";
import { BackButtonUI } from "../commons/buttons/BackButton";
import { LoadingIcon } from "../commons/LoadingIcon";
import { DISCLOSURE_RANGE } from "../../constants/planConstants";
import noImage from "../../assets/img/noimage.png";
import { SanitizedText, SanitizedTextWithUrl } from "../commons/SanitizedText";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
export const ConfirmPlan = ({
  planInfo,
  setPlanInfo,
  topImage,
  S3Keys,
  editMode,
  areaCenter,
  setAreaCenter,
  routeDataUrl,
  setRouteDataUrl,
  routeKeysWillBeDeleted,
  setShowPreview,
}) => {
  const [showSchedule, setShowSchedule] = useState();
  const [result, setResult] = useState(null);
  const [draftMode, setDraftMode] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [scheduleIndex, setScheduleIndex] = useState(null);
  const [publishingSettings, setPublishingSettings] = useState(null);
  const [imageUrlList, setImageUrlList] = useState(null); //top画像一覧用の配列(""は含まない)
  const [creator, setCreator] = useState("");
  const [isLinkCopied, setIsLinkCopied] = useState(false);
  const [isInfoCopied, setIsInfoCopied] = useState(false);
  const [scheduleListWithSpot, setScheduleListWithSpot] = useState(null);
  const { userInfo } = useContext(globalUserContext);
  const { fetchImageURL } = usePlanImage();
  //plan情報の取得
  useEffect(() => {
    if (!planInfo) return;
    //スケジュールに付与されているスポット情報を取得
    const processScheduleInfoWithSpot = async (scheduleList) => {
      // マーカー情報の取得
      const scheduleListWithSpot = [];
      //spotIdがある場合は、spotIdからlatLngを取得
      for (let i = 0; i < scheduleList.length; i++) {
        if (scheduleList[i].spotId) {
          //spotIdからlatLngを取得
          const spot = await tryGetSpot(scheduleList[i].spotId);
          scheduleListWithSpot.push({ ...scheduleList[i], spot: spot });
        } else {
          scheduleListWithSpot.push(scheduleList[i]);
        }
      }
      return scheduleListWithSpot;
    };
    let latLngList = [];
    processScheduleInfoWithSpot(planInfo.scheduleList).then((scheduleListWithSpot) => {
      setScheduleListWithSpot(scheduleListWithSpot);
      //マーカー情報のセット
      scheduleListWithSpot.forEach((schedule) => {
        if (schedule.latLng) {
          latLngList.push(schedule.latLng);
        }
      });
      setMarkers(latLngList);
    });
  }, [planInfo]);

  //プラン作成ユーザー情報の取得
  useEffect(() => {
    if (!planInfo && !userInfo) return;
    const prepareCreatorInfo = async () => {
      try {
        if (planInfo.creator) {
          const res = await tryGetUser(planInfo.creator);
          if (res && res.nickName) {
            setCreator(res.nickName);
          } else {
            setCreator(planInfo.creator);
          }
        } else if (userInfo) {
          setCreator(userInfo.attributes.preferred_username);
        }
      } catch (e) {
        console.error(e);
      }
    };
    prepareCreatorInfo();
  }, [planInfo]);

  //実施地域をセット
  useEffect(() => {
    if (areaCenter) setAreaCenter(areaCenter);
  }, [areaCenter]);

  // スクロール設定の再セット。モーダルを閉じた時に呼び出す
  useEffect(() => {
    document.body.style.overflowY = "hidden";
  }, [showSchedule]);

  // すべて画像keyの取得 & URL化して表示
  useEffect(() => {
    if (!scheduleListWithSpot || !scheduleListWithSpot.length) return;

    const getImageUrls = async (imageKeys) => {
      const imageURLs = await tryGetS3URL(imageKeys[0]);
      return imageURLs;
    };
    (async () => {
      const urlList = [];
      try {
        //TOP画像URLのセット
        if (topImage.file) {
          urlList[0] = URL.createObjectURL(topImage.file);
          // URLのTOPがある場合
        } else if (topImage.url) {
          urlList[0] = topImage.url;
        } else {
          urlList[0] = noImage;
        }
        if (planInfo.scheduleList && planInfo.scheduleList.length > 0) {
          for (let i = 0; i < planInfo.scheduleList.length; i++) {
            // スケジュールの画像URLをセット
            if (planInfo.scheduleList[i].image.file) {
              urlList.push(URL.createObjectURL(planInfo.scheduleList[i].image.file));
            } else if (planInfo.scheduleList[i].image.url) {
              urlList.push(planInfo.scheduleList[i].image.url);
            }
            // スポットの画像URLをセット
            else if (scheduleListWithSpot[i].spotId) {
              const spot = scheduleListWithSpot[i].spot;
              if (spot && spot.imageKeys && spot.imageKeys.length > 0) {
                await getImageUrls(spot.imageKeys).then((res) => {
                  urlList.push(res);
                });
              } else {
                urlList.push("");
              }
            } else {
              urlList.push("");
            }
          }
          setImageUrlList(urlList);
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [scheduleListWithSpot, topImage]);

  //ルートデータのセット
  useEffect(() => {
    if (!routeDataUrl) return;
    setRouteDataUrl(routeDataUrl);
  }, [routeDataUrl]);

  useEffect(() => {
    if (!planInfo) return;
    try {
      if (planInfo.disclosureRange === DISCLOSURE_RANGE.PRIVATE) {
        setPublishingSettings("非公開（このプランのURLを知っている人のみ閲覧可能）");
        return;
      } else if (planInfo.disclosureRange === DISCLOSURE_RANGE.PUBLIC) {
        setPublishingSettings("公開（すべての人が閲覧可能）");
      } else {
        return;
      }
    } catch (e) {
      console.error(e);
    }
  }, [planInfo]);

  const onCopyLink = () => {
    const plainText = window.location.href;
    tryCopyFunc(plainText, null, setIsLinkCopied);
  };
  //プラン情報をクリップボードにコピー
  const onCopyInfo = async () => {
    if (!planInfo) return;
    const { title, description, scheduleList } = planInfo;
    let schedulePlainText = "";
    let scheduleHtmlText = "";
    //スケジュール情報をテキストに整形
    if (scheduleList.length > 0) {
      scheduleList.forEach((schedule, i) => {
        schedulePlainText =
          schedulePlainText + `${i + 1}, ${schedule.startTime} ~ ${schedule.endTime}, ${schedule.title}\n`;
        scheduleHtmlText =
          scheduleHtmlText +
          `${i + 1}, ${schedule.startTime ? schedule.startTime : ""} ${
            schedule.endTime ? `~ ${schedule.endTime}` : ""
          } ${schedule.title}<br/>`;
      });
    }
    //plainTextは、htmlタグを含まないテキストでタイトルなどを太字にしない
    const plainText = `${title}\n${description ? description : ""}\n\nスケジュール\n${
      schedulePlainText ? schedulePlainText : ""
    }`;
    //htmlTextは、htmlタグを含むテキストでタイトルなどを太字にする
    const htmlText = `<strong>${title}</strong><br/>${
      description ? description : ""
    }<br/<br/><strong>スケジュール</strong><br/>${scheduleHtmlText ? scheduleHtmlText : ""}`;
    tryCopyFunc(plainText, htmlText, setIsInfoCopied);
  };
  /**
   * register planinfo .
   * @param {boolean} isTemp if it is for temporary save,true else false
   * @param {boolean} isEdit if it is for edit(update) ,true else false
   */
  const registerPlanInfo = async (isTemp, isEdit) => {
    let imageKeyList = [];
    let planInfoWithImage = "";
    const button = document.getElementById("defaultButton");
    button.disabled = true;
    // return
    if (!userInfo) {
      console.error("User is not found.");
      setResult(false);
      return;
    }
    // 0 メイン画像の処理
    // 0.1 編集モード＆既にS3Keysがある場合
    try {
      // 変更あり
      if (topImage.file || topImage.file === "") {
        //0.2 画像をS3に登録
        if (topImage.file) {
          const item = await tryUploadFile(topImage.file);
          imageKeyList[0] = item.key;
        } else {
          imageKeyList[0] = "";
        }
      }
      //変更なし
      else if (topImage.file === null && S3Keys && S3Keys[0]) {
        // s3keyをそのまま入れる
        imageKeyList[0] = S3Keys[0];
      } else {
        imageKeyList[0] = "";
      }
      // 1 スケジュール画像の処理
      // 1.2 画像をS3に登録
      for (let i = 0; i < planInfo.scheduleList.length; i++) {
        //変更なし
        if (planInfo.scheduleList[i].image?.file === null) {
          //既に登録済みのS3keyがある
          if (S3Keys && S3Keys[i + 1]) {
            imageKeyList.push(S3Keys[i + 1]);
            continue;
          } else {
            imageKeyList.push("");
            continue;
          }
        }
        // 変更あり
        else if (planInfo.scheduleList[i].image?.file || planInfo.scheduleList[i].image?.file === "") {
          //画像を登録
          if (planInfo.scheduleList[i].image?.file) {
            const item = await tryUploadFile(planInfo.scheduleList[i].image.file);
            imageKeyList.push(item.key);
            continue;
          }
          //×で削除した場合
          else {
            imageKeyList.push("");
          }
        }
        //変更なし
        else if (planInfo.scheduleList[i].image?.file === null && S3Keys[i + 1]) {
          // s3keyをそのまま入れる
          imageKeyList.push(S3Keys[i + 1]);
          continue;
        } else {
          imageKeyList.push("");
          continue;
        }
      }
    } catch (e) {
      console.error(e);
      setResult(false);
      return;
    }
    // 登録用のscheduleListを準備
    const copiedScheduleList = JSON.parse(JSON.stringify(planInfo.scheduleList)); //deep copy planInfo.scheduleList
    const scheduleListWithoutImage = copiedScheduleList.map((schedule) => {
      if (schedule.image) delete schedule.image;
      return schedule;
    });
    let image = "";
    let scheduleImages = [];
    imageKeyList.forEach((key, i) => {
      if (i === 0) {
        image = key;
      } else {
        scheduleImages.push(key);
      }
    });
    //2 プラン情報を作成
    planInfoWithImage = {
      ...planInfo,
      scheduleList: scheduleListWithoutImage,
      image: image,
      scheduleImages: scheduleImages,
    };
    //3. create or update ride plan
    try {
      const preparedPlanInfo = planInfoWithImage ? planInfoWithImage : planInfo;
      // return;
      if (isTemp) {
        // 一時保存の場合
        if (isEdit) {
          //編集モードもしくは下書き保存済みの場合
          await tryUpdateRidePlan({ ...preparedPlanInfo, isTemp: true }, userInfo);
        } else {
          //新規登録の場合
          await tryCreateRidePlan({ ...preparedPlanInfo, isTemp: true }, userInfo);
        }
        setPlanInfo({ ...planInfo, isTemp: true });
      } else {
        // 公開・非公開の登録の場合
        if (isEdit) {
          //編集モードもしくは下書き保存済みの場合
          await tryUpdateRidePlan({ ...preparedPlanInfo, isTemp: false }, userInfo);
        } else {
          //新規登録の場合
          await tryCreateRidePlan(preparedPlanInfo, userInfo);
        }
        setPlanInfo({ ...planInfo, isTemp: false });
      }
      setResult(true);
      setDraftMode(isTemp);
      //3 要らなくなったルートデータを削除
      if (routeKeysWillBeDeleted) {
        for (let i = 0; i < routeKeysWillBeDeleted.length; i++) {
          await tryDeleteFile(routeKeysWillBeDeleted[i]);
        }
      }
      //4 置き換えられた画像を削除
      if (S3Keys) {
        S3Keys.forEach(async (key) => {
          if (!imageKeyList.includes(key)) {
            await tryDeleteFile(key);
          }
        });
      }
      // console.log("Successfully registered.");
    } catch (e) {
      // If fail registering, delete uploaded image and re-upload original keys
      console.error(e);
      setResult(false);
      tryRollbackS3Upload(S3Keys, imageKeyList);
    } finally {
      button.disabled = false;
    }
  };
  const RegisterButton = () => {
    //下書き保存状態の場合
    if (editMode === "temp") {
      return (
        <div className={styles.applyButtonBox}>
          <button
            type="button"
            id="grayButton"
            className={styles.updatePlan}
            onClick={() => registerPlanInfo(true, true)}
          >
            下書き保存
          </button>
          <button
            type="submit"
            className={styles.updatePlan}
            id="defaultButton"
            onClick={() => registerPlanInfo(false, true)}
          >
            公開
          </button>
          <p>「プラン登録」を押すと公開が完了します。</p>
        </div>
      );
    } else if (editMode === DISCLOSURE_RANGE.PUBLIC || editMode === DISCLOSURE_RANGE.PRIVATE) {
      //公開済みプランの編集モードの場合
      return (
        <div className={styles.applyButtonBox}>
          <button
            type="button"
            id="grayButton"
            className={styles.updatePlan} //GA用
            onClick={() => registerPlanInfo(true, true)}
          >
            下書き保存
          </button>
          <button
            type="submit"
            id="defaultButton"
            className={styles.updatePlan} //GA用
            onClick={() => registerPlanInfo(false, true)}
          >
            編集を確定
          </button>
          <p>「 下書き保存 」を押すと公開が取り下げられます。</p>
        </div>
      );
    } else if (!editMode) {
      //新規登録の場合
      return (
        <div className={styles.applyButtonBox}>
          <button
            type="button"
            id="grayButton"
            className={styles.registrationPlan} //GA用
            onClick={() => registerPlanInfo(true, false)}
          >
            下書き保存
          </button>
          <button
            type="submit"
            className={styles.registrationPlan} //GA用
            id="defaultButton"
            onClick={() => registerPlanInfo(false, false)}
          >
            新規公開
          </button>
          <p>「新規公開」を押すと公開が完了します。</p>
        </div>
      );
    }
  };
  return (
    <div className={styles.fixedContainer}>
      <div className={styles.previewContainer}>
        <div className={styles.topHeader}>
          <BackButtonUI onClick={() => setShowPreview(false)} />
          <div className={styles.optionBar}>
            {!isLinkCopied ? (
              <button onClick={() => onCopyLink()} className={styles.copyButton}>
                <FontAwesomeIcon icon={["fas", "fa-link"]} className={styles.faIcon} />
                <div>リンク</div>
              </button>
            ) : (
              <button className={styles.copiedButton} disabled>
                <FontAwesomeIcon icon={["fas", "fa-link"]} className={styles.faIcon} />
                <div>コピー完了</div>
              </button>
            )}
          </div>
        </div>
        {!planInfo ? (
          <div id="container">
            <LoadingIcon />
          </div>
        ) : (
          <div className={styles.planContainer}>
            {/* PC */}
            {imageUrlList && (
              <div className="topImgBoxPC">
                <swiper-container
                  navigation="true"
                  pagination="true"
                  pagination-type="progressbar"
                  rewind="true"
                  space-between="0"
                  slides-per-view={planInfo.scheduleList && planInfo.scheduleList.length >= 2 ? "2" : "1"}
                  class="mySwiper"
                >
                  <div className="swiper-button-next"></div>
                  <div className="swiper-button-prev"></div>
                  {imageUrlList.map((image, index) => {
                    // メイン画像
                    if (index === 0) {
                      return (
                        <swiper-slide key={index}>
                          <div className="slideBox">
                            <img src={imageUrlList[0] ? imageUrlList[0] : noImage} alt="topImage" id="topImage" />
                          </div>
                        </swiper-slide>
                      );
                    } else {
                      return (
                        <swiper-slide key={index} lazy="true">
                          <div className="slideBox">
                            <img
                              src={imageUrlList[index] ? imageUrlList[index] : noImage}
                              alt="topImage"
                              id="topImage"
                              loading="lazy"
                            />
                            <div className="topImgTitle">{`${
                              index + ". " + planInfo.scheduleList[index - 1]?.title
                            }`}</div>
                          </div>
                        </swiper-slide>
                      );
                    }
                  })}
                </swiper-container>
              </div>
            )}
            {/* モバイル */}
            {imageUrlList && (
              <div className="topImgBoxMobile">
                <swiper-container
                  navigation="true"
                  pagination="true"
                  pagination-type="progressbar"
                  rewind="true"
                  space-between="0"
                  slides-per-view="1"
                  class="mySwiper"
                >
                  {imageUrlList.map((schedule, index) => {
                    if (index === 0) {
                      return (
                        <swiper-slide key={index}>
                          <div className="slideBox">
                            <img src={imageUrlList[0] ? imageUrlList[0] : noImage} alt="topImage" id="topImage" />
                          </div>
                        </swiper-slide>
                      );
                    } else {
                      return (
                        <swiper-slide
                          key={index}
                          lazy="true"
                          onClick={() => {
                            setScheduleIndex(index - 1);
                            setShowSchedule(true);
                          }}
                        >
                          <div className="slideBox">
                            <img
                              src={imageUrlList[index] ? imageUrlList[index] : noImage}
                              alt="topImage"
                              id="topImage"
                              loading="lazy"
                            />
                            <div className="topImgTitle">{`${
                              index + ". " + planInfo.scheduleList[index - 1]?.title
                            }`}</div>
                          </div>
                        </swiper-slide>
                      );
                    }
                  })}
                </swiper-container>
              </div>
            )}
            <div className={styles.planContent}>
              <div className={styles.planDetail}>
                <div className={styles.titleBox}>
                  <h1>
                    <SanitizedText text={planInfo.title} />
                  </h1>
                  <div className={styles.copyInfoButtonBox}>
                    {!isInfoCopied ? (
                      <button onClick={() => onCopyInfo()} className={styles.copyInfoButton}>
                        <FontAwesomeIcon icon="fa-regular fa-copy" />
                        プラン情報をクリップボードにコピー
                      </button>
                    ) : (
                      <div className={styles.copiedInfoButton}>
                        <FontAwesomeIcon icon="fa-regular fa-copy" />
                        コピー完了
                      </div>
                    )}
                  </div>
                </div>
                {planInfo.originalPlanId &&
                  (planInfo.originalPlan?.id ? (
                    <span className={styles.originalPlan}>
                      このプランは
                      <Link to={`/find-plan/${planInfo.originalPlan.id}`}>{planInfo.originalPlan.title}</Link>
                      を基に作成されました。
                    </span>
                  ) : (
                    <span className={styles.deletedOriginalPlan}>このプランの基となったプランは既に削除済み。</span>
                  ))}
                <div className={styles.tagBox}>
                  {planInfo.rideLevel && <div className={styles.tag}>{planInfo.rideLevel}向け</div>}
                  {planInfo.purpose &&
                    planInfo.purpose.map((purpose, index) => {
                      return (
                        <div className={styles.tag} key={index}>
                          {purpose}
                        </div>
                      );
                    })}
                  {planInfo.bicycleType &&
                    planInfo.bicycleType.map((bicycleType, index) => {
                      return (
                        <div className={styles.tag} key={index}>
                          {bicycleType}
                        </div>
                      );
                    })}
                </div>
                <div className={styles.infoBox}>
                  <div className={styles.infoLabel}>地域</div>
                  {!planInfo.prefecture && !planInfo.city ? (
                    <div className={styles.infoContent}>データなし</div>
                  ) : (
                    <div className={styles.infoContent}>
                      {planInfo.prefecture} {planInfo.city}
                    </div>
                  )}
                </div>
                <div className={styles.infoBox}>
                  <div className={styles.infoLabel}>作成者</div>
                  {/* <img src={iconImg} alt="アイコン" className={styles.iconImage} /> */}
                  <div className={styles.infoContent}>{creator}</div>
                </div>
                {planInfo.createdAt && (
                  <div className={styles.infoBox}>
                    <div className={styles.infoLabel}>作成日</div>
                    <div className={styles.infoContent}>{new Date(planInfo.createdAt).toLocaleDateString()}</div>
                  </div>
                )}
                {planInfo.updatedAt && (
                  <div className={styles.infoBox}>
                    <div className={styles.infoLabel}>最終更新日</div>
                    <div className={styles.infoContent}>{new Date(planInfo.updatedAt).toLocaleDateString()}</div>
                  </div>
                )}
                <div className={styles.infoBox}>
                  <div className={styles.infoLabel}>公開設定</div>
                  <div className={styles.infoContent}>{publishingSettings}</div>
                </div>
                <div className={styles.planExplanation}>
                  <SanitizedTextWithUrl text={planInfo.description} />
                </div>
                {planInfo.scheduleList && planInfo.scheduleList.length >= 1 && (
                  <div className={styles.scheduleContent}>
                    <div className={styles.infoSection}>スケジュール</div>
                    <div className={styles.scheduleList}>
                      <div className={styles.timeSchedule}>
                        {planInfo.scheduleList.map((schedule, index) => (
                          <div key={index}>
                            <div className={styles.scheduleRow}>
                              <div>
                                <div className={styles.scheduleIndex}>{`${index + 1}`}.</div>
                              </div>
                              <motion.div
                                whileHover={{ scale: 1.02 }}
                                whileTap={{ scale: 0.95 }}
                                className={styles.scheduleBox}
                                onClick={() => {
                                  setScheduleIndex(index);
                                  setShowSchedule(true);
                                }}
                              >
                                <div className={styles.timeBox}>
                                  {schedule.startTime && ( // startTimeがある場合
                                    <div className={styles.time}>
                                      {schedule.startTime} ~ {schedule.endTime}
                                    </div>
                                  )}
                                </div>
                                <div className={styles.scheduleTitle}>{schedule.title}</div>
                                <div className={styles.scheduleMemo}>{schedule.memo}</div>
                              </motion.div>
                              {
                                //最初のスケジュールの最初用のタイムラインを表示
                                index === 0 && <div className={styles.firstTimeline}></div>
                              }
                              {index !== 0 && index !== planInfo.scheduleList.length - 1 && (
                                <div className={styles.timeline}></div>
                              )}
                              {
                                //最後の場合は最後用のタイムラインを表示
                                index === planInfo.scheduleList.length - 1 && (
                                  <div className={styles.lastTimeline}></div>
                                )
                              }
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
                {showSchedule && (
                  <ReadOnlyScheduleModal
                    show={showSchedule}
                    setShow={setShowSchedule}
                    schedule={scheduleListWithSpot[scheduleIndex]}
                    scheduleIndex={scheduleIndex}
                    setScheduleIndex={setScheduleIndex}
                    imgUrl={imageUrlList && scheduleIndex !== null ? imageUrlList[scheduleIndex + 1] : null}
                  />
                )}
                <div className={styles.mapContent}>
                  <div className={styles.infoSection}>マップ</div>
                  <div className={styles.mapBox}>
                    <ReadOnlyMap
                      routeDataUrl={routeDataUrl}
                      markers={markers}
                      scheduleList={planInfo.scheduleList}
                      setShowSchedule={setShowSchedule}
                      setScheduleIndex={setScheduleIndex}
                      areaArray={[planInfo.city ? planInfo.city : "", planInfo.prefecture ? planInfo.prefecture : ""]}
                    />
                  </div>
                </div>
                {/* ツアー情報 */}
                {(planInfo.date || planInfo.isTour) && (
                  <div>
                    <h3>ツアー概要</h3>
                    <div className={styles.tourInfoBox}>
                      {planInfo.date && (
                        <div className={styles.infoBox}>
                          <div className={styles.infoLabel}>開催日</div>
                          <div className={styles.tourInfoContent}>{new Date(planInfo.date).toLocaleDateString()}</div>
                        </div>
                      )}
                      {planInfo.time && (
                        <div className={styles.infoBox}>
                          <div className={styles.infoLabel}>所要時間</div>
                          <div className={styles.tourInfoContent}>{planInfo.time}</div>
                        </div>
                      )}
                      {planInfo.deadline && (
                        <div className={styles.infoBox}>
                          <div className={styles.infoLabel}>応募締切</div>
                          <div className={styles.tourInfoContent}>
                            {new Date(planInfo.deadline).toLocaleString().slice(0, -3)}
                          </div>
                        </div>
                      )}
                      {planInfo.maxParticipants && (
                        <div className={styles.infoBox}>
                          <div className={styles.infoLabel}>定員</div>
                          <div className={styles.tourInfoContent}>{planInfo.maxParticipants}</div>人
                        </div>
                      )}
                      {planInfo.minParticipants && (
                        <div className={styles.infoBox}>
                          <div className={styles.infoLabel}>最少催行人数</div>
                          <div className={styles.tourInfoContent}>{planInfo.minParticipants}</div>人
                        </div>
                      )}
                      {planInfo.price && (
                        <div className={styles.infoBox}>
                          <div className={styles.infoLabel}>参加費</div>
                          <div className={styles.tourInfoContent}>￥{planInfo.price}</div>
                        </div>
                      )}
                      {planInfo.paymentMethod && (
                        <div className={styles.infoBox}>
                          <div className={styles.infoLabel}>お支払い方法</div>
                          <div className={styles.tourInfoContent}>
                            {<SanitizedTextWithUrl text={planInfo.paymentMethod} />}
                          </div>
                        </div>
                      )}
                      {planInfo.notes && (
                        <div className={styles.infoBox}>
                          <div className={styles.infoLabel}>注意事項</div>
                          <div className={styles.notes}>{<SanitizedTextWithUrl text={planInfo.notes} />}</div>
                        </div>
                      )}
                      {planInfo.applyMethods && planInfo.applyMethods.length > 0 && (
                        <div className={styles.applyMethodInfoBox}>
                          <div className={styles.infoLabel}>お申し込み手順</div>
                          {planInfo.applyMethods.map((applyMethod, index) => (
                            <div key={index} className={styles.applyMethodBox}>
                              {planInfo.applyMethods.length > 1 && <div>{index + 1}. </div>}
                              {<SanitizedTextWithUrl text={applyMethod} />}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        <RegisterButton />
        <ConfirmResultModal
          confirmResult={{ result: result, mode: draftMode }}
          planInfo={planInfo}
          setShowPreview={setShowPreview}
          editMode={editMode}
          topImage={topImage}
        />
      </div>
    </div>
  );
};
