import React, { useState, useEffect } from "react";
import styles from "../../assets/css/global/listPlans.module.css";
import { DISCLOSURE_RANGE } from "../../constants/planConstants";
import { tryListRidePlansByDisclosureRange, tryListRidePlansByCreator } from "../../helper/dbAccess/planAccess";
import { tryListRidePlansFromPlanMembers } from "../../helper/dbAccess/planMemberAccess";
import { PlanCard } from "../../components/listplans/PlanCard";
import { LoadingIcon } from "../commons/LoadingIcon";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "../../assets/css/global/swiper.css";
// import function to register Swiper custom elements
import { register } from "swiper/element/bundle";
// register Swiper custom elements
register();
//新しい順にプランを表示する
export const ListPlansByDisclosureRange = ({
  limit = null,
  isTemp,
  selectedTag,
  /*isWanted*/
}) => {
  const [fetchedPlans, setFetchedPlans] = useState(null);
  const [filteredPlans, setFilteredPlans] = useState(null);
  const [isDoneFetch, setIsDoneFetch] = useState(false);
  const [isFailFetch, setIsFailFetch] = useState(false);
  const [indexOfOpenedMenu, setIndexOfOpenedMenu] = useState(null);

  const listPlanAndImage = async () => {
    setIsFailFetch(false);
    try {
      const result = await tryListRidePlansByDisclosureRange(DISCLOSURE_RANGE.PUBLIC, isTemp /*isWanted*/);
      result.items.length > 0 ? setFetchedPlans(result.items) : setFetchedPlans(null);
      setIsDoneFetch(true);
    } catch (e) {
      console.error(e);
      setIsFailFetch(true);
    }
  };
  //初回レンダー時にプランをfetch
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      listPlanAndImage();
    }
    return () => (isMounted = false);
  }, []);

  //表示するプランを絞り込む(カスタム可能)
  useEffect(() => {
    if (!fetchedPlans) return;
    if (selectedTag) {
      const filteredPlans = fetchedPlans.filter((plan) => plan.purpose?.includes(selectedTag.purpose.tagName));
      setFilteredPlans(filteredPlans);
    } else {
      setFilteredPlans(fetchedPlans);
    }
  }, [fetchedPlans, selectedTag]);

  if (isDoneFetch) {
    return (
      <div id="container">
        <section className={styles.planCardsBox}>
          <div className={styles.cardsBoxWrap}>
            {filteredPlans &&
              filteredPlans.map((plan, index) => {
                if (!limit || +index + 1 <= limit) {
                  if (!selectedTag) {
                    return (
                      <div className={styles.cardBox} key={index}>
                        <PlanCard
                          indexOfOpenedMenu={indexOfOpenedMenu}
                          setIndexOfOpenedMenu={setIndexOfOpenedMenu}
                          index={index}
                          planInfo={plan}
                        />
                      </div>
                    );
                  } else if (plan.purpose?.includes(selectedTag.purpose.tagName)) {
                    return (
                      <div className={styles.cardBox} key={index}>
                        <PlanCard
                          indexOfOpenedMenu={indexOfOpenedMenu}
                          setIndexOfOpenedMenu={setIndexOfOpenedMenu}
                          index={index}
                          planInfo={plan}
                        />
                      </div>
                    );
                  } else return null;
                } else return null;
              })}
          </div>
        </section>
      </div>
    );
  } else if (isDoneFetch && !filteredPlans) {
    return (
      <div id="container">
        <Message text={"参加中のプランはありません。"} />
      </div>
    );
  } else if (isFailFetch) {
    return (
      <div id="container">
        <FetchFailed isFailFetch={isFailFetch} listPlanAndImage={listPlanAndImage} />
      </div>
    );
  } else {
    return (
      <div id="container">
        <LoadingIcon />
      </div>
    );
  }
};

export const ListRidePlansByCreator = ({
  creator,
  limit = null,
  disclosureRange = null,
  isTemp = null,
  selectedTag,
  slider = false,
  /*isWanted*/
}) => {
  const [fetchedPlans, setFetchedPlans] = useState(null);
  const [isDoneFetch, setIsDoneFetch] = useState(false);
  const [isFailFetch, setIsFailFetch] = useState(false);
  const [filteredPlans, setFilteredPlans] = useState(null);
  const [indexOfOpenedMenu, setIndexOfOpenedMenu] = useState(null);
  const listPlanAndImage = async () => {
    setIsFailFetch(false);
    try {
      const result = await tryListRidePlansByCreator(creator, disclosureRange, isTemp /*isWanted*/);
      result.items.length > 0 ? setFetchedPlans(result.items) : setFetchedPlans(null);
      setIsDoneFetch(true);
    } catch (e) {
      console.error(e);
      setIsFailFetch(true);
    }
  };
  useEffect(() => {
    let isMounted = true;
    if (creator && isMounted) {
      listPlanAndImage();
    } else return;
    return () => (isMounted = false);
  }, [creator]);

  //表示するプランを絞り込む(カスタム可能)
  useEffect(() => {
    if (!fetchedPlans) return;
    if (selectedTag && selectedTag.purpose) {
      const filteredPlans = fetchedPlans.filter((plan) => plan.purpose?.includes(selectedTag.purpose.tagName));
      setFilteredPlans(filteredPlans);
    } else {
      setFilteredPlans(fetchedPlans);
    }
  }, [fetchedPlans, selectedTag]);

  if (isDoneFetch && fetchedPlans) {
    return (
      <section className={styles.planCardsBox}>
        {slider ? (
          /* -----プランリストのスライダー表示-----  */
          <div>
            <div className={styles.planCardsSliderPC}>
              <swiper-container
                navigation="true"
                pagination="true"
                rewind="true"
                space-between="50"
                slides-per-view={filteredPlans.length > 4 ? 4 : filteredPlans.length}
                class="mySwiper"
              >
                <div className="swiper-button-next"></div>
                <div className="swiper-button-prev"></div>
                {filteredPlans.map((plan, index) => {
                  if (!limit || +index + 1 <= limit) {
                    if (!selectedTag) {
                      return (
                        <swiper-slide key={index}>
                          <PlanCard
                            indexOfOpenedMenu={indexOfOpenedMenu}
                            setIndexOfOpenedMenu={setIndexOfOpenedMenu}
                            index={index}
                            planInfo={plan}
                          />
                        </swiper-slide>
                      );
                    } else if (plan.purpose?.includes(selectedTag.purpose.tagName)) {
                      return (
                        <swiper-slide index={index}>
                          <PlanCard
                            indexOfOpenedMenu={indexOfOpenedMenu}
                            setIndexOfOpenedMenu={setIndexOfOpenedMenu}
                            index={index}
                            planInfo={plan}
                          />
                        </swiper-slide>
                      );
                    } else return null;
                  } else return null;
                })}
              </swiper-container>
            </div>
            <div className={styles.cardsBoxSlider}>
              {filteredPlans.map((plan, index) => {
                if (!limit || +index + 1 <= limit) {
                  if (!selectedTag) {
                    return (
                      <div className={styles.cardBox} key={index}>
                        <PlanCard
                          indexOfOpenedMenu={indexOfOpenedMenu}
                          setIndexOfOpenedMenu={setIndexOfOpenedMenu}
                          index={index}
                          planInfo={plan}
                        />
                      </div>
                    );
                  } else if (plan.purpose?.includes(selectedTag.purpose.tagName)) {
                    return (
                      <div className={styles.cardBox} key={index}>
                        <PlanCard
                          indexOfOpenedMenu={indexOfOpenedMenu}
                          setIndexOfOpenedMenu={setIndexOfOpenedMenu}
                          index={index}
                          planInfo={plan}
                        />
                      </div>
                    );
                  } else return null;
                } else return null;
              })}
            </div>
          </div>
        ) : (
          /*-----プランリストの全表示-----*/
          <div className={styles.cardsBoxWrap}>
            {filteredPlans.map((plan, index) => {
              if (!limit || +index + 1 <= limit) {
                if (!selectedTag) {
                  return (
                    <div className={styles.cardBox} key={index}>
                      <PlanCard
                        indexOfOpenedMenu={indexOfOpenedMenu}
                        setIndexOfOpenedMenu={setIndexOfOpenedMenu}
                        index={index}
                        planInfo={plan}
                      />
                    </div>
                  );
                } else if (plan.purpose?.includes(selectedTag.purpose.tagName)) {
                  return (
                    <div className={styles.cardBox} key={index}>
                      <PlanCard
                        indexOfOpenedMenu={indexOfOpenedMenu}
                        setIndexOfOpenedMenu={setIndexOfOpenedMenu}
                        index={index}
                        planInfo={plan}
                      />
                    </div>
                  );
                } else return null;
              } else return null;
            })}
          </div>
        )}
      </section>
    );
  } else if (isDoneFetch && !fetchedPlans) {
    if (isTemp) {
      return <Message text={"下書き中のプランはありません。"} />;
    } else {
      return <Message text={"公開中のプランはありません。"} />;
    }
  } else if (isFailFetch) {
    return <FetchFailed isFailFetch={isFailFetch} listPlanAndImage={listPlanAndImage} />;
  } else {
    return <LoadingIcon />;
  }
};

//listPlanMemberからplanInfoを取得しに行く
export const ListRidePlansFromPlanMembers = ({ userID, limit = null }) => {
  const [fetchedPlans, setFetchedPlans] = useState(null);
  const [isDoneFetch, setIsDoneFetch] = useState(false);
  const [isFailFetch, setIsFailFetch] = useState(false);
  const [indexOfOpenedMenu, setIndexOfOpenedMenu] = useState(null);

  const listPlanAndImage = async () => {
    setIsFailFetch(false);
    try {
      const result = await tryListRidePlansFromPlanMembers(userID);
      result.length > 0 ? setFetchedPlans(result.map((item) => item.ridePlan)) : setFetchedPlans(null);
      setIsDoneFetch(true);
    } catch (e) {
      console.error(e);
      setIsFailFetch(true);
    }
  };
  useEffect(() => {
    let isMounted = true;
    if (userID && isMounted) {
      listPlanAndImage();
    } else return;
    return () => (isMounted = false);
  }, [userID]);

  if (isDoneFetch && fetchedPlans) {
    return (
      <div className={styles.cardsBoxRow}>
        {fetchedPlans.map((plan, index) => {
          if ((plan && !limit) || index + 1 <= limit) {
            return (
              <div className={styles.cardBox} key={index}>
                <PlanCard
                  indexOfOpenedMenu={indexOfOpenedMenu}
                  setIndexOfOpenedMenu={setIndexOfOpenedMenu}
                  index={index}
                  planInfo={plan}
                />
              </div>
            );
          } else return null;
        })}
      </div>
    );
  } else if (isDoneFetch && !fetchedPlans) {
    return <Message text={"参加中のプランはありません。"} />;
  } else if (isFailFetch) {
    return <FetchFailed isFailFetch={isFailFetch} listPlanAndImage={listPlanAndImage} />;
  } else {
    return <LoadingIcon />;
  }
};

const FetchFailed = ({ isFailFetch, listPlanAndImage }) => {
  return (
    <div className={styles.fetchConditionBox}>
      {isFailFetch ? (
        <div className={styles.conditionTextBox}>
          <div className={styles.conditionText}> プランの取得に失敗しました。</div>
          <button id="addBlueButton" onClick={listPlanAndImage}>
            再読み込み
          </button>
        </div>
      ) : null}
    </div>
  );
};
const Message = ({ text }) => {
  return (
    <div className={styles.fetchConditionBox}>
      <div className={styles.conditionTextBox}>
        <div className={styles.conditionText}> {text}</div>
      </div>
    </div>
  );
};
