import React, { useState, useContext } from "react";
import { globalUserContext } from "../../globalContext";
import styles from "../../assets/css/auth/editNickName.module.css";
import { Auth } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import { tryUpdateUser } from "../../helper/dbAccess/userAccess";

export const EditNickName = ({ setIsOpenNameEdit }) => {
  const { userInfo } = useContext(globalUserContext);
  const [preferredUserName, setPreferredUserName] = useState("");

  const updatePreferredUserName = async () => {
    if (preferredUserName.trim() === "") return;
    try {
      setIsOpenNameEdit(false);
      //cognitoに登録
      await Auth.updateUserAttributes(userInfo, {
        preferred_username: preferredUserName.trim(),
      });
      //userテーブルに登録
      await tryUpdateUser({ id: userInfo.attributes.sub, nickName: preferredUserName.trim() });
    } catch (err) {
      //ロールバック
      await Auth.updateUserAttributes(userInfo, {
        preferred_username: userInfo.attributes.preferred_username,
      });
      await tryUpdateUser({ id: userInfo.attributes.sub, nickName: preferredUserName.trim() });
      console.error(err);
      setIsOpenNameEdit(false);

      return;
    }
  };
  return (
    <div className={styles.editNickname}>
      <input
        type="text"
        placeholder={
          userInfo?.attributes?.preferred_username ? userInfo.attributes.preferred_username : "ニックネームを変更"
        }
        minLength={1}
        maxLength={20}
        className={styles.usernameInput}
        value={preferredUserName.trim()}
        onChange={(e) => setPreferredUserName(e.target.value.trim())}
      ></input>
      <div className={styles.buttonBox}></div>
      <button
        id="smallGrayButton"
        onClick={() => {
          setPreferredUserName("");
          setIsOpenNameEdit(false);
        }}
      >
        戻る
      </button>
      {preferredUserName.trim() && (
        <button
          id="smallGreenButton"
          onClick={() => {
            setPreferredUserName("");
            updatePreferredUserName();
          }}
        >
          決定
        </button>
      )}
    </div>
  );
};
