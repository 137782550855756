import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import styles from "../../../assets/css/global/backButton.module.css";

export const BackPreviousButton = () => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(-1);
  };

  return (
    <button
      type="button"
      className={styles.backButton}
      onClick={() => {
        onClick();
      }}
    >
      <FontAwesomeIcon icon={["fa", "arrow-left-long"]} className={styles.arrowLeftLong} />
    </button>
  );
};
export const BackButtonUI = ({ onClick }) => {
  return (
    <button type="button" className={styles.backButton} onClick={() => onClick()}>
      <FontAwesomeIcon icon={["fa", "arrow-left-long"]} className={styles.arrowLeftLong} />
    </button>
  );
};

export const BackToURLButton = ({ toURL, state }) => {
  const navigate = useNavigate();
  const onClick = () => {
    navigate(toURL, { state: { state } });
  };

  return (
    <button
      type="button"
      className={styles.backButton}
      onClick={() => {
        onClick();
      }}
    >
      <FontAwesomeIcon icon={["fa", "arrow-left-long"]} className={styles.arrowLeftLong} />
    </button>
  );
};
