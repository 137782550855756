import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useCallback, useEffect, useState, useContext, useRef } from "react";
import { set, useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "../assets/css/createPlan.module.css";
import noImage from "../assets/img/noimage.png";
import { initPlanInfo } from "../constants/initState";
import { RIDE_PURPOSE_LIST, BICYCLE_TYPE_LIST, RIDE_LEVEL, DISCLOSURE_RANGE } from "../constants/planConstants";
import { ItIsRequired } from "../components/commons/messages/Messages";
import { GoogleAutoComplete } from "../components/map/GoogleAutoComplete";
import { globalUserContext } from "../globalContext";
import { LoadingIcon } from "../components/commons/LoadingIcon";
import { handleConfiguring } from "../helper/auth";
import imageCompression from "browser-image-compression";
import { MainImageCompressionOptions } from "../constants/global";
import { Helmet } from "react-helmet-async";
import { InputScheduleInfo } from "../components/createPlan/InputScheduleInfo";
import UUID from "uuidjs";
import { Oval } from "react-loader-spinner";
import { NotFound, NotLogin } from "./ExceptionMessage";
import { BackPreviousButton } from "../components/commons/buttons/BackButton";
import { tryGetRidePlan } from "../helper/dbAccess/planAccess";
import { tryGetS3URL } from "../helper/s3Access";
import { savePlan } from "../helper/savePlan";
import { ConfirmPlan } from "../components/createPlan/ConfirmPlan";
import { motion } from "framer-motion";
import { popUp } from "../helper/animation";

export const CreatePlan = () => {
  const [planInfo, setPlanInfo] = useState({ ...initPlanInfo, scheduleList: [] });
  const [showAddScheduleModal, setShowAddScheduleModal] = useState(false);
  const [showEditScheduleModal, setShowEditScheduleModal] = useState(false);
  const [topImage, setTopImage] = useState({ file: null, url: null });
  const [editMode, setEditMode] = useState(null);
  const [tempSaveStatus, setTempSaveStatus] = useState(true);
  const [S3Keys, setS3Keys] = useState(null);
  const navigate = useNavigate();
  const [areaCenter, setAreaCenter] = useState(null);
  const [routeDataUrl, setRouteDataUrl] = useState(null);
  const [routeKeysWillBeDeleted, setRouteKeysWillBeDeleted] = useState([]);
  const [applyMethodsCount, setApplyMethodsCount] = useState(1);
  const [showSchedulePage, setShowSchedulePage] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [fetchPlanFlag, setFetchPlanFlag] = useState(true);
  const { state, pathname } = useLocation();
  const { authState, userInfo, setAuthState, setAuthMode } = useContext(globalUserContext);
  const { id } = useParams();
  const prefectureRef = useRef(null);
  const cityRef = useRef(null);

  const {
    register,
    handleSubmit,
    reset,
    trigger,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    criteriaMode: "all",
    shouldFocusError: showAddScheduleModal || showEditScheduleModal ? false : true,
  });

  useEffect(() => {
    //ログイン状態かどうか確認
    if (!userInfo && authState === "unauthenticated") {
      setAuthMode("signIn");
      navigate("/");
      return;
    } else if (authState === "configuring") {
      handleConfiguring(setAuthState, authState);
    } else return;
  }, [authState, userInfo]);

  //バリデーションをリセット
  useEffect(() => {
    if (!form) return;
    //enterボタンを無効化
    form.onkeydown = (e) => {
      let activeTextarea = document.activeElement;
      //タグがtextareaの場合はenterを許容する
      if (activeTextarea.tagName !== "TEXTAREA" && e.key === "Enter") {
        e.preventDefault();
      }
    };
    if (!planInfo) return;
    reset(planInfo);
  }, [planInfo, reset]);

  //1：プラン情報の再セット(アドレスにidがある場合,DBからプラン情報を取得）
  useEffect(() => {
    const fetchPlanInfo = async () => {
      try {
        const plan = await tryGetRidePlan(id);
        if (!plan) {
          console.error("Plan is not found.");
          return;
        }
        // 1.プラン情報をセット
        setPlanInfo(plan);
        navigate(pathname, { replace: true });
        let imageKeyList = []; //S3Keyを一括管理用
        // メイン画像をセット
        if (plan.image) {
          const topImageKey = plan.image;
          const imageUrl = await tryGetS3URL(topImageKey);
          setTopImage({ url: imageUrl, file: null });
          imageKeyList[0] = topImageKey;
        } else {
          imageKeyList.push("");
        }
        //  スケジュール画像をセット
        if (plan.scheduleList && plan.scheduleList.length > 0) {
          const scheduleList = plan.scheduleList;
          const scheduleImages = plan.scheduleImages ? plan.scheduleImages : [];
          const processSchedule = async (schedule, i) => {
            const uuid = UUID.generate();
            //スケジュール画像のkeyを取得
            const scheduleImgKey = scheduleImages[i] ? scheduleImages[i] : null;
            if (!scheduleImgKey) {
              scheduleList[i] = {
                ...schedule,
                image: { url: null, file: null, id: uuid },
              };
              imageKeyList.push("");
            } else {
              //スケジュール画像のURLを取得
              const imageUrl = await tryGetS3URL(scheduleImgKey);
              scheduleList[i] = {
                ...schedule,
                image: { url: imageUrl, file: null, id: uuid },
              };
              imageKeyList.push(scheduleImgKey);
            }
          };

          //スケジュール画像を一つ一つ取得
          for (let i = 0; scheduleList.length > i; i++) {
            await processSchedule(scheduleList[i], i);
          }
          //画像情報付きのスケジュールをセットmemo:登録時には画像情報はdeleteされる
          setPlanInfo({ ...plan, scheduleList: scheduleList });
        }
        setS3Keys(imageKeyList); //メイン画像とスケジュール画像のS3Keyをセット
      } catch (e) {
        console.error(e);
      }
    };
    const initializeStates = () => {
      setPlanInfo({ ...initPlanInfo, scheduleList: [] });
      setTopImage({ file: null, url: null });
      setEditMode(null);
      setTempSaveStatus(false);
      setS3Keys(null);
      setAreaCenter(null);
      setRouteDataUrl(null);
      setRouteKeysWillBeDeleted([]);
      setApplyMethodsCount(1);
    };
    //navigateのstateがない場合は初期化
    if (state) return; //ブラウザバックキャンセル時はstateがあるので、ここで処理を終了⇒2：プラン情報(state)の再セット
    if (fetchPlanFlag) {
      if (id) {
        //idがある場合はDBからプラン情報を取得
        fetchPlanInfo();
      } else {
        initializeStates(); //idがない場合はuseState初期化
      }
      setFetchPlanFlag(false); //fetchPlanInfoを連続で実行しないようにする
    }
  }, [fetchPlanFlag]);

  //2：プラン情報(state)の再セット(ブラウザバックキャンセル時のみ)
  useEffect(() => {
    if (!state) return;
    try {
      const { planInfo } = state.state;
      if (!planInfo) return;
      //1.バリデーションのリセット
      reset(planInfo);
      //2.プラン情報を更新
      setPlanInfo(planInfo);
      //3.routerのstateをリセット
      navigate(pathname, { replace: true });
    } catch (e) {
      console.error(e);
    }
  }, [state]);

  // 都道府県、市区町村の再セット
  useEffect(() => {
    if (!planInfo) return;
    if (planInfo.prefecture && planInfo.city) {
      prefectureRef.current.value = planInfo.prefecture;
      cityRef.current.value = planInfo.city;
    }
  }, [planInfo.prefecture, planInfo.city]);

  //3:ページ遷移後に画像とその他情報を再セット
  useEffect(() => {
    if (!state?.state) return;
    const { files, imgUrls, S3Keys, planInfo, topImage, routeKeysWillBeDeleted, tempSaveStatus } = state?.state;
    //編集モードの時に変更前の画像データとしてS3Keyを保管
    if (S3Keys) setS3Keys(S3Keys);
    // 古いルートデータを再セット
    if (routeKeysWillBeDeleted) setRouteKeysWillBeDeleted(routeKeysWillBeDeleted);
    //保存状態を再セット
    setTempSaveStatus(tempSaveStatus);
    //routerのstateをリセット
    navigate(pathname, { replace: true });

    //  TOP画像のセット（編集モードまたは確認画面から戻ってきたとき）
    let topImageURL = "";
    try {
      // メイン画像のセット
      //1.1 ファイルがある場合
      if (files && files[0] && files.length > 0) {
        topImageURL = URL.createObjectURL(files[0]);
        setTopImage({ url: topImageURL, file: files[0] });
      } else if (files && files[0] === "") {
        // ×で削除した場合
        setTopImage({ url: "", file: "" });
      }
      //1.2 URLがある場合
      else if (imgUrls && imgUrls[0] && imgUrls.length > 0) {
        topImageURL = imgUrls[0];
        setTopImage({ url: topImageURL, file: null });
      }
      // 登録エラーで戻ってきた時
      else if (topImage) {
        topImageURL = topImage.url;
        setTopImage({ url: topImageURL ? topImageURL : null, file: topImage.file ? topImage.file : null });
      } else {
        setTopImage({ url: null, file: null });
      }
    } catch (e) {
      console.error(e);
    }
    //スケジュール画像を一つ一つ再セット
    if (planInfo && planInfo.scheduleList.length > 0) {
      const scheduleList = planInfo.scheduleList;
      planInfo.scheduleList.forEach((schedule, i) => {
        //1.1 ファイルがある場合(新規画像追加)
        if (files && files[i + 1]) {
          scheduleList[i] = {
            ...schedule,
            image: { url: null, file: files[i + 1], id: schedule.image.id },
          };
        } else if (files && files[i + 1] === "") {
          // 1.2 ×で削除した場合
          scheduleList[i] = {
            ...schedule,
            image: { url: "", file: "", id: schedule.image.id },
          };
        } else if (imgUrls && imgUrls[i + 1] && schedule.image) {
          //1.3 URLがある場合(編集モードまたは確認画面から戻ってきたとき)
          scheduleList[i] = {
            ...schedule,
            image: { url: imgUrls[i + 1], file: null, id: schedule.image.id },
          };
        } else {
          //画像なし
          if (schedule.image && schedule.image.id) {
            //スケジュール画像無い場合はidのみ設定
            scheduleList[i] = {
              ...schedule,
              image: { url: null, file: null, id: schedule.image.id },
            };
          }
        }
        setPlanInfo({ ...planInfo, scheduleList: scheduleList });
      });
    } else {
      return;
    }
  }, [state]);

  // //TOP画像キャンセル×の表示切替
  const closeBtn = document.getElementById("closeBtn");
  useEffect(() => {
    if (!closeBtn) return;
    if (topImage.url || topImage.file) {
      closeBtn.style.visibility = "visible";
    } else {
      closeBtn.style.visibility = "hidden";
    }
  }, [topImage, closeBtn]);

  //編集モードの有無を管理
  useEffect(() => {
    if (!planInfo) return;
    if (planInfo.isTemp) {
      //下書きモードの場合
      setEditMode("temp");
    } else if (id) {
      //編集モードの場合
      setEditMode(planInfo.disclosureRange);
    } else {
      //新規
      setEditMode(null);
    }
  }, [planInfo, id]);

  //リロード、ブラウザクローズの時にアラートを出す
  useEffect(() => {
    if (!planInfo) return;
    // 最新状態をローカル変数で保持
    const cachedTopImage = topImage;
    const cachedS3Keys = S3Keys;
    const cachedPlanInfo = planInfo;
    const cachedRouteKeysWillBeDeleted = routeKeysWillBeDeleted;
    const cachedTempSaveStatus = tempSaveStatus;

    //リロード時,ブラウザクローズ時にアラートを出す
    const handleWindowReload = async (e) => {
      const message = "入力内容が保存されない可能性があります。ページを離れますか？";
      e.preventDefault();
      e.returnValue = message;
    };

    //ブラウザバック時
    const handleBrowserBack = async (e) => {
      e.preventDefault();
      if (window.confirm("入力内容が保存されない可能性があります。ページを離れますか？")) {
        //OK
        setFetchPlanFlag(true); //fetchPlanInfoを実行するようにする
        return;
      } else {
        //キャンセル
        setFetchPlanFlag(false);
        let fileList = [];
        let urlList = [];
        // メイン画像のfileとurlをセット
        if (cachedTopImage.file) {
          fileList.push(cachedTopImage.file);
        }
        if (cachedTopImage.url) {
          urlList.push(cachedTopImage.url);
        }
        // スケジュール画像のfileとurlをセット
        if (cachedPlanInfo.scheduleList && cachedPlanInfo.scheduleList.length > 0) {
          cachedPlanInfo.scheduleList.forEach((schedule) => {
            if (schedule.image.file) {
              fileList.push(schedule.image.file);
            }
            if (schedule.image.url) {
              urlList.push(schedule.image.url);
            }
          });
        }
        const state = {
          planInfo: cachedPlanInfo,
          files: fileList,
          imgUrls: urlList,
          S3Keys: cachedS3Keys,
          routeKeysWillBeDeleted: cachedRouteKeysWillBeDeleted,
          tempSaveStatus: cachedTempSaveStatus,
        };

        if (cachedPlanInfo.id) {
          navigate("/create-plan/" + cachedPlanInfo.id, { state: { state } });
        } else {
          navigate("/create-plan", { state: { state } });
        }
      }
    };

    window.addEventListener("beforeunload", handleWindowReload);
    window.addEventListener("popstate", handleBrowserBack);
    return () => {
      window.removeEventListener("beforeunload", handleWindowReload);
      window.removeEventListener("popstate", handleBrowserBack);
    };
  }, [navigate, topImage, S3Keys, planInfo, userInfo, editMode, routeKeysWillBeDeleted, tempSaveStatus]);

  //ログイン状態の監視
  useEffect(() => {
    if (!userInfo && authState === "unauthenticated") {
      setAuthMode("signIn");
      return;
    } else if (authState === "configuring") {
      handleConfiguring(setAuthState, authState);
    } else return;
  }, [authState, userInfo]);

  let form = document.getElementById("planForm");
  useEffect(() => {
    //enterボタンを無効化
    if (!form) return;

    form.onkeydown = (e) => {
      let activeTextarea = document.activeElement;
      //タグがtextareaの場合はenterを許容する
      if (activeTextarea.tagName !== "TEXTAREA" && e.key === "Enter") {
        e.preventDefault();
      }
    };
  }, [form]);

  // 下書き失敗時のUI処理
  useEffect(() => {
    if (tempSaveStatus === "error") {
      setTimeout(function () {
        //一定時間後に戻す
        setTempSaveStatus(false);
      }, 1 * 2000);
      console.error("temp save error");
    } else return;
  }, [tempSaveStatus]);

  useEffect(() => {
    if (!planInfo || !planInfo.applyMethods) return;
    //申し込み手順の数の同期
    if (planInfo.applyMethods.length === 0) {
      setApplyMethodsCount(1);
    } else {
      setApplyMethodsCount(planInfo.applyMethods.length);
    }
  }, [planInfo.applyMethods]);

  //プレビュ―モーダル表示時とスケジュール入力画面表示時にスクロールを無効化
  useEffect(() => {
    if (showPreview || showSchedulePage) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "scroll";
    }
  }, [showPreview, showSchedulePage]);

  const handleFileSelect = async (event) => {
    setTempSaveStatus(false);
    //TOP画像のセット
    const closeBtn = document.getElementById("closeBtn");
    // 古いURLを無効化
    topImage.url && URL.revokeObjectURL(topImage.url);
    const imageFile = event.target.files[0];
    //画像サイズの圧縮

    try {
      const compressedFile = await imageCompression(imageFile, MainImageCompressionOptions);
      const imageUrl = URL.createObjectURL(compressedFile);
      if (!imageUrl) {
        setTopImage({ file: compressedFile, url: null });
      } else {
        //画像表示
        setTopImage({ file: compressedFile, url: imageUrl });
        closeBtn.style.visibility = "visible";
      }
    } catch (e) {
      console.error(e);
    }
    // }
  };

  function topImgCancel() {
    setTempSaveStatus(false);
    topImage.url && URL.revokeObjectURL(topImage.url);
    const topImageEle = document.getElementById("topImage");
    const closeBtn = document.getElementById("closeBtn");
    closeBtn.style.visibility = "hidden";
    setTopImage({ file: "", url: "" });
    topImageEle.src = noImage;
  }

  const handleTextChange = useCallback(
    (e) => {
      setTempSaveStatus(false);
      const { name, value } = e.target;
      setPlanInfo({ ...planInfo, [name]: value });
    },
    [planInfo]
  );

  const handleDateChange = useCallback(
    (e) => {
      setTempSaveStatus(false);
      const { name, value } = e.target;
      value === "" ? setPlanInfo({ ...planInfo, [name]: null }) : setPlanInfo({ ...planInfo, [name]: value });
    },
    [planInfo]
  );

  const handleArrayChange = (index, e, prevList) => {
    setTempSaveStatus(false);
    const { name, value } = e.target;
    const newApplyMethodList = prevList ? [...prevList] : [];
    newApplyMethodList[index] = value;
    setPlanInfo({ ...planInfo, [name]: newApplyMethodList });
  };

  const handleDeleteArrayInput = (index, prevList) => {
    setTempSaveStatus(false);
    const newApplyMethodList = prevList ? [...prevList] : [];
    if (newApplyMethodList.length === 0) return;
    newApplyMethodList.splice(index, 1);
    setPlanInfo({ ...planInfo, applyMethods: newApplyMethodList });
  };

  const handleAddInput = () => {
    setTempSaveStatus(false);
    if (applyMethodsCount < 5) {
      setApplyMethodsCount(applyMethodsCount + 1);
    }
  };

  const handleCheckboxChange = useCallback(
    (e, prevList) => {
      setTempSaveStatus(false);
      const { name, value } = e.target;
      if (!prevList) {
        //DBから取ってきた場合に[]はnullとなるので、[]を入れてエラーを回避
        prevList = [];
      }
      const index = prevList.indexOf(value);
      if (index === -1) {
        // if same value is not found チェックを入れる
        setPlanInfo({ ...planInfo, [name]: [...prevList, value] });
      } else {
        // if same value is found チェックを外す
        prevList.splice(index, 1);
        setPlanInfo({ ...planInfo, [name]: prevList });
      }
    },
    [planInfo]
  );

  const handleRadioChange = useCallback(
    (e) => {
      setTempSaveStatus(false);
      const { name, value } = e.target;
      setPlanInfo({ ...planInfo, [name]: value ? value : "" });
    },
    [planInfo]
  );
  const afterGetAutoComp = (place, setSearchText, setSearchResult) => {
    // ジオコーディング結果を用いた処理
    if (!place.address_components) {
      setPlanInfo({
        ...planInfo,
        prefecture: place.name,
      });
      return;
    }
    //都道府県を抽出
    const prefecture = place.address_components.filter((component) => {
      if (component.types.indexOf("administrative_area_level_1") > -1) {
        return component;
      } else {
        return "";
      }
    });
    //市を抽出
    const city = place.address_components.filter((component) => {
      if (component.types.indexOf("locality") > -1) {
        return component;
      } else if (component.types.indexOf("administrative_area_level_2") > -1) {
        return component;
      } else return "";
    });
    //区町村を抽出
    const sublocality = place.address_components.filter((component) => {
      if (component.types.indexOf("sublocality_level_1") > -1) {
        return component;
      } else return "";
    });

    let prefectureValue = "";
    let cityValue = "";

    // 地名の準備 [0],longnameとか付ける
    prefectureValue = prefecture.length && prefecture[0].long_name ? prefecture[0].long_name : "";
    if (city[0] && sublocality[0]) {
      cityValue = `${city[0].long_name} ${sublocality[0].long_name}`;
    } else if (city[0]) {
      cityValue = city[0].long_name;
    } else {
      cityValue = "";
    }
    //inputのvalueに値をセット
    prefectureRef.current.value = prefectureValue;
    cityRef.current.value = cityValue;
    //プラン情報を更新
    setPlanInfo({
      ...planInfo,
      prefecture: prefectureValue ? prefectureValue : "",
      city: cityValue ? cityValue : "",
    });
    setTempSaveStatus(false);
    //検索結果表示の更新
    setSearchResult(`${prefectureValue ? prefectureValue : ""} ${cityValue ? cityValue : ""}`);
    setSearchText("");
  };

  const callSavePlan = async (isTempSave) => {
    try {
      //プラン情報をDBに保存
      const savedPlanId = await savePlan(
        topImage,
        S3Keys,
        setS3Keys,
        planInfo,
        userInfo,
        editMode,
        setRouteKeysWillBeDeleted,
        routeKeysWillBeDeleted,
        isTempSave
      );

      return savedPlanId;
    } catch (e) {
      console.error(e);
    }
  };

  //下書き保存
  const registerAsTemp = async () => {
    //公開済みの場合
    if (!planInfo.isTemp && (editMode === DISCLOSURE_RANGE.PUBLIC || editMode === DISCLOSURE_RANGE.PRIVATE)) {
      if (!window.confirm("公開を取り下げて、下書き保存に戻します。")) {
        setTempSaveStatus(false);
        return;
      }
    }
    setTempSaveStatus("saving");

    if (!userInfo) {
      console.error("User is not found.");
      setTempSaveStatus("error");
      return;
    }
    const isTempSave = true;
    try {
      const newPlanId = await callSavePlan(isTempSave);
      setPlanInfo({ ...planInfo, id: newPlanId, isTemp: true });
      setTempSaveStatus(true);
    } catch (e) {
      console.error(e);
      setTempSaveStatus("error");
    } finally {
    }
  };

  //プラン登録
  const onSubmit = (e) => {
    const button = document.getElementById("grayButton");
    button.disabled = true;
    registerAsTemp();
    button.disabled = false;
  };

  const TempSaveButton = ({ LoadingIconSize }) => {
    if (planInfo.title) {
      if (tempSaveStatus === "error") {
        return <div className={styles.tempSaveError}>下書き失敗</div>;
      } else if (tempSaveStatus === false) {
        return (
          <button type="submit" className={styles.tempSaveButton} id="grayButton">
            下書き保存
          </button>
        );
      } else if (tempSaveStatus === true) {
        return (
          <motion.div variants={popUp} initial="hidden" animate="visible" exit="exit" className={styles.tempSaveDone}>
            <FontAwesomeIcon icon={["fa", "fa-check"]} className={styles.faCheck} />
            保存済み
          </motion.div>
        );
      } else if (tempSaveStatus === "saving") {
        return (
          <div className={styles.tempSaveLoading}>
            <Oval
              color="#111111"
              secondaryColor="#ffffff00"
              height={LoadingIconSize}
              width={LoadingIconSize}
              speedMultiplier={2}
            />
          </div>
        );
      } else {
        return <div className={styles.tempSaveButton}></div>;
      }
    } else {
      return <div className={styles.tempSaveButton}></div>;
    }
  };

  if (authState === "unauthenticated") {
    return (
      <div id="container">
        <NotLogin />
      </div>
    );
  } else if (userInfo && planInfo && authState === "authenticated") {
    //プラン作成者以外の場合は404ページを表示
    if (planInfo.creator && planInfo.creator !== userInfo.attributes.sub) {
      return (
        <div id="container">
          <NotFound />
        </div>
      );
    } else if ((!id && !planInfo.creator) || (id && planInfo.creator === userInfo.attributes.sub)) {
      return (
        <div id="container">
          <Helmet>
            <title>プランをつくる | pobicle / ポビクル</title>
            <meta
              charSet="utf-8"
              name="プランをつくる"
              description="「プランをつくる」自分だけの小さな旅のプランを作ってみんなに公開しよう！ - pobicle(ポビクル)"
            />
            <meta property="og:url" content="https://www.pobicle.com/create-plan" />
            <meta property="og:type" content="website" />
            <meta
              property="og:title"
              content="プランをつくる|休日の小さな旅のプランをさがす・つくる・走りに行く - ポビクル - pobicle"
            />
            <meta property="og:site_name" content="pobicle" />
            <meta property="og:image" content="%PUBLIC_URL%/logowithslogan.png" />
            <meta property="og:image:width" content="600" />
            <meta property="og:image:height" content="300" />
            <link rel="canonical" href="https://www.pobicle.com/create-plan" />
          </Helmet>
          {/* 確認画面(プレビュー) */}
          {showPreview && (
            <ConfirmPlan
              planInfo={planInfo}
              setPlanInfo={setPlanInfo}
              topImage={topImage}
              S3Keys={S3Keys}
              editMode={editMode}
              areaCenter={areaCenter}
              setAreaCenter={setAreaCenter}
              routeDataUrl={routeDataUrl}
              setRouteDataUrl={setRouteDataUrl}
              routeKeysWillBeDeleted={routeKeysWillBeDeleted}
              setShowPreview={setShowPreview}
              showPreview={showPreview}
            />
          )}
          <div className={styles.forms}>
            <form onSubmit={handleSubmit(onSubmit)} id="planForm">
              <div id="inputPlanBox"></div>
              <div className={styles.progressHeaderBox}>
                <div className={styles.progressHeader}>
                  {showSchedulePage ? (
                    <div
                      className={styles.progressBackButton}
                      onClick={() => {
                        setShowSchedulePage(false);
                      }}
                    >
                      <FontAwesomeIcon icon={["fa", "angle-left"]} className="faAngle" />
                      <p>基本情報入力へ</p>
                    </div>
                  ) : (
                    <BackPreviousButton />
                  )}

                  <div className={styles.progressForwardButtonBox}>
                    <TempSaveButton LoadingIconSize={35} />
                    {showSchedulePage ? (
                      <button
                        type="button"
                        onClick={async () => {
                          //todo バリデーション
                          if (await trigger()) {
                            setShowPreview(true);
                          }
                        }}
                        id="defaultButton"
                        className={styles.forwardButton}
                      >
                        確認画面へ
                        <FontAwesomeIcon icon={["fa", "angle-right"]} className="faAngle" />
                      </button>
                    ) : (
                      <button type="button" onClick={() => setShowSchedulePage(true)} className={styles.forwardButton}>
                        スケジュール入力へ
                        <FontAwesomeIcon icon={["fa", "angle-right"]} className="faAngle" />
                      </button>
                    )}
                  </div>
                </div>
              </div>
              {showSchedulePage ? (
                <InputScheduleInfo
                  showSchedulePage={showSchedulePage}
                  planInfo={planInfo}
                  setPlanInfo={setPlanInfo}
                  S3Keys={S3Keys}
                  setS3Keys={setS3Keys}
                  showEditScheduleModal={showEditScheduleModal}
                  setShowEditScheduleModal={setShowEditScheduleModal}
                  showAddScheduleModal={showAddScheduleModal}
                  setShowAddScheduleModal={setShowAddScheduleModal}
                  areaCenter={areaCenter}
                  setAreaCenter={setAreaCenter}
                  routeDataUrl={routeDataUrl}
                  setRouteDataUrl={setRouteDataUrl}
                  routeKeysWillBeDeleted={routeKeysWillBeDeleted}
                  setRouteKeysWillBeDeleted={setRouteKeysWillBeDeleted}
                  setTempSaveStatus={setTempSaveStatus}
                  showPreview={showPreview}
                />
              ) : (
                <div className={styles.stickyProgressBarBox}>
                  <div className={styles.progressBarBox}>
                    <button
                      type="button"
                      className={styles.progressNavTitle}
                      onClick={() => {
                        document
                          .getElementById("inputPlanBox")
                          .scrollIntoView({ behavior: "auto", block: "start", inline: "nearest" });
                        setShowSchedulePage(false);
                      }}
                    >
                      <div className={styles.numberCircle}>1</div>
                      <p>基本情報を入力する</p>
                    </button>
                    <button type="button" onClick={() => setShowSchedulePage(true)} className={styles.progressNavTitle}>
                      <div className={styles.numberCircle}>2</div>
                      <p>スケジュールを入力する</p>
                    </button>
                  </div>
                </div>
              )}

              <div className={styles.inputBasicInfoBox}>
                <h1 className={styles.pageTitle}>プランをつくる</h1>
                <div className={styles.planName}>
                  <h3 className={styles.inputTitle}>
                    プラン名&ensp;
                    <FontAwesomeIcon icon={["fas", "fa-asterisk"]} className={styles.asterisk} />
                  </h3>
                  <p className={styles.inputCaption}>
                    どこで、いつ、何をする？プランのコンセプトが分かりやすい題名を付けよう
                  </p>
                  <div>
                    <input
                      {...register("title", {
                        required: true,
                        pattern: { value: /\S+/ },
                      })}
                      type="text"
                      name="title"
                      maxLength="100"
                      placeholder="プラン名を入力してください"
                      value={planInfo.title}
                      onChange={handleTextChange}
                    />
                  </div>
                  {errors.title && <ItIsRequired />}
                </div>
                {planInfo.originalPlanId &&
                  (planInfo.originalPlan ? (
                    <span className={styles.originalPlan}>
                      このプランは
                      <Link to={`/find-plan/${planInfo.originalPlan.id}`}>{planInfo.originalPlan.title}</Link>
                      を基に作成されました。
                    </span>
                  ) : (
                    <span>このプランの基となったプランは既に削除済みです。</span>
                  ))}
                <div className={styles.disclosure}>
                  <h3 className={styles.inputSubTitle}>
                    公開設定&ensp;
                    <FontAwesomeIcon icon={["fas", "fa-asterisk"]} className={styles.asterisk} />
                  </h3>
                  <div id={styles.disclosureCheckBox} className={styles.checkBox}>
                    <label htmlFor="public">
                      <input
                        id="public"
                        type="radio"
                        name="disclosureRange"
                        value={DISCLOSURE_RANGE.PUBLIC}
                        checked={planInfo.disclosureRange === DISCLOSURE_RANGE.PUBLIC}
                        onChange={handleRadioChange}
                      />
                      公開 (全員に公開)
                    </label>
                    <label htmlFor="private">
                      <input
                        id="private"
                        type="radio"
                        name="disclosureRange"
                        value={DISCLOSURE_RANGE.PRIVATE}
                        checked={planInfo.disclosureRange === DISCLOSURE_RANGE.PRIVATE}
                        onChange={handleRadioChange}
                      />
                      限定公開 (URLを知っている人のみに公開)
                    </label>
                  </div>
                </div>
                <div className={styles.ridePurpose}>
                  <h3 className={styles.inputSubTitle}>旅の目的</h3>
                  <div className={styles.pottering}>
                    <div className={styles.checkBox}>
                      {RIDE_PURPOSE_LIST.length > 0 &&
                        RIDE_PURPOSE_LIST.map((purpose, index) => (
                          <label key={index} htmlFor={`purpose-${index}`}>
                            <input
                              id={`purpose-${index}`}
                              type="checkbox"
                              name="purpose"
                              value={RIDE_PURPOSE_LIST[index]}
                              checked={planInfo.purpose?.includes(RIDE_PURPOSE_LIST[index])}
                              onChange={(e) => handleCheckboxChange(e, planInfo.purpose)}
                            />
                            {purpose}
                          </label>
                        ))}
                    </div>
                  </div>
                </div>
                <div className={styles.uploadImageBox}>
                  <div className={styles.topImageTitleBox}>
                    <h3 className={styles.inputTitle}>トップ画像</h3>
                    <button id="closeBtn" type="button" className={styles.closeBtn} onClick={topImgCancel}>
                      <FontAwesomeIcon icon={["fa", "fa-times"]} />
                    </button>
                  </div>

                  <div className={styles.topImgBox}>
                    {topImage.url ? (
                      <img src={topImage.url} alt="topImage" id="topImage" />
                    ) : (
                      <label className={styles.fileUpload}>
                        <input
                          type="file"
                          accept=".png, .jpeg, .jpg"
                          onClick={(e) => {
                            e.target.value = "";
                          }}
                          onChange={handleFileSelect}
                          id="inputImage"
                        ></input>
                      </label>
                    )}
                  </div>
                  <div className={styles.uploadButtonBox}>
                    <label id="addBlueButton" className={styles.uploadImageButton}>
                      画像をアップロード <FontAwesomeIcon icon={["fa", "plus"]} className={styles.faPlus} />
                      <input
                        type="file"
                        accept=".png, .jpeg, .jpg"
                        onClick={(e) => {
                          e.target.value = "";
                        }}
                        onChange={handleFileSelect}
                        id="inputImage"
                      ></input>
                    </label>
                  </div>
                </div>

                <div className={styles.planDescription}>
                  <h3 className={styles.inputTitle}>プラン説明</h3>
                  <p className={styles.inputCaption}>
                    プランのみどころ、ストーリー、こんな人に走ってほしい！など、見にきてくれた人に伝えてみよう。
                  </p>
                  <div>
                    <textarea
                      type="text"
                      name="description"
                      maxLength="1000"
                      placeholder="頭の中のアイデアを書いてみよう"
                      value={planInfo.description ? planInfo.description : ""}
                      onChange={handleTextChange}
                    />
                  </div>
                </div>

                <div className={styles.place}>
                  <h3 className={styles.inputTitle}>地域</h3>
                  <p className={styles.inputCaption}>
                    ※目的地の名称検索で自動入力ができます。
                    <br />
                  </p>
                  <div className={styles.regionSearchBox}>
                    <GoogleAutoComplete
                      afterGetAutoComp={afterGetAutoComp}
                      placeholder=" 浜松市、○○カフェ"
                      inputValue={`${planInfo.prefecture ? planInfo.prefecture : ""} ${
                        planInfo.city ? planInfo.city : ""
                      }`}
                    />
                  </div>
                  <div className={styles.prefecture}>
                    <h4 className={styles.inputSubTitle}>都道府県 &ensp;</h4>
                    <div>
                      <input
                        type="text"
                        name="prefecture"
                        ref={prefectureRef}
                        maxLength="100"
                        placeholder="都道府県を入力してください"
                        onBlur={handleTextChange}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.place}>
                  <h3 className={styles.inputTitle}>地域</h3>
                  <p className={styles.inputCaption}>
                    ※目的地の名称検索で自動入力ができます。
                    <br />
                  </p>
                  <div className={styles.regionSearchBox}>
                    <GoogleAutoComplete
                      afterGetAutoComp={afterGetAutoComp}
                      placeholder=" 浜松市、○○カフェ"
                      inputValue={`${planInfo.prefecture ? planInfo.prefecture : ""} ${
                        planInfo.city ? planInfo.city : ""
                      }`}
                    />
                  </div>
                  <div className={styles.prefecture}>
                    <h4 className={styles.inputSubTitle}>都道府県 &ensp;</h4>
                    <div>
                      <input
                        type="text"
                        name="prefecture"
                        ref={prefectureRef}
                        maxLength="100"
                        placeholder="都道府県を入力してください"
                        onBlur={handleTextChange}
                      />
                    </div>
                  </div>
                  <div className={styles.city}>
                    <h4 className={styles.inputSubTitle}>市区町村 &ensp;</h4>
                    <div>
                      <input
                        type="text"
                        name="city"
                        ref={cityRef}
                        maxLength="100"
                        placeholder="市区町村を入力してください"
                        onBlur={handleTextChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="plan-additionalInfo">
                  <div className={styles.inputOthersBox}>
                    <div className={styles.subtitleBox}>
                      <h2 className={styles.subtitle}>追加情報</h2>
                      <p className={styles.inputCaption}>あなたのプランに合わせて追加情報を記入しよう。</p>
                    </div>
                    <div
                      className={styles.toggleFormLabel}
                      onClick={() => setPlanInfo({ ...planInfo, isCycling: !planInfo.isCycling })}
                    >
                      <h4>サイクリングをする方</h4>
                      {/* 下矢印 */}
                      <FontAwesomeIcon
                        icon={["fa", "angle-right"]}
                        className={styles.toggleIcon}
                        style={{ transform: planInfo.isCycling ? "rotate(90deg)" : "rotate(0)" }}
                      />
                    </div>

                    <div
                      className={styles.formContainer}
                      style={{
                        height: planInfo.isCycling ? "400px" : "0",
                        opacity: planInfo.isCycling ? "1" : "0",
                      }}
                    >
                      <div className={styles.rideLevel}>
                        <h4 className={styles.inputSubTitle}>ライドのレベル</h4>
                        <div className={styles.checkBox}>
                          <label htmlFor="beginner">
                            <input
                              id="beginner"
                              type="radio"
                              name="rideLevel"
                              value={RIDE_LEVEL.beginner}
                              checked={planInfo.rideLevel === RIDE_LEVEL.beginner}
                              onChange={handleRadioChange}
                            />
                            初心者向け
                          </label>
                          <label htmlFor="intermediate">
                            <input
                              id="intermediate"
                              type="radio"
                              name="rideLevel"
                              value={RIDE_LEVEL.intermediate}
                              checked={planInfo.rideLevel === RIDE_LEVEL.intermediate}
                              onChange={handleRadioChange}
                            />
                            中級者向け
                          </label>
                          <label htmlFor="advanced">
                            <input
                              id="advanced"
                              type="radio"
                              name="rideLevel"
                              value={RIDE_LEVEL.advanced}
                              checked={planInfo.rideLevel === RIDE_LEVEL.advanced}
                              onChange={handleRadioChange}
                            />
                            上級者向け
                          </label>
                          <label htmlFor="noData">
                            <input
                              id="noData"
                              type="radio"
                              name="rideLevel"
                              value={""}
                              checked={planInfo.rideLevel === "" || planInfo.rideLevel === null}
                              onChange={handleRadioChange}
                            />
                            設定しない
                          </label>
                        </div>
                      </div>
                      <div className={styles.bicycleType}>
                        <h4 className={styles.inputSubTitle}>自転車の種類（複数選択可）</h4>
                        <div className={styles.checkBox}>
                          {BICYCLE_TYPE_LIST.length > 0 &&
                            BICYCLE_TYPE_LIST.map((type, index) => (
                              <label key={index} htmlFor={`bicycle-type-${index}`}>
                                <input
                                  id={`bicycle-type-${index}`}
                                  type="checkbox"
                                  name="bicycleType"
                                  value={BICYCLE_TYPE_LIST[index]}
                                  checked={planInfo.bicycleType?.includes(BICYCLE_TYPE_LIST[index])}
                                  onChange={(e) => handleCheckboxChange(e, planInfo.bicycleType)}
                                />
                                {type}
                              </label>
                            ))}
                        </div>
                      </div>
                    </div>

                    <div
                      className={styles.toggleFormLabel}
                      onClick={() => setPlanInfo({ ...planInfo, isTour: !planInfo.isTour })}
                    >
                      <h4> ツアーを企画する方</h4>
                      {/* 下矢印 */}
                      <FontAwesomeIcon
                        icon={["fa", "angle-right"]}
                        className={styles.toggleIcon}
                        style={{ transform: planInfo.isTour ? "rotate(90deg)" : "rotate(0)" }}
                      />
                    </div>
                    <div
                      className={styles.formContainer}
                      style={{ height: planInfo.isTour ? "auto" : "0", opacity: planInfo.isTour ? "1" : "0" }}
                    >
                      <div className={styles.date}>
                        <h4 className={styles.inputTitle}>実施日</h4>
                        <input type="date" name="date" value={planInfo.date} onChange={handleDateChange} />
                      </div>
                      <div className={styles.deadline}>
                        <h4 className={styles.inputTitle}>申込締め切り</h4>
                        <div>
                          <input
                            type="datetime-local"
                            name="deadline"
                            value={planInfo.deadline}
                            onChange={handleDateChange}
                          />
                        </div>
                      </div>
                      <div className={styles.maxParticipants}>
                        <h4 className={styles.inputTitle}>定員</h4>
                        <div>
                          <input
                            type="number"
                            name="maxParticipants"
                            maxLength="7"
                            min={0}
                            placeholder="0"
                            value={planInfo.maxParticipants}
                            onChange={handleTextChange}
                          />
                        </div>
                      </div>
                      <div className={styles.minParticipants}>
                        <h4 className={styles.inputTitle}>最小催行人数</h4>
                        <div>
                          <input
                            type="number"
                            name="minParticipants"
                            maxLength="7"
                            min={0}
                            placeholder="0"
                            value={planInfo.minParticipants}
                            onChange={handleTextChange}
                          />
                        </div>
                      </div>
                      <div className={styles.belongings}>
                        <h4 className={styles.inputTitle}>持ち物</h4>
                        <div>
                          <input
                            type="text"
                            name="belongings"
                            maxLength="1000"
                            placeholder="タオル、サングラス、服装等"
                            value={planInfo.belongings}
                            onChange={handleTextChange}
                          />
                        </div>
                      </div>
                      <div className={styles.price}>
                        <h4 className={styles.inputTitle}>参加費</h4>
                        <div>
                          <input
                            type="number"
                            name="price"
                            maxLength="7"
                            placeholder="金額を入力"
                            value={planInfo.price}
                            onChange={handleTextChange}
                          />
                        </div>
                      </div>
                      <div className={styles.notes}>
                        <h4 className={styles.inputTitle}>注意事項</h4>
                        <p className={styles.inputCaption}>ツアー実施前に参加者に伝えたいことを記入してください。</p>
                        <div>
                          <textarea
                            type="text"
                            name="notes"
                            maxLength="1000"
                            placeholder="参加者へのメッセージを入力してください。"
                            value={planInfo.notes ? planInfo.notes : ""}
                            onChange={handleTextChange}
                          />
                        </div>
                      </div>
                      <div className={styles.applyMethods}>
                        <h4 className={styles.inputTitle}>お申し込み手順</h4>
                        {Array.from({ length: applyMethodsCount }).map((_, index) => (
                          <div key={index} className={styles.applyMethodBox}>
                            <div className={styles.applyMethodContent}>
                              <input
                                id={`applyMethods-${index}`}
                                type="text"
                                value={
                                  planInfo.applyMethods && planInfo.applyMethods[index]
                                    ? planInfo.applyMethods[index]
                                    : ""
                                }
                                name="applyMethods"
                                maxLength="300"
                                placeholder={`手順 ${index + 1}`}
                                onChange={(e) => handleArrayChange(index, e, planInfo.applyMethods)}
                              />

                              <button
                                type="button"
                                onClick={() => {
                                  handleDeleteArrayInput(index, planInfo.applyMethods);
                                }}
                                className={styles.deleteURLButton}
                              >
                                <FontAwesomeIcon icon={["fas", "fa-trash-alt"]} />
                              </button>
                            </div>
                            {applyMethodsCount < 5 && index + 1 === applyMethodsCount && (
                              <button
                                type="button"
                                onClick={handleAddInput}
                                className={styles.addInputPlus}
                                disabled={
                                  !planInfo.applyMethods ||
                                  !planInfo.applyMethods[index] ||
                                  planInfo.applyMethods[index]?.trim() === ""
                                }
                              >
                                <FontAwesomeIcon icon={["fa", "plus"]} />
                                <p>手順を追加する</p>
                              </button>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>

                    {/* )} */}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      );
    } else {
      return (
        <div id="container">
          <LoadingIcon />
        </div>
      );
    }
  } else {
    <div id="container">
      <LoadingIcon />
    </div>;
  }
};
