import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { globalUserContext } from "../globalContext";

export const EveryTimeChangePath = () => {
  const { authMode } = useContext(globalUserContext);

  const { pathname } = useLocation();
  useEffect(() => {
    //ページ先頭にスクロール
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    //スクロールを有効化
    if (!authMode && document.body.style.overflowY === "hidden") {
      document.body.style.overflowY = "scroll";
    }
  }, [pathname]);

  return null;
};
