import React, { useState, useContext, useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styles from "../assets/css/header.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as LogoPC } from "../assets/img/logo/logo_pc.svg";
import { ReactComponent as LogoSimple } from "../assets/img/logo/logo_simple.svg";
import { AuthStatus } from "./auth/AuthStatus";
import { globalUserContext } from "../globalContext";

export const Header = () => {
  const { userInfo, authMode, setAuthMode } = useContext(globalUserContext);
  const [showMenu, setShowMenu] = useState(false);
  const [lastPosition, setLastPosition] = useState(0);
  const headerHeight = 140;
  const iconBar = document.getElementById("iconBar");
  const iconClose = document.getElementById("iconClose");
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (!iconBar || !iconClose) return;

    if (showMenu) {
      //menu is open
      iconBar.style.visibility = "hidden";
      iconClose.style.visibility = "visible";
      document.body.style.overflowY = "hidden"; //背景のスクロールを防ぐ
    } else {
      //close
      iconBar.style.visibility = "visible";
      iconClose.style.visibility = "hidden";
      if (!authMode) document.body.style.overflowY = "scroll";
    }
  }, [showMenu, authMode, iconBar, iconClose]);

  const scrollEvent = useCallback(() => {
    const header = document.getElementById("header");
    if (!header) return;
    const offset = window.pageYOffset;
    //headerのスクロールによるアニメーション
    if (offset < headerHeight) {
      //上に到達
      header.classList.remove(styles.hiddenHeader);
      return;
    }

    if (offset < lastPosition) {
      // 上スクロール
      header.classList.remove(styles.hiddenHeader);
    } else {
      //ログイン画面表示時はヘッダー表示
      if (authMode) {
        header.classList.remove(styles.hiddenHeader);
        return;
      }
      // 下スクロール
      header.classList.add(styles.hiddenHeader);
    }
    setLastPosition(offset);
  }, [lastPosition, authMode]);

  useEffect(() => {
    window.addEventListener("scroll", scrollEvent);

    return () => {
      window.removeEventListener("scroll", scrollEvent);
    };
  }, [scrollEvent]);

  const closeMenu = () => {
    setShowMenu(false);
    const menu = document.getElementById("drawerInput");
    menu.checked = false;
    iconBar.style.visibility = "visible";
    iconClose.style.visibility = "hidden";
    if (!authMode) document.body.style.overflowY = "scroll";
  };

  const navigateTo = (toUrl) => {
    if (location.pathname.match("/create-plan") && toUrl === "/create-plan") {
      if (window.confirm("新しいプランをつくりますか？")) {
        navigate(toUrl);
        window.location.reload();
      }
      return;
    } else if (location.pathname === "/create-plan" || location.pathname === "/confirm-plan") {
      if (window.confirm("入力内容が保存されない可能性があります。ページを離れますか？")) {
        navigate(toUrl);
        return;
      } else {
        return;
      }
    } else {
      navigate(toUrl);
    }
  };
  if (location.pathname.match(/create-plan/) || location.pathname.match(/confirm-plan/) ||location.pathname.match(/plan-room/)) {
    return null;
  } else
    return (
      <header className={styles.headerSpace}>
        <div id="header" className={`${styles.isFixed} ${styles.header}`}>
          <div className={styles.headerBox}>
            {/* for mobile */}
            <div className={styles.hamburgerMenu}>
              <div
                onClick={() => {
                  navigateTo("/");
                  setAuthMode(false);
                }}
                className={styles.headerTitle}
              >
                <div className={styles.logoBox}>
                  <LogoSimple className={styles.logoPC} alt="pobicleのロゴ" />
                  <p>小さな旅の入り口に</p>
                </div>
                <LogoSimple className={styles.LogoMob} alt="pobicleのロゴ" />
              </div>
              <div className={styles.nav}>
                <input id="drawerInput" className={styles.drawerHidden} type="checkbox" />
                <label
                  htmlFor="drawerInput"
                  className={styles.drawerOpen}
                  id="hamburgerMenuIcon"
                  onClick={() => {
                    showMenu ? setShowMenu(false) : setShowMenu(true);
                  }}
                >
                  <FontAwesomeIcon icon={["fa", "bars"]} className={styles.faBars} id="iconBar" />
                  <FontAwesomeIcon icon={["fa", "times"]} className={styles.faTimes} id="iconClose" />
                </label>
                <div className={styles.mobileAuthBox}>
                  <AuthStatus closeMenu={closeMenu} />
                </div>
                <div
                  className={styles.navOverlay}
                  onClick={() => {
                    closeMenu();
                  }}
                ></div>
                <nav className={styles.navContent}>
                  <ul className={styles.navList}>
                    {userInfo ? (
                      <li id="createPlan" className={styles.navItem}>
                        <button
                          onClick={() => {
                            closeMenu();
                            navigateTo("/create-plan");
                          }}
                        >
                          プランをつくる
                        </button>
                      </li>
                    ) : (
                      <li className={styles.disabledMenuItem}>
                        <div className={styles.disabledMenu}>
                          プランをつくる
                          <div className={styles.captionBox}>
                            <div className={styles.caption}>ログインが必要です</div>
                          </div>
                        </div>
                      </li>
                    )}
                    <li className={styles.navItem}>
                      <button
                        onClick={() => {
                          closeMenu();
                          setAuthMode(false);
                          navigateTo("/find-plan");
                        }}
                      >
                        プランをさがす
                      </button>
                    </li>
                    {/* <li className={styles.navItem}>
                    <button to="ride-record">みんなのライド記録</button>
                  </li> */}
                    {/* <li className={styles.navItem}>
                    <button to="">よくあるご質問</button>
                  </li> */}
                    {/* <li className={styles.navItem}>
                    <button to="mypage">マイページ</button>
                  </li> */}
                    <li className={styles.navItem}>
                      <button
                        onClick={() => {
                          closeMenu();
                          setAuthMode(false);
                          navigateTo("/mypage/plans");
                        }}
                      >
                        マイプラン
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className={styles.authBox}>
              <AuthStatus closeMenu={closeMenu} />
            </div>
          </div>
          {/* for PC/tablet */}
          <div className={styles.menuBox}>
            <ul className={styles.menuBar1}>
              {/* ログイン済みの時のみ旅プランをつくるボタンが有効 */}
              {userInfo ? (
                <li>
                  <button onClick={() => navigateTo("/create-plan")}>プランをつくる</button>
                </li>
              ) : (
                <div className={styles.disabledMenu}>
                  <p>プランをつくる</p>
                  <div className={styles.captionBox}>
                    <div className={styles.caption}>ログインが必要です</div>
                  </div>
                </div>
              )}
              <li>
                <button onClick={() => navigateTo("/find-plan")}>プランをさがす</button>
              </li>
              {/* <li>
            <button to="ride-record">みんなのライド記録</button>
          </li> */}
            </ul>
            <ul className={styles.menuBar2}>
              {/* <li>
            <button to="">よくあるご質問</button>
          </li> */}

              {userInfo && (
                <li>
                  <button onClick={() => navigateTo("/mypage/plans")}>マイプラン</button>
                </li>
              )}
            </ul>
          </div>
        </div>
      </header>
    );
};
