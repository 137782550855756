import React, { memo, useEffect, useState, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import styles from "../../assets/css/planCard.module.css";
import { usePlanImage } from "../../hooks/usePlanImage";
import noImage from "../../assets/img/noimage.png";
import { tryGetUser } from "../../helper/dbAccess/userAccess";
import { tryCopyFunc } from "../../helper/copyClipBoard";
import { DISCLOSURE_RANGE } from "../../constants/planConstants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { globalUserContext } from "../../globalContext";
import { tryGetRidePlan, tryCreateRidePlan } from "../../helper/dbAccess/planAccess";
import { motion } from "framer-motion";
import { popUp } from "../../helper/animation";
import { MessageModal } from "../modal/MessageModal";

export const PlanCard = memo(({ planInfo, indexOfOpenedMenu, setIndexOfOpenedMenu, index }) => {
  const { userInfo } = useContext(globalUserContext);
  const navigate = useNavigate();
  const { fetchImageURL } = usePlanImage();
  const [imageListForDisp, setImageListForDisp] = useState([]);
  const [creator, setCreator] = useState("");
  const [doneCopied, setDoneCopied] = useState(false);
  const [isShowMessageModal, setIsShowMessageModal] = useState(false);
  //画像のURLを取得する
  useEffect(() => {
    if (!planInfo) return;
    (async () => {
      if (planInfo.image) {
        const imageURLs = await fetchImageURL([planInfo.image, ...planInfo.scheduleImages]);
        setImageListForDisp(imageURLs);
      }
    })();
  }, [planInfo]);

  useEffect(() => {
    const prepareCreatorInfo = async () => {
      try {
        const res = await tryGetUser(planInfo.creator);
        if (res) {
          setCreator(res);
        } else {
          // setCreator(planInfo.creator);
          setCreator(planInfo.creator);
        }
      } catch (e) {
        console.error(e);
      }
    };
    if (planInfo) {
      prepareCreatorInfo();
    } else return;
  }, [planInfo]);

  // タグの数制限の処理付きのタグの表示コンポーネントを作成する
  const TagList = React.memo(() => {
    const TOTALLIMIT = 5;
    const PORPOSELIMIT = 3;
    const TYPELIMIT = 2;
    let tags = [];
    if (planInfo.purpose || planInfo.bicycleType) {
      //目的と自転車の種類のそれぞれの個数上限を超えている場合は、越えた分は除外する
      if (planInfo.purpose?.length + planInfo.bicycleType?.length > TOTALLIMIT) {
        planInfo.purpose.forEach((purpose, index) => {
          if (index + 1 <= PORPOSELIMIT && purpose) tags.push(purpose);
        });
        planInfo.bicycleType.forEach((bicycleType, index) => {
          if (index + 1 <= TYPELIMIT && bicycleType) tags.push(bicycleType);
        });
      } else {
        //トータルの個数が上限を超えていない場合はそのまま全て表示する
        planInfo.purpose?.forEach((purpose) => {
          if (purpose) tags.push(purpose);
        });
        planInfo.bicycleType?.forEach((bicycleType) => {
          if (bicycleType) tags.push(bicycleType);
        });
      }
    } else {
      tags = [];
    }
    if (tags.length > 0) {
      return tags.map((tag, index) => {
        return (
          <div className={styles.tag} index={index} key={index}>
            {tag}
          </div>
        );
      });
    } else return null;
  });
  //プラン編集ページに遷移する
  const toEditPage = async (planId) => {
    navigate("/create-plan/" + planId);
  };

  //プランを複製する
  const tryDuplicatePlan = async () => {
    try {
      const plan = await tryGetRidePlan(planInfo.id);
      const newPlan = {
        ...plan,
        creator: null,
        title: planInfo.title + "- コピー",
        isTemp: true,
        originalPlanId: planInfo.id,
      };
      // 入力に不要なkeyを削除
      for (const key of ["updatedAt", "createdAt", "id", "originalPlan", "duplicatedPlans"]) {
        delete newPlan[key];
      }
      const result = await tryCreateRidePlan(newPlan, userInfo);
      //複製元の画像を再登録
      if (plan.image) {
        await fetchImageURL([plan.image, ...plan.scheduleImages]);
      }
      //編集ページへ遷移
      toEditPage(result.id);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className={styles.cardBox} whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.95 }}>
      {isShowMessageModal && (
        <MessageModal
          yesFunc={tryDuplicatePlan}
          message={`${planInfo.title}を複製しますか？ 複製されたプランは下書きとして保存されます。`}
          yesButtonText={"複製する"}
          onCancel={() => setIsShowMessageModal(false)}
        />
      )}
      {indexOfOpenedMenu !== index ? (
        <div className={styles.menuOpenBtnBox}>
          {/* ellipsisボタン */}
          <button type="button" onClick={() => setIndexOfOpenedMenu(indexOfOpenedMenu === index ? null : index)}>
            <span className={styles.ellipsisBtnCircle}>
              <FontAwesomeIcon icon={["fa", "ellipsis"]} className={styles.faEllipsis} />
            </span>
          </button>
        </div>
      ) : null}

      {indexOfOpenedMenu === index ? (
        <motion.div className={styles.planCardMenu} variants={popUp} initial="hidden" animate="visible" exit="exit">
          <div className={styles.menuCloseBtnBox}>
            <button type="button" onClick={() => setIndexOfOpenedMenu(indexOfOpenedMenu === index ? null : index)}>
              <span className={styles.closeBtnCircle}>
                <FontAwesomeIcon icon={["fa", "times"]} className={styles.faTimes} />
              </span>
            </button>
          </div>
          <div className={styles.planCardMenuList}>
            {planInfo.creator === userInfo?.attributes.sub ? (
              <button className={styles.menuButtonBox} onClick={() => toEditPage(planInfo.id)}>
                <FontAwesomeIcon icon={["fa", "pen"]} className={styles.menuIcon} />
                <span>編集</span>
              </button>
            ) : null}
            {userInfo ? (
              <button className={styles.menuButtonBox} onClick={() => setIsShowMessageModal(true)}>
                <FontAwesomeIcon icon="fa-regular fa-copy" className={styles.menuIcon} />
                <span>プランを複製 </span>
              </button>
            ) : null}

            <button
              className={styles.menuButtonBox}
              onClick={() => tryCopyFunc(`${window.location.href}/${planInfo.id}`, null, setDoneCopied)}
            >
              <FontAwesomeIcon icon={["fa", "link"]} className={styles.menuIcon} />
              {doneCopied ? <span>コピー完了 </span> : <span>リンクをコピー </span>}
            </button>
          </div>
        </motion.div>
      ) : null}

      <motion.div className={styles.motionSkin}></motion.div>
      <motion.div whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.95 }} className={styles.motionSkin}>
        <Link to={`/find-plan/${planInfo.id}`} className={styles.cardLink}></Link>
        <div className={styles.imageBox}>
          {planInfo.disclosureRange === DISCLOSURE_RANGE.PRIVATE && (
            <div className={styles.disclosureRange}>
              <p>限定公開</p>
            </div>
          )}
          <Link to={`/find-plan/${planInfo.id}`}>
            <img
              src={planInfo.image && imageListForDisp.length ? imageListForDisp[0] : noImage}
              alt="planImage"
              loading="lazy"
            />
          </Link>

          {/* (
          //  お気に入りボタン
          <div className={styles.buttonBox}>
          <div className={styles.goodButtonBox}>
          <button type="button" className={styles.goodButton}>
          <FontAwesomeIcon icon={["fa", "heart"]} className={styles.faHeart} />
          </button>
          </div>
          </div>
        ) */}
        </div>
        <div className={styles.cardInfo}>
          <h4 className={styles.cardTitle}>{planInfo.title}</h4>
          <div className={styles.planDetail}>
            {planInfo.prefecture && planInfo.city && (
              <div className={styles.place}>
                {planInfo.prefecture}
                {planInfo.city}
              </div>
            )}
            <div className={styles.tagBox}>
              {planInfo.rideLevel && <div className={styles.tag}>{planInfo.rideLevel}向け</div>}
              <TagList />
            </div>
            <div className={styles.bottomRow}>
              {/* TODO: ビジネスユーザーの実装 */}

              <button
                className={styles.creator}
                // style={{ backgroundColor: creator.bussines ? "#e8fdf4" : "#f5f5f5" }}
                // onClick={() => navigate(`/user/${creator.id}`)}
              >
                作成者：
                <span className={styles.creationinfo}>{creator.nickName}</span>
              </button>
              <div className={styles.emptyDiv}></div>
              <div className={styles.date}>
                <div className={styles.creationlabel}>
                  <div>作成日</div>
                  <div className={styles.creationinfo}>{new Date(planInfo.createdAt).toLocaleDateString()}</div>
                </div>
                <div className={styles.creationlabel}>
                  <div>最終更新日</div>
                  <div className={styles.creationinfo}>{new Date(planInfo.updatedAt).toLocaleDateString()}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
});
