/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSpot = /* GraphQL */ `
  query GetSpot($id: ID!) {
    getSpot(id: $id) {
      id
      creatorId
      name
      address
      googlePlaceId
      latLng {
        lat
        lng
      }
      category
      memo
      disclosureRange
      imageKeys
      url
      createdAt
      updatedAt
    }
  }
`;
export const listSpots = /* GraphQL */ `
  query ListSpots(
    $filter: ModelSpotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpots(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        creatorId
        name
        address
        googlePlaceId
        latLng {
          lat
          lng
        }
        category
        memo
        disclosureRange
        imageKeys
        url
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRidePlan = /* GraphQL */ `
  query GetRidePlan($id: ID!) {
    getRidePlan(id: $id) {
      id
      creator
      image
      title
      description
      timeRequired
      purpose
      prefecture
      city
      disclosureRange
      isTemp
      rideLevel
      bicycleType
      scheduleList {
        title
        startTime
        endTime
        place
        latLng {
          lat
          lng
        }
        address
        url
        phone
        memo
        spotId
      }
      scheduleImages
      routeDataKey
      date
      deadline
      maxParticipants
      minParticipants
      belongings
      price
      paymentMethod
      notes
      applyMethods
      createdAt
      updatedAt
      isCycling
      isTour
      members {
        items {
          id
          ridePlanID
          userID
          createdAt
          updatedAt
        }
        nextToken
      }
      originalPlanId
      originalPlan {
        id
        creator
        image
        title
        description
        timeRequired
        purpose
        prefecture
        city
        disclosureRange
        isTemp
        rideLevel
        bicycleType
        scheduleList {
          title
          startTime
          endTime
          place
          address
          url
          phone
          memo
          spotId
        }
        scheduleImages
        routeDataKey
        date
        deadline
        maxParticipants
        minParticipants
        belongings
        price
        paymentMethod
        notes
        applyMethods
        createdAt
        updatedAt
        isCycling
        isTour
        members {
          nextToken
        }
        originalPlanId
        originalPlan {
          id
          creator
          image
          title
          description
          timeRequired
          purpose
          prefecture
          city
          disclosureRange
          isTemp
          rideLevel
          bicycleType
          scheduleImages
          routeDataKey
          date
          deadline
          maxParticipants
          minParticipants
          belongings
          price
          paymentMethod
          notes
          applyMethods
          createdAt
          updatedAt
          isCycling
          isTour
          originalPlanId
        }
        duplicatedPlans {
          nextToken
        }
      }
      duplicatedPlans {
        items {
          id
          creator
          image
          title
          description
          timeRequired
          purpose
          prefecture
          city
          disclosureRange
          isTemp
          rideLevel
          bicycleType
          scheduleImages
          routeDataKey
          date
          deadline
          maxParticipants
          minParticipants
          belongings
          price
          paymentMethod
          notes
          applyMethods
          createdAt
          updatedAt
          isCycling
          isTour
          originalPlanId
        }
        nextToken
      }
    }
  }
`;
export const listRidePlans = /* GraphQL */ `
  query ListRidePlans(
    $filter: ModelRidePlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRidePlans(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        creator
        image
        title
        description
        timeRequired
        purpose
        prefecture
        city
        disclosureRange
        isTemp
        rideLevel
        bicycleType
        scheduleList {
          title
          startTime
          endTime
          place
          address
          url
          phone
          memo
          spotId
        }
        scheduleImages
        routeDataKey
        date
        deadline
        maxParticipants
        minParticipants
        belongings
        price
        paymentMethod
        notes
        applyMethods
        createdAt
        updatedAt
        isCycling
        isTour
        members {
          nextToken
        }
        originalPlanId
        originalPlan {
          id
          creator
          image
          title
          description
          timeRequired
          purpose
          prefecture
          city
          disclosureRange
          isTemp
          rideLevel
          bicycleType
          scheduleImages
          routeDataKey
          date
          deadline
          maxParticipants
          minParticipants
          belongings
          price
          paymentMethod
          notes
          applyMethods
          createdAt
          updatedAt
          isCycling
          isTour
          originalPlanId
        }
        duplicatedPlans {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      nickName
      icon
      PlansJoined {
        items {
          id
          ridePlanID
          userID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        nickName
        icon
        PlansJoined {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPlanMembers = /* GraphQL */ `
  query GetPlanMembers($id: ID!) {
    getPlanMembers(id: $id) {
      id
      ridePlanID
      userID
      ridePlan {
        id
        creator
        image
        title
        description
        timeRequired
        purpose
        prefecture
        city
        disclosureRange
        isTemp
        rideLevel
        bicycleType
        scheduleList {
          title
          startTime
          endTime
          place
          address
          url
          phone
          memo
          spotId
        }
        scheduleImages
        routeDataKey
        date
        deadline
        maxParticipants
        minParticipants
        belongings
        price
        paymentMethod
        notes
        applyMethods
        createdAt
        updatedAt
        isCycling
        isTour
        members {
          nextToken
        }
        originalPlanId
        originalPlan {
          id
          creator
          image
          title
          description
          timeRequired
          purpose
          prefecture
          city
          disclosureRange
          isTemp
          rideLevel
          bicycleType
          scheduleImages
          routeDataKey
          date
          deadline
          maxParticipants
          minParticipants
          belongings
          price
          paymentMethod
          notes
          applyMethods
          createdAt
          updatedAt
          isCycling
          isTour
          originalPlanId
        }
        duplicatedPlans {
          nextToken
        }
      }
      user {
        id
        nickName
        icon
        PlansJoined {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listPlanMembers = /* GraphQL */ `
  query ListPlanMembers(
    $filter: ModelPlanMembersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlanMembers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ridePlanID
        userID
        ridePlan {
          id
          creator
          image
          title
          description
          timeRequired
          purpose
          prefecture
          city
          disclosureRange
          isTemp
          rideLevel
          bicycleType
          scheduleImages
          routeDataKey
          date
          deadline
          maxParticipants
          minParticipants
          belongings
          price
          paymentMethod
          notes
          applyMethods
          createdAt
          updatedAt
          isCycling
          isTour
          originalPlanId
        }
        user {
          id
          nickName
          icon
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const spotByCreatorId = /* GraphQL */ `
  query SpotByCreatorId(
    $creatorId: String!
    $category: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSpotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    spotByCreatorId(
      creatorId: $creatorId
      category: $category
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        creatorId
        name
        address
        googlePlaceId
        latLng {
          lat
          lng
        }
        category
        memo
        disclosureRange
        imageKeys
        url
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const ridePlanByCreator = /* GraphQL */ `
  query RidePlanByCreator(
    $creator: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRidePlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ridePlanByCreator(
      creator: $creator
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        creator
        image
        title
        description
        timeRequired
        purpose
        prefecture
        city
        disclosureRange
        isTemp
        rideLevel
        bicycleType
        scheduleList {
          title
          startTime
          endTime
          place
          address
          url
          phone
          memo
          spotId
        }
        scheduleImages
        routeDataKey
        date
        deadline
        maxParticipants
        minParticipants
        belongings
        price
        paymentMethod
        notes
        applyMethods
        createdAt
        updatedAt
        isCycling
        isTour
        members {
          nextToken
        }
        originalPlanId
        originalPlan {
          id
          creator
          image
          title
          description
          timeRequired
          purpose
          prefecture
          city
          disclosureRange
          isTemp
          rideLevel
          bicycleType
          scheduleImages
          routeDataKey
          date
          deadline
          maxParticipants
          minParticipants
          belongings
          price
          paymentMethod
          notes
          applyMethods
          createdAt
          updatedAt
          isCycling
          isTour
          originalPlanId
        }
        duplicatedPlans {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const ridePlanByDisclosureRange = /* GraphQL */ `
  query RidePlanByDisclosureRange(
    $disclosureRange: DisclosureRange!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRidePlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ridePlanByDisclosureRange(
      disclosureRange: $disclosureRange
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        creator
        image
        title
        description
        timeRequired
        purpose
        prefecture
        city
        disclosureRange
        isTemp
        rideLevel
        bicycleType
        scheduleList {
          title
          startTime
          endTime
          place
          address
          url
          phone
          memo
          spotId
        }
        scheduleImages
        routeDataKey
        date
        deadline
        maxParticipants
        minParticipants
        belongings
        price
        paymentMethod
        notes
        applyMethods
        createdAt
        updatedAt
        isCycling
        isTour
        members {
          nextToken
        }
        originalPlanId
        originalPlan {
          id
          creator
          image
          title
          description
          timeRequired
          purpose
          prefecture
          city
          disclosureRange
          isTemp
          rideLevel
          bicycleType
          scheduleImages
          routeDataKey
          date
          deadline
          maxParticipants
          minParticipants
          belongings
          price
          paymentMethod
          notes
          applyMethods
          createdAt
          updatedAt
          isCycling
          isTour
          originalPlanId
        }
        duplicatedPlans {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const ridePlanByOriginalPlanId = /* GraphQL */ `
  query RidePlanByOriginalPlanId(
    $originalPlanId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRidePlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ridePlanByOriginalPlanId(
      originalPlanId: $originalPlanId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        creator
        image
        title
        description
        timeRequired
        purpose
        prefecture
        city
        disclosureRange
        isTemp
        rideLevel
        bicycleType
        scheduleList {
          title
          startTime
          endTime
          place
          address
          url
          phone
          memo
          spotId
        }
        scheduleImages
        routeDataKey
        date
        deadline
        maxParticipants
        minParticipants
        belongings
        price
        paymentMethod
        notes
        applyMethods
        createdAt
        updatedAt
        isCycling
        isTour
        members {
          nextToken
        }
        originalPlanId
        originalPlan {
          id
          creator
          image
          title
          description
          timeRequired
          purpose
          prefecture
          city
          disclosureRange
          isTemp
          rideLevel
          bicycleType
          scheduleImages
          routeDataKey
          date
          deadline
          maxParticipants
          minParticipants
          belongings
          price
          paymentMethod
          notes
          applyMethods
          createdAt
          updatedAt
          isCycling
          isTour
          originalPlanId
        }
        duplicatedPlans {
          nextToken
        }
      }
      nextToken
    }
  }
`;
