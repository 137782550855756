import { Authenticator, translations } from "@aws-amplify/ui-react";
import { I18n } from "aws-amplify";
import { globalUserContext } from "../../globalContext";
import React, { useContext } from "react";
import { CrossCloseButtonRight } from "../commons/buttons/CrossCloseButton";
import { ReactComponent as LogoSimple } from "../../assets/img/logo/logo_simple.svg";
import authTranslation from "../../constants/authTranslation";
import styles from "../../assets/css/auth/authModal.module.css";
import { CognitoIdentityProviderClient, GetUserCommand } from "@aws-sdk/client-cognito-identity-provider";

I18n.putVocabularies(authTranslation.languageDict);
I18n.putVocabularies(translations);
I18n.setLanguage("ja");
export function AuthModal() {
  const { authMode, setAuthMode } = useContext(globalUserContext);

  const closeAuthModal = () => {
    setAuthMode(false);
  };
  // ユーザー情報取得
  // const getuser = async (accessToken) => {
  //   const config = { region: process.env.REACT_APP_AWS_REGION };
  //   const client = new CognitoIdentityProviderClient(config);
  //   const command = new GetUserCommand({
  //     AccessToken: accessToken,
  //   });
  //   const response = await client.send(command);
  // };

  if (authMode === "signIn" || authMode === "signUp") {
    //ログイン/サインアップモード
    document.body.style.overflowY = "hidden";
    return (
      <div className={styles.modal}>
        <div className={styles.background} onClick={closeAuthModal}></div>
        <div className={styles.clossButton}>
          <CrossCloseButtonRight onClick={closeAuthModal} />
        </div>
        <div className={styles.authenticator}>
          <Authenticator
            initialState={authMode}
            loginMechanisms={["email"]}
            /*variation="modal"*/
          >
            {({ user }) => {
              document.body.style.overflowY = "scroll";
              if (user) closeAuthModal();
              return null;
            }}
          </Authenticator>
        </div>
      </div>
    );
  } else return null;
}
