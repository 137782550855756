export const sanitizeInput = (input) => {
  const sanitizedInput = { ...input }; // 元のオブジェクトを変更しないようにコピーを作成
  // テキスト内のURLもサニタイズ
  //引数のinput内のプロパティ(PlanInfo)がstring型であれば、sanitizeTextWithUrls関数を実行
  for (const key in sanitizedInput) {
    if (key === "scheduleList") {
      const scheduleList = sanitizedInput[key];
      //scheduleListの配列内を順番にサニタイズ
      for (let i = 0; i < scheduleList.length; i++) {
        const schedule = scheduleList[i];
        for (const scheduleKey in schedule) {
          //配列の場合
          if (Array.isArray(schedule[scheduleKey])) {
            const item = schedule[scheduleKey];
            schedule[scheduleKey] = sanitizeArray(item);
          }
          //stringの場合
          if (typeof schedule[scheduleKey] === "string") {
            schedule[scheduleKey] = sanitizeText(schedule[scheduleKey]);
          }
        }
      }
    }
    //その他のプロパティをサニタイズ
    if (typeof sanitizedInput[key] === "string") {
      sanitizedInput[key] = sanitizeText(sanitizedInput[key]);
    }
    //配列の場合
    if (Array.isArray(sanitizedInput[key])) {
      const item = sanitizedInput[key];
      sanitizedInput[key] = sanitizeArray(item);
    }
  }
  return sanitizedInput;
};

//配列の中身をサニタイズ
const sanitizeArray = (array) => {
  const sanitizedArray = array.map((item) => {
    if (typeof item === "string") {
      return sanitizeText(item);
    }
    if (Array.isArray(item)) {
      return sanitizeArray(item);
    }
    return item;
  });
  return sanitizedArray;
};

// スクリプトタグを無効化またはエスケープ処理
export const sanitizeText = (inputText) => {
  if (!inputText) return;
  const sanitizedText = inputText.replace(/<script>/gi, "&lt;script&gt;");
  return sanitizedText;
};

const displaySanitizedText = (sanitizedText) => {
  // エンティティを元の文字に戻す
  const displayedUrl = sanitizedText
    .replace(/&amp;/g, "&")
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/&quot;/g, '"')
    .replace(/&#x27;/g, "'")
    .replace(/&#x2F;/g, "/");

  //HTMLエンティティ化された文字列を表示

  return displayedUrl;
};

const sanitizeUrl = (url) => {
  // サニタイズ処理を実装
  // 特殊文字やHTMLエンティティをエスケープする
  const sanitizedUrl = url
    .replace(/<script>/gi, "&lt;script&gt;")
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#x27;")
    .replace(/\//g, "&#x2F;");
  return sanitizedUrl;
};

const sanitizeTextWithUrls = (text) => {
  // 正規表現を使用してテキスト内のURLを検出し、サニタイズする
  const sanitizedText = text.replace(/(https?:\/\/[^\s]+)/g, (url) => {
    return sanitizeUrl(url);
  });
  return sanitizedText;
};
