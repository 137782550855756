import React, { useContext } from "react";
import { globalUserContext } from "../globalContext";
import { Link } from "react-router-dom";
import styles from "../assets/css/exceptionMessage.module.css";
import authStyles from "../assets/css/auth/authStatus.module.css";
import people1 from "../assets/img/illust/people1.png";
import people2 from "../assets/img/illust/people2.png";

export const NotFound = () => {
  return (
    <div id="container">
      <div className={styles.messageBox}>
        <p className={styles.title}>ページが見つかりません。</p>
        <h4 className={styles.message}>あなたにぴったりの小さな旅のプランを探しに行こう。</h4>
        <div className={styles.buttonBox}>
          <Link className={styles.link} to={"/find-plan"}>
            プランをさがす
          </Link>
          <Link className={styles.link} to={"/create-plan"}>
            プランをつくる
          </Link>
        </div>
        <div className={styles.imgBox}>
          <img src={people1} alt="自転車に乗る人のイラスト" className={styles.imgPeople3}></img>
          <img src={people2} alt="自転車に乗る人のイラスト" className={styles.imgPeople4}></img>
        </div>
      </div>
    </div>
  );
};
export const NotAuthenticated = () => {
  return (
    <div id="container">
      <div className={styles.messageBox}>
        <p className={styles.title}>このページへのアクセス権限がありません。</p>
        <h4 className={styles.message}>あなたにぴったりの小さな旅のプランを探しに行こう。</h4>
        <div className={styles.buttonBox}>
          <Link className={styles.link} to={"/find-plan"}>
            プランをさがす
          </Link>
        </div>
        <div className={styles.imgBox}>
          <img src={people1} alt="自転車に乗る人のイラスト" className={styles.imgPeople3}></img>
          <img src={people2} alt="自転車に乗る人のイラスト" className={styles.imgPeople4}></img>
        </div>
      </div>
    </div>
  );
};

export const NotLogin = () => {
  const { setAuthMode } = useContext(globalUserContext);
  return (
    <div id="container">
      <div className={styles.messageBox}>
        <p className={styles.title}>「ログイン」が必要です。</p>
        <p className={styles.message}>
          「ログイン」で
          <br />
          プラン作成ツール『プランをつくる』、管理機能『マイプラン』が使用可能になります。
        </p>
        <div className={styles.buttonBox}>
          <button
            className={authStyles.loginButton}
            onClick={() => {
              setAuthMode("signIn");
            }}
          >
            ログイン
          </button>
          <button
            className={authStyles.greenButton}
            onClick={() => {
              setAuthMode("signUp");
            }}
          >
            新規登録
          </button>
        </div>
        <h5 className={styles.message}>登録がお済みでない方は「新規登録」(無料)をお願いします。</h5>

        <div className={styles.bottomArea}>
          <h4>もっと便利に、もっと楽しく。</h4>
          <div className={styles.imgBox}>
            <img src={people1} alt="自転車に乗る人のイラスト" className={styles.imgPeople3}></img>
            <img src={people2} alt="自転車に乗る人のイラスト" className={styles.imgPeople4}></img>
          </div>
        </div>
      </div>
    </div>
  );
};
