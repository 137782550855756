import { API, graphqlOperation } from "aws-amplify";
import { createRidePlan, updateRidePlan, deleteRidePlan } from "../../graphql/mutations";
import { getRidePlan, listRidePlans, ridePlanByDisclosureRange, ridePlanByCreator } from "../../graphql/queries";
import { customGetRidePlan } from "../../customGraphql/customQueries";
import { SORT_DIRECTION } from "../../constants/global";
import { initPlanInfo } from "../../constants/initState";

const preparePlanInfo = (planInfo, userInfo) => {
  planInfo.creator = userInfo.attributes.sub; //作成者のIDを入れる
  for (let prop in planInfo) {
    //入力に含まれないプロパティを削除
    if ([prop] in initPlanInfo || prop === "id") {
      continue;
    } else {
      delete planInfo[prop];
    }
    //メイン画像(key)はスキップ
    if (prop === "image") continue;
    // undefinedかnullのプロパティを削除
    if ((planInfo[prop] === undefined || planInfo[prop] === null) && prop !== "scheduleList") {
      delete planInfo[prop];
    }
    if (Array.isArray(planInfo[prop])) {
      if (planInfo[prop].length === 0 && prop !== "scheduleList" && prop !== "scheduleImages") {
        delete planInfo[prop];
      }
    }
    //scheduleListの中身の不要なプロパティを削除
    if (prop === "scheduleList") {
      if (planInfo.scheduleList === undefined || planInfo.scheduleList === null) {
        delete planInfo.scheduleList;
      } else {
        for (const schedule of planInfo.scheduleList) {
          for (let k in schedule) {
            if (schedule[k] === "" || schedule[k] === undefined || schedule[k] === null) {
              delete schedule[k];
            }

            if (Array.isArray(schedule[k])) {
              if (schedule[k].length === 0) {
                delete schedule[k];
              }
            }
          }
        }
      }
    }
  }
  // Convert local time to UTC
  // AWSDateTime: YYYY-MM-DDThh:mm:ss.sssZ
  const inputDeadline = planInfo.deadline; // YYYY-MM-DDThh:mm(JST)
  if (inputDeadline) {
    const localDeadline = new Date(inputDeadline);
    const utcDeadline = localDeadline.toISOString();
    planInfo.deadline = utcDeadline;
  }
  return planInfo;
};

/**
 * Populate planInfo data into DynamoDB. The properties have not meaning value do not populate.
 * @param {object} planInfo is 'planInfo' object.
 * @returns {object | never} item | error
 */
export const tryCreateRidePlan = async (planInfo, userInfo) => {
  const preparedPlanInfo = preparePlanInfo(planInfo, userInfo);
  try {
    // throw new Error("test");
    const item = await API.graphql(
      graphqlOperation(createRidePlan, {
        input: { ...preparedPlanInfo },
      })
    );
    return item.data.createRidePlan;
  } catch (e) {
    console.error("Failed creating a ride plan");
    throw e;
  }
};
/**
 * Populate planInfo data into DynamoDB. The properties have not meaning value do not populate.
 * @param {object} planInfo is 'planInfo' object.
 * @returns {object | never} item | error
 */
export const tryUpdateRidePlan = async (planInfo, userInfo) => {
  const preparedPlanInfo = preparePlanInfo(planInfo, userInfo);
  try {
    const item = await API.graphql(
      graphqlOperation(updateRidePlan, {
        input: preparedPlanInfo,
      })
    );
    return item.data.updateRidePlan;
  } catch (e) {
    console.error("Failed updating a ride plan");
    throw e;
  }
};

/**
 * Fetch ride plans from DB. By default, the search result page size is 100.
 * @param {number} limit The number of plans to fetch.
 * @param {object} filter Conditions to filter after fetching data. See https://docs.amplify.aws/lib/graphqlapi/query-data/q/platform/js/#simple-query
 * @param {string} nextToken Fetch rest items starting from nextToken.
 * @returns {object | never} Oject has two properties.
 * 'items` are array of ride plans.
 *  'nextToken' is the key for next item.
 */
export const tryListRidePlan = async (limit, filter, nextToken) => {
  try {
    const result = await API.graphql(graphqlOperation(listRidePlans, { limit, filter, nextToken }));
    return {
      items: result.data.listRidePlans.items,
      nextToken: result.data.listRidePlans.nextToken,
    };
  } catch (e) {
    console.error("Failed fetching plans");
    throw e;
  }
};

/**
 * Fetch ride plans from DB by using global secondary index which is "disclosureRange".
 * The number of search result is 100.
 * @param {string} disclosureRange Refer to the schema.graphql.
 * @param {number} limit The number of plans to fetch.
 * @param {object} filter Conditions to filter after fetching data. See https://docs.amplify.aws/lib/graphqlapi/query-data/q/platform/js/#simple-query
 * @param {string} nextToken Fetch rest items starting from nextToken.
 * @param {string} sortDirection Sort items by using sort key which is "createdAt". Possible values are "ASC" or "DESC"(default)
 * @returns {object | error} Successed result has two properties.
 *  - items: Array of ride plans.
 *  - nextToken: key for a next item.
 */
export const tryListRidePlansByDisclosureRange = async (disclosureRange, isTemp = undefined /*isWanted*/) => {
  const setFilter = () => {
    let filter = {};
    if (isTemp !== undefined) {
      filter = {
        ...filter,
        isTemp: { eq: isTemp },
      };
    }
    return filter;
  };
  const filter = setFilter();
  const sortDirection = SORT_DIRECTION.DESC;
  try {
    const result = await API.graphql(
      graphqlOperation(ridePlanByDisclosureRange, { disclosureRange, filter: filter, sortDirection })
    );

    const data = result.data.ridePlanByDisclosureRange;

    return {
      items: data.items,
      nextToken: data.nextToken,
    };
  } catch (e) {
    console.error("Failed fetching plans");
    throw e;
  }
};

/**
 * Fetch ride plans from DB by using global secondary index which is "creator".
 * The number of search result is 100.
 * @param {string} creator Refer to the schema.graphql.
 * @param {number} limit The number of plans to fetch.
 * @param {object} filter Conditions to filter after fetching data. See https://docs.amplify.aws/lib/graphqlapi/query-data/q/platform/js/#simple-query
 * @param {string} nextToken Fetch rest items starting from nextToken.
 * @param {string} sortDirection Sort items by using sort key which is "createdAt". Possible values are "ASC" or "DESC"(default)
 * @returns {object | error} Successed result has two properties.
 *  - items: Array of ride plans.
 *  - nextToken: key for a next item.
 */
export const tryListRidePlansByCreator = async (creator, disclosureRange, isTemp /*isWanted*/) => {
  const setFilter = () => {
    let filter = {};
    if (disclosureRange) {
      filter = {
        ...filter,
        disclosureRange: { eq: disclosureRange },
      };
    }

    filter = {
      ...filter,
      isTemp: { eq: isTemp },
    };

    return filter;
  };
  const filter = setFilter();
  // const disclosureRangeFilter = { disclosureRange: { eq: disclosureRange } };
  const sortDirection = SORT_DIRECTION.DESC;
  try {
    const result = await API.graphql(graphqlOperation(ridePlanByCreator, { creator, filter: filter, sortDirection }));

    const data = result.data.ridePlanByCreator;

    return {
      items: data.items,
      nextToken: data.nextToken,
    };
  } catch (e) {
    console.error("Failed fetching plans");
    throw e;
  }
};

/**
 * Fetch a stored plan by a plan id. depth=3
 * @param {string} id ID of a registerd plan.
 * @returns {object | error} A fetched plan.
 */
export const tryGetRidePlan = async (id) => {
  try {
    const result = await API.graphql(graphqlOperation(getRidePlan, { id }));
    return result.data.getRidePlan;
  } catch (e) {
    console.error("Failed getting a ride plan");
    throw e;
  }
};

/**
 * Fetch a stored planInfo and roomInfo by a plan id. depth=4
 * @param {string} id ID of a registerd plan.
 * @returns {object | error} A fetched plan.
 */
export const tryGetRidePlanAndSpotInfo = async (id) => {
  try {
    const result = await API.graphql(graphqlOperation(customGetRidePlan, { id }));
    return result.data.getRidePlan;
  } catch (e) {
    console.error("Failed getting a ride plan");
    throw e;
  }
};

/**
 * Delete RidePlan by a plan id.
 * @param {string} id ID of a registerd plan.
 * @returns {id} deleted plan's id
 */
export const tryDeleteRidePlan = async (id) => {
  try {
    const result = await API.graphql(graphqlOperation(deleteRidePlan, { input: { id: id } }));
    return result.data.deleteRidePlan;
  } catch (e) {
    console.error("Failed delete ride plan", e);
    return null;
  }
};

//for update all data manually
//run somewhere in frontend to update all data
//dont foget to delete this function after use
export const tryUpdateALLRidePlan = async () => {
  let isNextToken = true;
  let items = [];
  let nextToken = undefined;
  while (isNextToken) {
    const res = await tryListRidePlan(100, {}, nextToken);
    items = items.concat(res.items);
    if (res.nextToken === null) {
      isNextToken = false;
    } else {
      nextToken = res.nextToken;
    }
  }
  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    //do what you want to do with item
    // if (!items[i].isTemp) {
    //   const res = await API.graphql(graphqlOperation(updateRidePlan, { input: { id: item.id, isTemp: false } }));
    //   console.log(res);
    // }
  }
};
