import React from "react";
import styles from "../assets/css/footer.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

export const Footer = () => {
  const location = useLocation();
  if (!location.pathname.match(/create-plan/)) {
    return (
      <div id="footer">
        <footer>
          <div className={styles.footerBox}>
            <div className={styles.footerItem}>
              <div className={styles.footerIconBox}>
                <a href="https://www.instagram.com/pobicle_tabi_cycle/" target="_blank" rel="noreferrer">
                  <FontAwesomeIcon icon={["fab", "fa-instagram"]} className={styles.faInstagram} />
                </a>
              </div>
              <div className={styles.footerBlock1}>
                <HashLink to={"/#whatYouCan"}>pobicleでできること</HashLink>
                <Link to={"/create-plan"}>プランをつくる</Link>
                <Link to={"/find-plan"}>プランをさがす</Link>
              </div>
              <div className={styles.footerBlock2}>
                <li>
                  <Link to={"/user-policy"}>利用規約</Link>
                </li>

                <li>
                  <Link to={"/communtity-policy"}>コミュニティポリシー</Link>
                </li>
                <li>
                  <a href="https://asterone.co.jp/" target="_blank" rel="noopener noreferrer">
                    運営会社
                  </a>
                </li>
              </div>
            </div>
          </div>

          {/* for mobile */}
          <div className={styles.mobileFooterBox}>
            <div className={styles.footerIconBox}>
              <a href="https://www.instagram.com/pobicle_tabi_cycle/" target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={["fab", "fa-instagram"]} className={styles.faInstagram} />
              </a>
            </div>

            <div className={styles.footerMobileBlock1}>
              <HashLink to={"/#whatYouCan"}>pobicleでできること</HashLink>
              <Link to={"/create-plan"}>プランをつくる</Link>
              <Link to={"/find-plan"}>プランをさがす</Link>
            </div>
            <div className={styles.footerMobileBlock2}>
              <li>
                <Link to={"/communtity-policy"}>コミュニティポリシー</Link>
              </li>
              <li>
                <Link to={"/user-policy"}>利用規約</Link>
              </li>
              <div className={styles.footerMobileBlock}>
                <a href="https://asterone.co.jp/" target="_blank" rel="noopener noreferrer">
                  運営会社
                </a>
              </div>
            </div>
          </div>
          <div id={styles.copyright}>
            <a href="https://asterone.co.jp/" target="_blank" rel="noopener noreferrer">
              Copyright © ASTERONE. All Rigths Reserved.
            </a>
          </div>
        </footer>
      </div>
    );
  } else return null;
};
