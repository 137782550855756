//placeIdで場所の名前を取得する
export const getPlaceName = async (placeId, mapInstance) => {
  return new Promise(function (resolve, reject) {
    const service = new window.google.maps.places.PlacesService(mapInstance);
    const request = {
      placeId: placeId,
      fields: [
        //BASIC planのみ、それ以外の項目を取得する場合は追加料金がかかる:https://cloud.google.com/maps-platform/pricing/sheet/?_ga=2.100846854.666326393.1631611256-157179951.1617258437
        "address_component",
        "adr_address",
        "business_status",
        "formatted_address",
        "geometry",
        "icon",
        "icon_mask_base_uri",
        "icon_background_color",
        "name",
        "photo",
        "place_id",
        "plus_code",
        "type",
        "url",
      ],
    };

    service.getDetails(request, (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        if (place) {
          const placeName = place.name ? `${place.name}` : place.formatted_address;
          resolve(placeName);
        } else {
          reject("No results found");
        }
      } else {
        reject(`fail to get Address due to ${status}`);
      }
    });
  });
};

//placeIDがない場合は位置情報から住所を取得する
export const getAddress = (coordinations) => {
  return new Promise(function (resolve, reject) {
    const geocoder = new window.google.maps.Geocoder();
    const callback = (results, status) => {
      if (status === window.google.maps.GeocoderStatus.OK) {
        if (results[0]) {
          const address = results[0].formatted_address;
          resolve(address);
        } else {
          reject("No results found");
        }
      } else {
        reject(`fail to get Address due to ${status}`);
      }
    };
    geocoder.geocode({ location: coordinations }, callback);
  });
};

/**
 * @description 現在地を取得してマップの中央に設定する
 * @param {Function} 位置情報を取得した後に実行する関数
 */
export const getMylocation = (setSomething, something) => {
  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };
  const locSuccess = (position) => setSomething({ lat: position.coords.latitude, lng: position.coords.longitude });
  const locError = (error) => console.error(error);

  navigator.geolocation.getCurrentPosition(locSuccess, locError, options);
  return () => {};
};

/**
 * @description  地図表示領域をマーカー位置に合わせて調節する。
 * @param {Array} markers マーカーの配列
 * @param {Object} map マップinstance
 * @param {useState} setMapCenter mapの中央座標を更新する
 * @return {boolean} 完了したかどうか
 */
export const adjustMapAreaByMarker = (markers, map, setMapCenter) => {
  if (markers.length === 0) {
    return false;
  }
  //マーカーが空白(緯度:0, 経度:0)の場合はそれ以外のマーカーを抽出する
  const preparedMarkers = markers.filter((marker) => {
    return marker.lat !== 0 && marker.lng !== 0;
  });
  //マーカーが1つの場合はそのまま中央に設定する
  if (preparedMarkers.length === 1) {
    setMapCenter({ lat: preparedMarkers[0].lat, lng: preparedMarkers[0].lng });
    return true;
  }
  // マーカーがない場合は、何もせずに終了する
  if (preparedMarkers.length === 0) {
    return false;
  } else {
    const bounds = new window.google.maps.LatLngBounds();
    // 緯度と経度の配列を取得
    const latitudes = preparedMarkers.map((marker) => marker.lat);
    const longitudes = preparedMarkers.map((marker) => marker.lng);

    // 緯度と経度の最小・最大値を取得
    const minLat = Math.min(...latitudes);
    const minLng = Math.min(...longitudes);
    const maxLat = Math.max(...latitudes);
    const maxLng = Math.max(...longitudes);

    // 緯度と経度の最小・最大値を元に、表示領域の境界点を設定
    const borderPointPair = [
      { lat: minLat, lng: minLng - 0.02 }, //経度を下げ、全体を少し右にずらす
      { lat: maxLat, lng: maxLng },
    ];
    // 地図表示領域を表示領域の境界点に合わせて拡大
    borderPointPair.forEach((p) => {
      bounds.extend(p);
    });

    // 地図表示領域の変更を反映
    map.fitBounds(bounds);
    setMapCenter({ lat: map.getCenter().lat(), lng: map.getCenter().lng() });
    return true;
  }
};
/**
 * @description  地図表示領域をルートに合わせて調節する。
 * @param {Array} routePath ルートの配列
 * @param {Object} map マップinstance
 * @param {useState} setMapCenter mapの中央座標を更新する
 * @return {boolean} 完了したかどうか
 */
export const adjustMapAreaByRoute = (routePath, map, setMapCenter) => {
  const bounds = new window.google.maps.LatLngBounds();
  // 地図表示領域をルート全体に合わせて拡大
  routePath.forEach((p) => {
    bounds.extend(p);
  });

  // 地図表示領域の変更を反映
  map.fitBounds(bounds);
  setMapCenter({ lat: map.getCenter().lat(), lng: map.getCenter().lng() });
  return true;
};

/**
 * @description  実施地域の入力に合わせて位置情報を取得する。
 * @param {Array} area 地域名cityもしくはprefecture
 * * @return {latlng}位置情報
 */
export const getLocationByArea = (areaArray) => {
  for (let i = 0; i < areaArray.length; i++) {
    //BUG ページリロード時に読み込めないバグerror:Cannot read properties of undefined (reading 'maps')
    //TODO mapを読み込んだ後に実行するようにするonLoad
    const geocoder = new window.google.maps.Geocoder();
    if (!areaArray[i]) continue;
    const area = areaArray[i];
    return new Promise(function (resolve, reject) {
      geocoder.geocode({ address: area }, (results, status) => {
        if (status === window.google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            resolve({ lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng() });
          } else resolve(null);
        } else {
          reject(results);
        }
      });
      return;
    });
  }
};
