import React, { useState, memo, useEffect, useCallback } from "react";
import { InputScheduleDetail } from "./InputScheduleDetail";
import { ScheduleType } from "../../constants/global.js";
import { useScheduleInput } from "../../hooks/useScheduleInput.js";

export const EditScheduleModal = memo(
  ({
    setShowSpotList,
    setShowListMode,
    setSelectedScheduleIndex,
    showScheduleModal,
    setShowScheduleModal,
    areaCenter,
    handleTextChange,
    selectedScheduleIndex,
    planInfo,
    setPlanInfo,
    attachedSpot,
    setAttachedSpot,
    setSelectedSpot,
    selectedSpot,
    clickedLocation,
    setTempSaveStatus,
  }) => {
    const { scheduleInput, setScheduleInput, handleScheduleURLChange, resetScheduleInput } = useScheduleInput();
    const [selectedSchedule, setSelectedSchedule] = useState(null);
    useEffect(() => {
      if (selectedScheduleIndex === null) return;
      const selectedSchedule = planInfo.scheduleList[selectedScheduleIndex];
      setSelectedSchedule(selectedSchedule);
      // スケジュールに付与するスポット情報をセット
      const inputSpot = selectedSpot ? selectedSpot : attachedSpot;
      setAttachedSpot(inputSpot);

      //  スケジュール入力内容の初期stateをset
      const schedule = selectedSchedule;

      if (inputSpot || clickedLocation) {
        // 選択されたスケジュールを取得
        const spotId = inputSpot && inputSpot.id ? inputSpot.id : null;
        const { latLng, address } = inputSpot ? inputSpot : clickedLocation;
        setScheduleInput({
          ...schedule,
          //位置情報のセット
          latLng: latLng,
          address: address,
          spotId: spotId,
          place: null, //旧バージョンのplaceデータを削除
        });
        return;
      } else {
        setScheduleInput(schedule);
      }
    }, [selectedScheduleIndex, selectedSpot, clickedLocation]);

    useEffect(() => {
      if (showScheduleModal) {
        document.body.style.overflowY = "hidden";
      }
    }, [showScheduleModal]);

    /** スケジュールの編集 */
    const editSchedule = useCallback(
      (schedule, selectedScheduleIndex) => {
        const scheduleList = planInfo.scheduleList;
        scheduleList[selectedScheduleIndex] = schedule;
        setPlanInfo({ ...planInfo, scheduleList });
      },
      [planInfo]
    );

    /** 確定ボタンが押されたときの処理 */
    const onClickChangeButton = (newImageObj) => {
      let newImage = null;
      //変更がある場合
      if (newImageObj.file && newImageObj.url) {
        newImage = newImageObj;
      }
      //画像が削除された場合
      else if (newImageObj.file === "" && newImageObj.url === "") {
        newImage = { file: "", url: "", id: newImageObj.id };
      }
      //画像の変更がない場合
      else if (newImageObj.file === null && newImageObj.url === null) {
        newImage = {
          file: selectedSchedule.image.file ? selectedSchedule.image.file : null,
          url: selectedSchedule.image.url ? selectedSchedule.image.url : null,
          id: newImageObj.id,
        };
      } else {
        newImage = { file: null, url: null };
      }
      //リンク先URLのトリミング
      const urls = scheduleInput.url.filter((u) => !(u.trim() === ""));
      //スケジュールのセット
      editSchedule(
        {
          ...scheduleInput,
          url: urls,
          image: newImage,
        },
        selectedScheduleIndex
      );

      //下書き保存ボタン表示
      setTempSaveStatus(false);

      //値の初期化
      setShowListMode(true);
      resetScheduleInput();
      setSelectedScheduleIndex(null);
      setShowScheduleModal(false);
      setShowSpotList(false);
      setAttachedSpot(null);
      setSelectedSpot(null);
      document.body.style.overflowY = "scroll";
    };

    const onClickClose = () => {
      resetScheduleInput();
      setAttachedSpot(null);
      setSelectedSpot(null);
      setShowScheduleModal(false);
    };

    return (
      <div>
        {showScheduleModal ? (
          <InputScheduleDetail
            planInfo={planInfo}
            setPlanInfo={setPlanInfo}
            scheduleInput={scheduleInput}
            setScheduleInput={setScheduleInput}
            handleTextChange={handleTextChange}
            handleScheduleURLChange={handleScheduleURLChange}
            onClickButton={onClickChangeButton}
            scheduleType={ScheduleType.EDITING}
            onClickClose={onClickClose}
            areaCenter={areaCenter}
            attachedSpot={attachedSpot}
          />
        ) : null}
      </div>
    );
  }
);
