import { useState } from "react";
export const useSpotMarker = () => {
  const [spotMarkers, setSpotMarkers] = useState([]);
  /**
   * 
   * @param {array} spotLatLngList is list of spot which contain latlng object.
   * @param {number} spotIndex is index of spot which is selected.
   * @returns void
   */

  
  const addNewSpotMarker = (spotLatLngList) => {
    // add new marker
    const lat = spotLatLngList[spotLatLngList.length - 1].latLng.lat;
    const lng = spotLatLngList[spotLatLngList.length - 1].latLng.lng;
    setSpotMarkers([...spotMarkers, { lat, lng }]);
  };

  const deleteSpotMarker = (spotIndex) => {
    // 削除したスポットのマーカーを削除
    const newMarkers = spotMarkers.filter((marker, index) => index !== spotIndex);
    setSpotMarkers(newMarkers);
  };
  return {
    spotMarkers,
    setSpotMarkers,
    addNewSpotMarker,
    deleteSpotMarker,
  };
};
