import { API, graphqlOperation } from "aws-amplify";

import { createPlanMembers } from "../../graphql/mutations";
import { listPlanMembers } from "../../graphql/queries";

/**
 * create a record user joined a planroom.
 * @param {string} ridePlanID plan id.
 * @param {string} userID user id(sub).
 * @returns {object | error} A result of create PlanMember.
 */
export const tryCreatePlanMembers = async (ridePlanID, userID) => {
  try {
    const result = await API.graphql(
      graphqlOperation(createPlanMembers, { input: { ridePlanID: ridePlanID, userID: userID } })
    );
    return result.data.getUser;
  } catch (e) {
    console.error("Failed get info");
    throw e;
  }
};
/**
 * list a PlanMembers filterd by ridePlanID, userID.
 * @param {string} ridePlanID plan id.
 * @param {string} userID user id(sub).
 * @returns {Array | error} list of planmebers records.
 */
export const tryListPlanMembers = async (ridePlanID, userID = null) => {
  let filter = null;
  if (userID) {
    filter = {
      ridePlanID: { eq: ridePlanID },
      userID: { eq: userID },
    };
  } else {
    filter = {
      ridePlanID: { eq: ridePlanID },
    };
  }
  try {
    const result = await API.graphql(
      graphqlOperation(listPlanMembers, {
        filter: filter,
      })
    );
    return result.data.listPlanMembers.items;
  } catch (e) {
    console.error("Failed get info");
    throw e;
  }
};

/**
 * list RidePlans using listPlanMembers filterd by userID from PlanMemberstable.
 * @param {string} userID user id(sub).
 * @returns {Array | error} list of planmebers records.
 */
export const tryListRidePlansFromPlanMembers = async (userID) => {
  try {
    const result = await API.graphql(
      graphqlOperation(listPlanMembers, {
        filter: {
          userID: { eq: userID },
        },
      })
    );
    return result.data.listPlanMembers.items;
  } catch (e) {
    console.error("Failed get info");
    throw e;
  }
};
