import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "../assets/css/communityPolicy.module.css";
import people1 from "../assets/img/illust/people1.png";
import people2 from "../assets/img/illust/people2.png";
import people3 from "../assets/img/illust/people3.png";
import people4 from "../assets/img/illust/people4.png";

export const CommunityPolicy = () => {
  return (
    <div id="container">
      <h1 id="pageTitle" className={styles.pageTitleClass}>
        コミュニティポリシー
      </h1>
      <div className={styles.communityPolicyContent}>
        <h3>pobicleが大切にしたいこと</h3>
        <div className={styles.wantToValue}>
          <p>「自転車仲間、ほしいなあ」</p>

          <p>pobicleのはじまりは、自転車と旅が好きなプログラマーのつぶやきでした。</p>
          <p>就職してやってきた新しい街。 休日のひとり旅で気になるお店を見つけても、一人ではなんだか入りにくい。</p>
          <p>こんな時、仲間と楽しめたら、いいのにな。</p>

          <p>
            でも不安だってある。一緒に旅に行くって、意外とめんどくさい。誘いにくいし、断られたら凹むし、ペースが合わないかも。
          </p>
          <p>「次の休みどこ行く？」「あそこのカレー屋気になってて」「浜名湖一周したくて」。</p>
          <p>そんな自転車と旅で気軽にできる楽しみ方を共有できる人たちの集まりがつくれたら。</p>
          <p>そうして生まれたのがpobicle。"people on bicycle"の頭文字からつけました。 </p>
          <div className={styles.imgBox}>
            <img src={people1} alt="自転車に乗る人のイラスト" className={styles.imgPeople1}></img>
            <img src={people2} alt="自転車に乗る人のイラスト" className={styles.imgPeople2}></img>
          </div>
        </div>
        <h3>pobicleを使ってくれるみなさんへのお願いです</h3>
        <div className={styles.pleaseBox}>
          <h4>みんなで、いい旅をつくろう</h4>
          <p>
            「ここで夕焼けを見たい」「自転車であのカフェに行きたい」。一人の小さなアイデアから、旅のプランは生まれます。その思いを、ホストも参加者も、みんなで形にして楽しもう。pobicleに集う人たちは一緒に旅をつくりあげる仲間です。
          </p>
          <h4>わかりやすく、やさしく伝えよう</h4>
          <p>
            「はじめまして」の人から顔なじみの人まで、pobicleには色々な仲間が集まります。「自転車はじめたばかりで、、」「次、右折します」「ここはあぶないから気を付けよう。」
            <br />
            いい自転車旅の秘訣は、自分の気持ちを相手にわかりやすく伝えること。相手を尊重して接することを心がけよう。
          </p>
        </div>
        <h3>では、出発。</h3>
        <h3 className={styles.lastCopy}>仲間と自転車に乗って、新しい景色を見つけに行こう。</h3>
        <div className={styles.imgBox}>
          <img src={people3} alt="自転車に乗る人のイラスト" className={styles.imgPeople3}></img>
          <img src={people4} alt="自転車に乗る人のイラスト" className={styles.imgPeople4}></img>
        </div>
        <div className={styles.enjoyBox}>
          <Link to={"/find-plan"}>
            <button type="button" id="defaultButton" className={styles.participateButton}>
              プランをさがす
              <FontAwesomeIcon icon={["fa", "angle-right"]} className="faAngle" />
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};
// export default CommunityPolicy;
