import React, { useEffect, useContext } from "react";
import { SpotType } from "../../constants/global";
import { InputSpotDetail } from "./InputSpotDetail";
import { globalUserContext } from "../../globalContext";
import { tryUpdateSpot } from "../../helper/dbAccess/spotAccess";

export const EditSpotModal = ({
  setMutateSpotDone,
  setShowAddSpotModal,
  setTempMarker,
  showSpotModal,
  setShowListMode,
  setShowSpotList,
  setSearchText,
  spotInput,
  setSpotInput,
  resetSpotInput,
  showEditSpotModal,
  setShowEditSpotModal,
  selectedSpot,
  setSelectedSpot,
  setClickedLocation,
}) => {
  const { userInfo } = useContext(globalUserContext);

  useEffect(() => {
    if (showSpotModal) {
      document.body.style.overflowY = "hidden";
    }
  }, [showSpotModal]);

  useEffect(() => {
    if (selectedSpot) {
      setSpotInput({
        ...spotInput,
        name: selectedSpot?.name,
        address: selectedSpot?.address,
        googlePlaceId: selectedSpot?.googlePlaceId,
        latLng: selectedSpot?.latLng,
        category: selectedSpot?.category,
        memo: selectedSpot?.memo,
        disclosureRange: selectedSpot?.disclosureRange,
        imageKeys: selectedSpot?.imageKeys,
        url: selectedSpot?.url,
      });
    }
  }, [selectedSpot, showEditSpotModal]);

  const editSpot = async (imageKeys) => {
    try {
      const newSpotInfo = {
        id: selectedSpot?.id,
        creatorId: userInfo.attributes.sub,
        category: spotInput.category ? spotInput.category : "その他",
        name: spotInput.name,
        address: spotInput.address,
        memo: spotInput.memo,
        disclosureRange: spotInput.disclosureRange,
        url: spotInput.url,
        imageKeys: imageKeys,
      };
      await tryUpdateSpot(newSpotInfo);
    } catch (e) {
      console.error("error", e);
    }
  };

  const onClickAddButton = async (imageKeys) => {
    await editSpot(imageKeys);
    //スポットの追加が完了したら、スポット一覧を表示する
    setShowListMode(true);
    setShowSpotList(true);
    //スポットの変更完了フラグ
    setMutateSpotDone(true);
    //値の初期化
    resetSpotInput();
    setClickedLocation(null);
    setTempMarker(null);
    setShowEditSpotModal(false);
    setSelectedSpot(null);
    document.body.style.overflowY = "scroll";
  };

  const onClickClose = () => {
    document.body.style.overflowY = "scroll";
    setShowAddSpotModal(false);
    setShowEditSpotModal(false);
    resetSpotInput();
  };
  return (
    <div>
      {showEditSpotModal ? (
        <div>
          <InputSpotDetail
            selectedSpot={selectedSpot}
            spotType={SpotType.EDITING}
            onClickButton={onClickAddButton}
            onClickClose={onClickClose}
            setSearchText={setSearchText}
            spotInput={spotInput}
            setSpotInput={setSpotInput}
            setShowAddSpotModal={setShowAddSpotModal}
          />
        </div>
      ) : null}
    </div>
  );
};
