// ページスクロール時のアニメーション(header)
export const newScrollObserver = () => {
  let options = {
    root: null,
    rootMargin: "0px 0px -30% 0px",
    threshold: 0,
  };
  const callback = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add("fadeIn");
        // observer.unobserve(entry.targrt); //todo warning fix
      }
      //アニメーションをもとの状態に戻す処理 todo bug fix
      // } else {
      //   console.log("not entries",entry.boundingClientRect);
      //   if(entry.boundingClientRect.y < 0) {
      //     entry.target.classList.remove("fadeIn");
      //   }
      // }
    });
  };
  //インスタンスを作成
  const observer = new IntersectionObserver(callback, options);

  //シンプルなフェードイン
  const fadeInList = document.querySelectorAll("#scrollFadeIn");
  if (fadeInList.length > 0) {
    fadeInList.forEach((box) => {
      observer.observe(box);
    });
  } else {
    return;
  }
  // // 左からのフェードイン
  // const fadeInLeftList = document.querySelectorAll("#scrollFadeInLeft");
  // if (fadeInLeftList.length > 0) {
  //   fadeInLeftList.forEach((box) => {
  //     observer.observe(box);
  //   });
  // } else {
  //   return;
  // }
};

//Framer Motion Variants
export const slideUp = {
  hidden: {
    y: "100vh",
    opacity: 0,
  },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 0.5,
      type: "spring",
      bounce: 0.1,
      
    },
  },
  exit: {
    opacity: 0,
    y: "100vh",
    transition: {
      duration: 0.7,
      type: "spring",
      bounce: 0.1,
    },
  },
};
export const popUp = {
  hidden: {
    
    opacity: 0,
  },
  visible: {
   
    opacity: 1,
    transition: {
      duration: 0.5,
      type: "spring",
      bounce: 0.1,
      
    },
  },
  exit: {
    opacity: 0,
   
    transition: {
      duration: 0.7,
      type: "spring",
      bounce: 0.1,
    },
  },
};
