import React, { useState, useEffect, useCallback } from "react";
import { ScheduleMap } from "../map/MapRender.js";
import styles from "../../assets/css/inputScheduleInfo.module.css";
import { getLocationByArea } from "../../helper/geoLocation.js";
import { tryGetS3URL, tryUploadFile } from "../../helper/s3Access.js";
import { useSpotMarker } from "../../hooks/useSpotMarker.js";
import { useScheduleMarker } from "../../hooks/useScheduleMarker.js";
import { useSpotInput } from "../../hooks/useSpotInput.js";
import { SideSheet } from "./InfoSheet/SideSheet.js";
import { AddScheduleModal } from "../schedule/AddScheduleModal.js";
import { EditScheduleModal } from "../schedule/EditScheduleModal.js";
import { EditSpotModal } from "../spot/EditSpotModal.js";
import { AddSpotModal } from "../spot/AddSpotModal.js";
import { ExistingScheduleModal } from "../schedule/ExistingScheduleModal.js";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BottomSheet from "./InfoSheet/BottomSheet.js";
export const InputScheduleInfo = ({
  planInfo,
  setPlanInfo,
  S3Keys,
  setS3Keys,
  showEditScheduleModal,
  setShowEditScheduleModal,
  showAddScheduleModal,
  setShowAddScheduleModal,
  areaCenter,
  setAreaCenter,
  routeDataUrl,
  setRouteDataUrl,
  routeKeysWillBeDeleted,
  setRouteKeysWillBeDeleted,
  showSchedulePage,
  setTempSaveStatus,
  showPreview,
}) => {
  const [tempMarker, setTempMarker] = useState(null);
  const [showSpotList, setShowSpotList] = useState(false);
  const [spotList, setSpotList] = useState([]);
  const [showListMode, setShowListMode] = useState(true);
  const [searchText, setSearchText] = useState(null);
  const [selectedSpot, setSelectedSpot] = useState(null);
  const [selectedScheduleIndex, setSelectedScheduleIndex] = useState(null);
  const [isScheduleCard, setIsScheduleCard] = useState(true);
  const [clickedLocation, setClickedLocation] = useState(null);
  const [mutateSpotDone, setMutateSpotDone] = useState(false);
  const [showAddSpotModal, setShowAddSpotModal] = useState(false);
  const [showEditSpotModal, setShowEditSpotModal] = useState(false);
  const [showExistingScheduleModal, setShowExistingScheduleModal] = useState(false);
  const [showSideSheet, setShowSideSheet] = useState(true);
  const [attachPlaceMode, setAttachPlaceMode] = useState(false);
  const [attachedSpot, setAttachedSpot] = useState(null);
  const { spotInput, setSpotInput, resetSpotInput } = useSpotInput();
  const { spotMarkers, setSpotMarkers, deleteSpotMarker } = useSpotMarker();
  const { scheduleMarkers, setScheduleMarkers, addNewScheduleMarker, deleteScheduleMarker } = useScheduleMarker();

  // マップの中央を実施場所でセット
  useEffect(() => {
    (async () => {
      try {
        let location = "";
        setAreaCenter(null);
        if (planInfo.city || planInfo.prefecture) {
          const areaArray = [planInfo.city ? planInfo.city : "", planInfo.prefecture ? planInfo.prefecture : ""];
          location = await getLocationByArea(areaArray);
          if (location) {
            setAreaCenter(location);
            return;
          } else return;
        }
      } catch (error) {
        console.error(error);
        return;
      }
    })();
  }, [planInfo.city, planInfo.prefecture, spotMarkers]);

  useEffect(() => {
    //初回読み込み時とスポット増減時にスポットマーカーの再セット
    if (spotList.length > 0) setSpotMarkers(spotList.map((spot) => spot.latLng));
    else if (spotList.length === 0) {
      setSpotMarkers([]);
    }
  }, [showSpotList, spotList, setSpotMarkers]);

  useEffect(() => {
    if (showSpotList) return;
    //初回読み込み時とスケジュール並び替え時にスケジュールマーカーの再セット
    if (planInfo.scheduleList.length > 0) {
      setScheduleMarkers(
        planInfo.scheduleList.map((schedule) => (schedule.latLng ? schedule.latLng : { lat: 0, lng: 0 }))
      );
    }
  }, [showSpotList, planInfo, setScheduleMarkers]);

  useEffect(() => {
    //planInfo.routeDataKeyがある場合はtryGetS3URLしてルートデータを取得
    if (planInfo.routeDataKey && routeDataUrl === null) {
      (async () => {
        const routeDataUrl = await tryGetS3URL(planInfo.routeDataKey);
        setRouteDataUrl(routeDataUrl);
      })();
    } else return;
  }, [planInfo.routeDataKey, routeDataUrl]);

  //スケジュールリストを表示
  const toggleListMode = () => {
    setShowSpotList(!showSpotList);
  };

  const handleRouteDataFileChange = async (event) => {
    try {
      //既にファイルが選択されている場合、keyを使ってS3から削除
      if (planInfo.routeDataKey) {
        setRouteDataUrl(null);
        routeKeysWillBeDeleted.push(planInfo.routeDataKey);
        setRouteKeysWillBeDeleted(routeKeysWillBeDeleted);
      }
      const file = event.target.files[0];
      setRouteDataUrl(URL.createObjectURL(file));
      const filePath = "routeData/";
      //todo プラン保存時にアップロードするようにする
      const result = await tryUploadFile(file, filePath);
      setTempSaveStatus(false);
      setPlanInfo({
        ...planInfo,
        routeDataKey: result.key,
      });
    } catch (error) {
      await tryUploadFile(planInfo.routeDataKey);
      console.error(error);
      return;
    }
  };

  const handleClearRouteDataUpload = async () => {
    // ファイル選択を解除する
    const routeDataFileInput = window.document.getElementById("routeDataFile");
    try {
      //既にファイルが選択されている場合、保存確定時にS3から削除するためにkeyを保持しておく
      routeKeysWillBeDeleted.push(planInfo.routeDataKey);
      setRouteKeysWillBeDeleted(routeKeysWillBeDeleted);
      //ファイル選択を解除
      routeDataFileInput.value = "";
      setTempSaveStatus(false);
      setRouteDataUrl("");
      setPlanInfo({
        ...planInfo,
        routeDataKey: "",
      });
    } catch (error) {
      alert("ファイルの削除に失敗しました。");
      console.error(error);
    }
  };

  const addPlaceToSchedule = () => {
    /** スケジュールに位置情報を付与 */
    const editSchedule = () => {
      const { latLng, address, id } = selectedSpot ? selectedSpot : clickedLocation;
      const attachedPlace = {
        //位置情報のセット
        latLng: latLng,
        address: address,
        spotId: id ? id : null,
        place: null, //旧バージョンのデータを削除
      };
      //スケジュールリスト
      const scheduleList = planInfo.scheduleList;

      //選択されたスケジュール
      const selectedSchedule = scheduleList[selectedScheduleIndex];

      //スケジュールに位置情報を付与
      const editedSchedule = { ...selectedSchedule, ...attachedPlace };

      //スケジュールのセット
      scheduleList[selectedScheduleIndex] = editedSchedule;
      setPlanInfo({ ...planInfo, scheduleList });
    };
    editSchedule();
    setTempSaveStatus(false);
    setAttachPlaceMode(false);
  };

  const deleteSchedule = (index) => {
    if (window.confirm("このスケジュールを削除します。よろしいですか?")) {
      //スケジュールリストの削除
      planInfo.scheduleList.splice(index, 1);
      setPlanInfo({ ...planInfo });
      //マーカーの削除
      deleteScheduleMarker(index);

      //選択中のスケジュールが削除された場合、スケジュールカードを非表示にする
      if (selectedScheduleIndex === index) {
        setSelectedScheduleIndex(null);
        setTempMarker(null);
        setShowListMode(true);
      }
    } else return;
  };
  if (showSchedulePage) {
    return (
      <div className={styles.inputScheduleBox}>
        <div id="inputScheduleBox"></div>
        <div className={styles.scheduleMapBox}>
          <div className={styles.mapMenuBox}>
            {attachPlaceMode ? (
              <div className={styles.mapMenuContents}>
                <div className={styles.backButtonBox}>
                  <button type="button" onClick={() => setAttachPlaceMode(false)} className={styles.backButton}>
                    <FontAwesomeIcon icon={["fa", "angle-left"]} className="faAngle" />
                    <span>戻る</span>
                  </button>
                </div>
                <div className={styles.instructionBox}>
                  <div>位置情報選択モード</div>
                </div>
              </div>
            ) : (
              <div className={styles.mapMenuContents}>
                <div className={styles.routeDataFileUpload}>
                  <label htmlFor="routeDataFile">ルート情報をインポート(.gpx)</label>
                  <div>
                    <input
                      className={styles.importButton}
                      id="routeDataFile"
                      type="file"
                      accept=".gpx"
                      onChange={handleRouteDataFileChange}
                    />
                    {planInfo.routeDataKey && (
                      <button type="button" onClick={handleClearRouteDataUpload} className={styles.clearButton}>
                        &#x2715;
                      </button>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className={styles.scheduleMapBox}>
            <div className={styles.scheduleMap}>
              <ScheduleMap
                selectedSpot={selectedSpot}
                setSelectedSpot={setSelectedSpot}
                showSpotList={showSpotList}
                spotList={spotList}
                setClickedLocation={setClickedLocation}
                clickedLocation={clickedLocation}
                setShowListMode={setShowListMode}
                routeDataUrl={routeDataUrl}
                spotMarkers={spotMarkers}
                areaCenter={areaCenter}
                tempMarker={tempMarker}
                setTempMarker={setTempMarker}
                selectedScheduleIndex={selectedScheduleIndex}
                setSelectedScheduleIndex={setSelectedScheduleIndex}
                scheduleMarkers={scheduleMarkers}
                setIsScheduleCard={setIsScheduleCard}
                searchText={searchText}
                setSearchText={setSearchText}
                scheduleList={planInfo.scheduleList}
                attachPlaceMode={attachPlaceMode}
              />
            </div>
          </div>
          {/* PC/タブレット */}
          <motion.div
            className={styles.sideSheetContainer}
            animate={{ x: showSideSheet ? "100%" : 0 }}
            transition={{ duration: 0.5, type: "spring", bounce: 0.1 }}
          >
            <button
              type="button"
              className={styles.toggleListButton}
              onClick={() => {
                setShowSideSheet(!showSideSheet);
              }}
            ></button>
            <SideSheet
              showListMode={showListMode}
              setShowListMode={setShowListMode}
              selectedScheduleIndex={selectedScheduleIndex}
              attachPlaceMode={attachPlaceMode}
              showSpotList={showSpotList}
              toggleListMode={toggleListMode}
              setAttachPlaceMode={setAttachPlaceMode}
              setShowEditSpotModal={setShowEditSpotModal}
              setShowAddSpotModal={setShowAddSpotModal}
              setShowExistingScheduleModal={setShowExistingScheduleModal}
              setAttachedSpot={setAttachedSpot}
              attachedSpot={attachedSpot}
              clickedLocation={clickedLocation}
              setClickedLocation={setClickedLocation}
              deleteSchedule={deleteSchedule}
              addPlaceToSchedule={addPlaceToSchedule}
              setShowAddScheduleModal={setShowAddScheduleModal}
              setShowEditScheduleModal={setShowEditScheduleModal}
              setSelectedScheduleIndex={setSelectedScheduleIndex}
              deleteScheduleMarker={deleteScheduleMarker}
              mutateSpotDone={mutateSpotDone}
              setMutateSpotDone={setMutateSpotDone}
              spotList={spotList}
              setSpotList={setSpotList}
              selectedSpot={selectedSpot}
              setIsScheduleCard={setIsScheduleCard}
              isScheduleCard={isScheduleCard}
              showAddScheduleModal={showAddScheduleModal}
              planInfo={planInfo}
              setPlanInfo={setPlanInfo}
              S3Keys={S3Keys}
              setS3Keys={setS3Keys}
              areaCenter={areaCenter}
              setSelectedSpot={setSelectedSpot}
              deleteSpotMarker={deleteSpotMarker}
              setTempMarker={setTempMarker}
              setShowSpotList={setShowSpotList}
              setTempSaveStatus={setTempSaveStatus}
              setSearchText={setSearchText}
            />
          </motion.div>
          {/* モバイル */}
          {showAddScheduleModal ||
          showEditScheduleModal ||
          showEditSpotModal ||
          showAddSpotModal ||
          showExistingScheduleModal ||
          showPreview ? null : (
            <div className={styles.bottomSheetContainer}>
              <BottomSheet
                showListMode={showListMode}
                setShowListMode={setShowListMode}
                selectedScheduleIndex={selectedScheduleIndex}
                attachPlaceMode={attachPlaceMode}
                showSpotList={showSpotList}
                toggleListMode={toggleListMode}
                setAttachPlaceMode={setAttachPlaceMode}
                setShowEditSpotModal={setShowEditSpotModal}
                setShowAddSpotModal={setShowAddSpotModal}
                setShowExistingScheduleModal={setShowExistingScheduleModal}
                setAttachedSpot={setAttachedSpot}
                attachedSpot={attachedSpot}
                clickedLocation={clickedLocation}
                setClickedLocation={setClickedLocation}
                deleteSchedule={deleteSchedule}
                addPlaceToSchedule={addPlaceToSchedule}
                setShowAddScheduleModal={setShowAddScheduleModal}
                setShowEditScheduleModal={setShowEditScheduleModal}
                setSelectedScheduleIndex={setSelectedScheduleIndex}
                deleteScheduleMarker={deleteScheduleMarker}
                mutateSpotDone={mutateSpotDone}
                setMutateSpotDone={setMutateSpotDone}
                spotList={spotList}
                setSpotList={setSpotList}
                selectedSpot={selectedSpot}
                setIsScheduleCard={setIsScheduleCard}
                isScheduleCard={isScheduleCard}
                showAddScheduleModal={showAddScheduleModal}
                planInfo={planInfo}
                setPlanInfo={setPlanInfo}
                S3Keys={S3Keys}
                setS3Keys={setS3Keys}
                areaCenter={areaCenter}
                setSelectedSpot={setSelectedSpot}
                deleteSpotMarker={deleteSpotMarker}
                setTempMarker={setTempMarker}
                setShowSpotList={setShowSpotList}
                setTempSaveStatus={setTempSaveStatus}
                setSearchText={setSearchText}
              />
            </div>
          )}
          {/* スケジュール追加モーダル */}
          {showAddScheduleModal && (
            <AddScheduleModal
              planInfo={planInfo}
              setPlanInfo={setPlanInfo}
              showScheduleModal={showAddScheduleModal}
              setShowAddScheduleModal={setShowAddScheduleModal}
              addNewScheduleMarker={addNewScheduleMarker}
              setTempMarker={setTempMarker}
              selectedSpot={selectedSpot}
              setSelectedSpot={setSelectedSpot}
              setShowSpotList={setShowSpotList}
              clickedLocation={clickedLocation}
              setShowListMode={setShowListMode}
              setAttachedSpot={setAttachedSpot}
              attachedSpot={attachedSpot}
              setTempSaveStatus={setTempSaveStatus}
            />
          )}
          {/* スケジュール編集モーダル */}
          {showEditScheduleModal && (
            <EditScheduleModal
              setShowSpotList={setShowSpotList}
              setShowListMode={setShowListMode}
              setSelectedScheduleIndex={setSelectedScheduleIndex}
              showScheduleModal={showEditScheduleModal}
              setShowScheduleModal={setShowEditScheduleModal}
              areaCenter={areaCenter}
              selectedScheduleIndex={selectedScheduleIndex}
              planInfo={planInfo}
              setPlanInfo={setPlanInfo}
              attachedSpot={attachedSpot}
              setAttachedSpot={setAttachedSpot}
              selectedSpot={selectedSpot}
              setSelectedSpot={setSelectedSpot}
              clickedLocation={clickedLocation}
              setTempSaveStatus={setTempSaveStatus}
            />
          )}
          <EditSpotModal
            setMutateSpotDone={setMutateSpotDone}
            setShowAddSpotModal={setShowAddSpotModal}
            setTempMarker={setTempMarker}
            showSpotModal={showAddScheduleModal}
            setShowListMode={setShowListMode}
            setShowSpotList={setShowSpotList}
            setSearchText={setSearchText}
            spotInput={spotInput}
            setSpotInput={setSpotInput}
            resetSpotInput={resetSpotInput}
            showEditSpotModal={showEditSpotModal}
            setShowEditSpotModal={setShowEditSpotModal}
            selectedSpot={selectedSpot}
            setSelectedSpot={setSelectedSpot}
            setClickedLocation={setClickedLocation}
          />
          <AddSpotModal
            setMutateSpotDone={setMutateSpotDone}
            clickedLocation={clickedLocation}
            showAddSpotModal={showAddSpotModal}
            setShowAddSpotModal={setShowAddSpotModal}
            setTempMarker={setTempMarker}
            setShowListMode={setShowListMode}
            showSpotModal={showAddScheduleModal}
            setShowSpotList={setShowSpotList}
            setSearchText={setSearchText}
            spotInput={spotInput}
            setSpotInput={setSpotInput}
            resetSpotInput={resetSpotInput}
            setClickedLocation={setClickedLocation}
          />
          <ExistingScheduleModal
            planInfo={planInfo}
            setSelectedScheduleIndex={setSelectedScheduleIndex}
            showExistingScheduleModal={showExistingScheduleModal}
            setShowExistingScheduleModal={setShowExistingScheduleModal}
            setShowEditScheduleModal={setShowEditScheduleModal}
            setIsScheduleCard={setIsScheduleCard}
          />
        </div>
      </div>
    );
  } else {
    return null;
  }
};
