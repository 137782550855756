import { API, graphqlOperation } from "aws-amplify";
import { getUser} from "../../graphql/queries";
import { updateUser } from "../../graphql/mutations";

/**
 * Fetch a getUser by a user id(sub).
 * @param {string} id user id(sub).
 * @returns {object | error} A fetched user info.
 */
export const tryGetUser = async (id) => {
  try {
    const result = await API.graphql(graphqlOperation(getUser, { id }));
    return result.data.getUser;
  } catch (e) {
    console.error("Failed get info");
    throw e;
  }
};

/**
 * update user data into DynamoDB.
 * @param {object} input is input object including id and property that you want to update. 
 * ex.{id: "text(sub)", nickName: "text", icon: "text(s3key)"}
 * @returns {object | never} result | error
 */
export const tryUpdateUser = async (input) => {
  try {
    const result = await API.graphql(
      graphqlOperation(updateUser, {
        input: input,
      })
    );
    return result.data.updateUser;
  } catch (e) {
    console.error("Failed update data");
    throw e;
  }
};
