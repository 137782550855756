import React, { useContext, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import styles from "../../assets/css/listCard.module.css";
import { globalUserContext } from "../../globalContext";
import { trySpotByCreatorId } from "../../helper/dbAccess/spotAccess";
import { SpotList } from "../spot/SpotList.js";

export const ListCard = ({
  setSelectedScheduleIndex,
  mutateSpotDone,
  setMutateSpotDone,
  spotList,
  setSpotList,
  showListMode,
  setShowListMode,
  showSpotList,
  setIsScheduleCard,
  planInfo,
  setPlanInfo,
  S3Keys,
  setS3Keys,
  setSelectedSpot,
  setTempSaveStatus,
  setSearchText,
  handleOnSortContent = () => {},
  isMobile,
}) => {
  const { userInfo } = useContext(globalUserContext);
  const onDragEnd = (result) => {
    const reorder = (startIndex, endIndex) => {
      let newScheduleList = null;
      let newS3Keys = null;
      //スケジュールの並び替え
      if (planInfo.scheduleList && planInfo.scheduleList.length > 0) {
        newScheduleList = Array.from(planInfo.scheduleList);
        const [removedSchedule] = newScheduleList.splice(startIndex, 1);
        newScheduleList.splice(endIndex, 0, removedSchedule);
      }

      //S3keyの並び替え
      if (S3Keys && S3Keys.length > 0) {
        newS3Keys = Array.from(S3Keys);
        const [removedS3Key] = newS3Keys.splice(startIndex + 1, 1); //[0]はメイン画像なので+1
        newS3Keys.splice(endIndex + 1, 0, removedS3Key);
      }
      return { scheduleList: newScheduleList, S3Keys: newS3Keys };
    };

    handleOnSortContent(false); // ドラッグ中の設定を解除

    const { source, destination } = result;
    if (!destination) return;
    const reorderResult = reorder(source.index, destination.index);
    setTempSaveStatus(false);
    setPlanInfo({ ...planInfo, scheduleList: reorderResult.scheduleList });
    setS3Keys(reorderResult.S3Keys);
  };

  const spotByCreatorId = async () => {
    const creatorId = userInfo?.attributes.sub;
    try {
      const res = await trySpotByCreatorId(creatorId);
      res.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
      setSpotList(res);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (mutateSpotDone || showListMode) {
      spotByCreatorId();
      setMutateSpotDone(false);
    }
  }, [mutateSpotDone, showListMode]);

  const itemStyle = (draggableStyle) => {
    return {
      ...draggableStyle,
      border: "1.5px solid #111111",
      borderWidth: "0 0 1.5px 0",
      userSelect: "none",
      background: "rgb(235, 236, 240)",
      borderRadius: "0px",
    };
  };

  /**
   * ドラッグ中のスタイルを設定する
   * @param {*} isDragging
   * @param {*} draggableStyle
   * @returns object スタイル
   */
  const getDraggingStyle = (draggableStyle) => {
   
    //親要素の高さを取る
    const listContainerRef = isMobile
      ? document.getElementsByClassName("react-modal-sheet-container")[0]//モバイルの場合はbottom sheetの高さを取得
      : document.getElementsByClassName(styles.listContainer)[0];//PCの場合はlistContainerの高さを取得
    const offsetY = listContainerRef.getBoundingClientRect().y;
   
    return {
      ...draggableStyle,
      border: "1.5px solid #111111",
      borderWidth: "1.5px",
      userSelect: "none",
      background: "rgb(227, 252, 239)",
      borderRadius: "10px",
      top: draggableStyle.top - offsetY,//ドラッグ中の要素の位置を調整
    };
  };

  const onClickSpot = (spot) => {
    setSelectedSpot(spot);
    setShowListMode(false);
    setIsScheduleCard(false);
    setSearchText(spot.address);
  };

  return showSpotList ? (
    <SpotList spotList={spotList} onClickSpot={onClickSpot} />
  ) : (
    <div className={styles.listContainer}>
      {planInfo.scheduleList.length > 0 && (
        <DragDropContext onDragEnd={onDragEnd} onDragStart={() => handleOnSortContent(true)}>
          <Droppable droppableId={"dndTableBody"}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                className={styles.listContents}
              >
                {planInfo.scheduleList.map((schedule, index) => (
                  <Draggable
                    draggableId={`${index}-${schedule.title}`}
                    key={`${index}-${schedule.title}`}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={styles.content}
                        style={
                          snapshot.isDragging
                            ? getDraggingStyle(provided.draggableProps.style)
                            : itemStyle(provided.draggableProps.style)
                        }
                      >
                        <div className={styles.scheduleTitleBox}>
                          <h4
                            className={styles.scheduleTitle}
                            onClick={() => {
                              setIsScheduleCard(true);
                              setShowListMode(false);
                              setSelectedScheduleIndex(index);
                            }}
                          >
                            {`${index + 1}.　${schedule.title}`}
                          </h4>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </div>
  );
};
