import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Autocomplete, GoogleMap, InfoWindow, Marker, Polyline, useLoadScript } from "@react-google-maps/api";
import React, { useEffect, useRef, useState } from "react";
import xml2js from "xml2js";
import styles from "../../assets/css/map/mapRender.module.css";
import config from "../../constants/config.json";
import { initLatLng } from "../../constants/initState";
import { adjustMapAreaByMarker, adjustMapAreaByRoute, getAddress, getPlaceName } from "../../helper/geoLocation";
import { LoadingIcon } from "../commons/LoadingIcon";
import { getLocationByArea } from "../../helper/geoLocation";

/**
 * デフォルトのマップの中心を決定する
 * @param {object} mapInstance - GoogleMapのインスタンス
 * @param {function} setMapCenter - マップの中心を設定する関数
 * @param {array} markers - マーカーの配列
 * @param {object} areaCenter - 実施地域の緯度経度
 * @param {array} routePath - ルートの緯度経度
 * @returns
 * @description
 * 1.ルートがある場合はルートの緯度経度を中心にする
 * 2.マーカーがある場合はマーカーの緯度経度を中心にする
 * 3.実施地域がある場合は実施地域の緯度経度を中心にする
 * 4.それ以外は初期値を中心にする
 **/

const judgeDefaultMapCenter = async (mapInstance, setMapCenter, markers = null, areaCenter = null, routePath = []) => {
  if (!mapInstance) return;
  //マーカーがある場合,
  if (markers.length > 0) {
    const validMakers = markers.filter((marker) => {
      return marker.lat !== 0 && marker.lng !== 0;
    });
    //有効なマーカー(緯度経度有り)がある場合,マーカーによって地図表示領域を調節する
    if (validMakers.length > 0) {
      await adjustMapAreaByMarker(markers, mapInstance, setMapCenter);
      return;
    }
    //有効なマーカーがない場合、実施地域を中心にする
  }
  //ルートがある場合
  else if (routePath && routePath.length > 0) {
    //2.routePathがすべて表示されるように地図を調節する
    await adjustMapAreaByRoute(routePath, mapInstance, setMapCenter);
    return;
  }
  //実施地域が記入済みの場合は地域を中心に調節する
  else if (areaCenter) {
    setMapCenter(areaCenter);
    return;
  } else return;
};

/**
 * 選択されたスケジュールによってマップの中心を決定する
 * @param {object} mapInstance - GoogleMapのインスタンス
 * @param {function} setMapCenter - マップの中心を設定する関数
 * @param {number} selectedScheduleIndex - 選択されたスケジュールのindex
 * @param {object} selectedSpot - 選択されたスポット
 * @param {array} markers - マーカーの配列
 * @returns
 * @description
 * * 選択されたスケジュールがある場合はスケジュールの緯度経度を中心にする
 **/
const judgeMapCenterByUserSelect = async (
  mapInstance,
  setMapCenter,
  selectedScheduleIndex = null,
  selectedSpot,
  markers = null
) => {
  if (!mapInstance) return;
  //選択されたスケジュールがある場合
  if (selectedScheduleIndex !== null) {
    const lat = markers[selectedScheduleIndex].lat;
    const lng = markers[selectedScheduleIndex].lng;
    if (lat !== 0 && lng !== 0) {
      setMapCenter(markers[selectedScheduleIndex]);
    } //位置が無効({lat: 0, lng: 0})の場合は無視
    return;
  } else if (selectedSpot) {
    setMapCenter(selectedSpot.latLng);
    return;
  } else return;
};

/**
 * 選択されたスケジュールによってマップの中心を決定する
 * @param {array} routePath - ルートの緯度経度配列
 * @param {object} mapInstance - GoogleMapのインスタンス
 * @param {function} setMapCenter - マップの中心を設定する関数
 * @returns
 * @description
 * * 選択されたスケジュールがある場合はスケジュールの緯度経度を中心にする
 **/
const judgeMapCenterByRoute = async (routePath, mapInstance, setMapCenter) => {
  await adjustMapAreaByRoute(routePath, mapInstance, setMapCenter);
  return;
};

export const ScheduleMap = ({
  selectedSpot,
  setSelectedSpot,
  showSpotList,
  spotList,
  setClickedLocation,
  setShowListMode,
  routeDataUrl,
  areaCenter,
  tempMarker,
  setTempMarker,
  selectedScheduleIndex,
  setSelectedScheduleIndex,
  spotMarkers = [],
  scheduleMarkers = [],
  setIsScheduleCard,
  searchText,
  setSearchText,
  scheduleList,
  attachPlaceMode,
}) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries: config.googleMap.libraries,
  });
  const [mapCenter, setMapCenter] = useState(initLatLng);
  const [mapInstance, setMapInstance] = useState(null);
  const [autocomplete, setAutocomplete] = useState(null);
  const [routePath, setRoutePath] = useState([]);
  const isDoneGetPlace = useRef(true);

  //マップの中心をマーカーもしくはルートで設定（初期表示）
  useEffect(() => {
    if (!mapInstance) return;
    if (!scheduleMarkers.length && !areaCenter && !routePath.length) return;
    const markers = scheduleMarkers;
    // スケジュールリストモードの場合はスケジュールのマーカーを中央に設定
    if (!showSpotList) {
      judgeDefaultMapCenter(mapInstance, setMapCenter, markers, areaCenter, routePath);
    } else return;
  }, [mapInstance, showSpotList]);

  //ユーザースケジュール選択によってマップの中心を設定
  useEffect(() => {
    if (!mapInstance) return;
    const markers = selectedSpot ? spotMarkers : scheduleMarkers;
    judgeMapCenterByUserSelect(mapInstance, setMapCenter, selectedScheduleIndex, selectedSpot, markers);
  }, [mapInstance, selectedSpot, selectedScheduleIndex, scheduleMarkers]);

  //ルート選択によってマップの中心を設定
  useEffect(() => {
    if (!mapInstance) return;
    if (!routePath || routePath.length === 0) return;
    judgeMapCenterByRoute(routePath, mapInstance, setMapCenter);
  }, [mapInstance, routePath]);

  useEffect(() => {
    //GPXファイルを取得してパースする
    async function getGPXFileAndParse() {
      if (!routeDataUrl) setRoutePath([]);
      // GPXファイルを読み込む
      try {
        const response = await fetch(routeDataUrl);
        const xml = await response.text();
        const parser = new xml2js.Parser();
        // GPXをXMLからパースする
        parser.parseString(xml, (err, result) => {
          if (err) {
            console.error(err);
          } else if (result) {
            // GPXの情報からルートの座標を取得する
            const polylineArray = result.gpx.trk[0].trkseg[0].trkpt.map((point) => {
              return {
                lat: parseFloat(point["$"].lat),
                lng: parseFloat(point["$"].lon),
              };
            });
            setRoutePath(polylineArray);
          }
        });
      } catch (err) {
        console.error(err);
      }
    }
    getGPXFileAndParse();
  }, [routeDataUrl]);

  /**
   * handle Clicked MAP
   * @param {object} event - event object
   * @param {boolean} isEdit - if exist schedule marker clicked true, else false
   */
  const mapClicked = async (event, isEdit) => {
    isDoneGetPlace.current = false;
    //1.位置情報を取得
    const latLng = { lat: event.latLng.lat(), lng: event.latLng.lng() };

    // 選択用の一時マーカーを作成(場所変更の場合はisEditをtrueにセット)
    setTempMarker({ ...latLng, isEdit: isEdit ? true : false });

    //infoモード(spot)に切り替える
    setShowListMode(false);
    setIsScheduleCard(false);

    //現在の中心座標を更新
    setMapCenter({ lat: mapInstance.getCenter().lat(), lng: mapInstance.getCenter().lng() });

    //spot選択を解除
    selectedSpot && setSelectedSpot(null);
    //スケジュール選択を解除(位置情報追加モード以外の場合)
    !attachPlaceMode && selectedScheduleIndex !== null && setSelectedScheduleIndex(null);

    try {
      //2.placeIdがある場合はplaceIdで場所の名前を取得する。ない場合は位置情報から住所の取得
      //todo 住所を場所名とは別に保管する
      const placeId = event.placeId ? event.placeId : null;
      const address = await getAddress(latLng);
      const placeName = placeId ? await getPlaceName(placeId, mapInstance) : null;

      //3.place.nameと緯度経度の更新
      setClickedLocation({ name: placeName, latLng: latLng, address: address, googlePlaceId: placeId });
      //4.検索バーの更新
      setSearchText(placeName ? placeName : address);

      isDoneGetPlace.current = true;
    } catch (err) {
      console.error(err);
      setClickedLocation("error");
      isDoneGetPlace.current = true;
    }
  };

  const onAutoCompSelected = (isEdit) => {
    setShowListMode(false);
    setIsScheduleCard(false);
    if (autocomplete !== null) {
      //1.getPlace()で住所文字列を取得する
      const place = autocomplete.getPlace();
      if (!place.geometry) return;
      setMapCenter(place.geometry?.location); //マップの中央に設定する
      const value = `${place?.name}、${place?.formatted_address}`;
      //2.setSearchTextに取得した文字列をセットする
      setSearchText(value);
      //3,tempMarkersの更新
      setTempMarker({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
        isEdit: isEdit ? true : false,
      });
      //4.place.nameと緯度経度の更新
      setClickedLocation({
        name: place.name ? place.name : place.formatted_address,
        latLng: { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() },
        address: place.formatted_address,
        googlePlaceId: place.place_id,
      });
    } else {
      return;
    }
  };

  const polylineOptions = {
    strokeColor: "#FF0000",
    strokeOpacity: 0.8,
    strokeWeight: 2,
  };
  return isLoaded ? (
    <div className={styles.mapBox}>
      <GoogleMap
        id="pobicleMap"
        mapContainerStyle={{
          // 親要素で指定した幅と高さに対して、以下のスタイルを適用
          height: "100%",
          width: "100%",
          margin: "0 auto",
        }}
        onLoad={(map) => {
          setMapInstance(map);
        }}
        zoom={15}
        center={mapCenter}
        onClick={(e) => (isDoneGetPlace.current ? mapClicked(e, true) : null)}
        options={{
          disableDoubleClickZoom: true,
          disableDefaultUI: true,
          gestureHandling: "greedy",
          // styles: [
          //   //GoogleMapの表示スタイル調整＊要別ファイル化
          // ],
        }}
      >
        <div className={styles.searchBox}>
          <Autocomplete
            className={styles.autoComplete}
            onLoad={(props) => {
              setAutocomplete(props);
            }}
            onPlaceChanged={() => onAutoCompSelected(tempMarker?.isEdit ? true : false)}
            options={{
              componentRestrictions: { country: "jp" },
            }}
          >
            <input
              onKeyDown={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
              id="autoComplete"
              type="text"
              name="searchText"
              maxLength="100"
              placeholder="地名や施設名で検索"
              autoComplete="off"
              value={searchText ? searchText : ""}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            ></input>
          </Autocomplete>
          {searchText ? (
            <button
              type="button"
              className={styles.deletePlaceButton}
              onClick={() => {
                setClickedLocation(null);
                setSearchText(""); //検索textの消去
                if (attachPlaceMode) {
                  //スケジュールへの場所付与時⇒検索textの消去、
                  setIsScheduleCard(true);
                } else {
                  //場所選択時⇒検索textの消去、tempMarkerの消去、リストの表示
                  setTempMarker(null);
                  setShowListMode(true);
                }
              }}
            >
              <FontAwesomeIcon icon={["fa", "fa-times"]} className={styles.times} />
            </button>
          ) : null}
        </div>
        {/* TODO: マーカのラベルすたいるを付与するにはMarkerWithLabel libraryを使う。 */}
        {/*spotのマーカーを表示 */}
        {(attachPlaceMode || showSpotList) &&
          spotMarkers.length > 0 &&
          spotMarkers.map((marker, index) => {
            // 編集中のスケジュールに付与されたのスポットマーカーは表示しない
            if (marker.lat !== 0 && marker.lng !== 0) {
              const SpotMarker = {
                fillColor: "white",
                fillOpacity: 1,
                strokeColor: "#f82b2b",
                scale: 7,
                path: window.google.maps.SymbolPath.CIRCLE,
              };
              return (
                //スポットの描画
                <Marker
                  draggable={false}
                  key={index}
                  position={{ lat: marker.lat, lng: marker.lng }}
                  visible={true}
                  className={styles.scheduleMakers}
                  icon={SpotMarker}
                  onClick={() => {
                    setSelectedSpot(spotList[index]);
                    setClickedLocation(null);
                    setShowListMode(false);
                    setIsScheduleCard(false);
                    setTempMarker({ lat: spotList[index].latLng.lat, lng: spotList[index].latLng.lng });
                    setSearchText(spotList[index].address ? spotList[index].address : spotList[index].name);
                  }}
                ></Marker>
              );
            } else return null;
          })}
        {/* scheduleのマーカーを表示 */}
        <div>
          {!showSpotList &&
            scheduleMarkers.length > 0 &&
            scheduleMarkers.map((marker, index) => {
              if (selectedScheduleIndex === index) return null;
              if (marker.lat !== 0 && marker.lng !== 0) {
                return (
                  <Marker
                    draggable={false}
                    key={index}
                    position={{ lat: marker.lat, lng: marker.lng }}
                    visible={true}
                    className={styles.scheduleMakers}
                    onClick={() => {
                      setShowListMode(false);
                      setIsScheduleCard(true);
                      setSelectedScheduleIndex(index);
                      setTempMarker({
                        lat: scheduleList[index].latLng.lat,
                        lng: scheduleList[index].latLng.lng,
                        isEdit: false,
                      });
                      setSearchText(
                        scheduleList[index].address ? scheduleList[index].address : scheduleList[index].place
                      );
                      setClickedLocation(null);
                    }}
                    label={
                      // 数字付きのマーカーを表示
                      {
                        color: "white",
                        fontSize: "20px",
                        fontWeight: "500",
                        text: `${index + 1}`,
                      }
                    }
                  ></Marker>
                );
              } else return null;
            })}
        </div>
        {/* 選択用の一時マーカーを表示 */}
        {tempMarker && (
          <Marker
            draggable={tempMarker.isEdit || attachPlaceMode ? true : false}
            onDragEnd={(e) => mapClicked(e, tempMarker.isEdit ? true : false)}
            key={"temp"}
            position={{ lat: tempMarker.lat, lng: tempMarker.lng }}
          ></Marker>
        )}

        {routePath && <Polyline path={routePath} options={polylineOptions} />}
      </GoogleMap>
    </div>
  ) : (
    <LoadingIcon />
  );
};

export const ReadOnlyMap = ({
  routeDataUrl,
  markers = [],
  scheduleIndex = null,
  setScheduleIndex,
  setShowSchedule,
  scheduleList,
  areaArray,
}) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries: config.googleMap.libraries,
  });
  const [mapCenter, setMapCenter] = useState(initLatLng); //現在地を保存する
  const [mapInstance, setMapInstance] = useState(null);
  const [routePath, setRoutePath] = useState([]);
  const [area, setArea] = useState(null);

  // エリア情報のジオコーディング情報取得
  useEffect(() => {
    if (!mapInstance || !areaArray) return;
    const getGeoCodingInfo = async () => {
      let location = "";
      // areaArrayの中に1つでも値がある場合はジオコーディング情報を取得する
      if (areaArray.some((areaInfo) => areaInfo)) {
        try {
          location = await getLocationByArea(areaArray);
          if (location) {
            setArea(location);
            return;
          }
        } catch (error) {
          console.error(error);
        }
      }
    };
    getGeoCodingInfo();
  }, [mapInstance, areaArray]);

  useEffect(() => {
    if (!markers.length && !area && !routePath.length) return;

    judgeDefaultMapCenter(mapInstance, setMapCenter, markers, area, routePath);
  }, [markers, scheduleIndex, mapInstance, area, routePath]);

  useEffect(() => {
    if (!routeDataUrl) setRoutePath([]);
    //GPXファイルを取得してパースする
    async function getGPXFileAndParse() {
      try {
        const response = await fetch(routeDataUrl);
        const xml = await response.text();
        const parser = new xml2js.Parser();
        parser.parseString(xml, (err, result) => {
          if (err) {
            console.error(err);
          } else if (result) {
            const polylineData = result.gpx.trk[0].trkseg[0].trkpt.map((point) => {
              return {
                lat: parseFloat(point["$"].lat),
                lng: parseFloat(point["$"].lon),
              };
            });
            setRoutePath(polylineData);
          }
        });
      } catch (err) {
        console.error(err);
      }
    }
    getGPXFileAndParse();
  }, [routeDataUrl]);

  const polylineOptions = {
    strokeColor: "#FF0000",
    strokeOpacity: 0.8,
    strokeWeight: 2,
  };

  return isLoaded ? (
    <div className={styles.mapBox}>
      <GoogleMap
        id="pobicleMap"
        mapContainerStyle={{
          // 親要素で指定した幅と高さに対して、以下のスタイルを適用
          height: "100%",
          width: "100%",
          margin: "0 auto",
        }}
        onLoad={(map) => {
          setMapInstance(map);
        }}
        zoom={14}
        center={area ? area : mapCenter}
        options={{
          disableDefaultUI: true,
          fullscreenControl: true,
        }}
      >
        {markers.length > 0 &&
          markers.map((marker, index) => {
            if (marker && marker.lat !== 0 && marker.lng !== 0) {
              return (
                <Marker
                  key={index}
                  position={{ lat: marker.lat, lng: marker.lng }}
                  visible={true}
                  className={styles.scheduleMakers}
                  onClick={() => {
                    /* スケジュールを表示する */
                    setScheduleIndex(index);
                    setShowSchedule(true);
                  }}
                  label={{
                    color: "white",
                    fontSize: "20px",
                    fontWeight: "500",
                    text: `${index + 1}`,
                  }}
                ></Marker>
              );
            } else return null;
          })}
        {routePath && <Polyline path={routePath} options={polylineOptions} />}
      </GoogleMap>
    </div>
  ) : (
    <LoadingIcon />
  );
};
